import { Injectable, PipeTransform } from '@angular/core';
// interface
import { StockHeader } from '../interface/stockheader';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';
// Service
import { LoadingService } from './loading.service';

// 搜尋結果承接的 Interface
interface SearchResult {
	stockSell: StockHeader[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(stockin: StockHeader[], column: string, direction: string): StockHeader[] {
	// 沒有進行排序
	if (direction === '') {
		return stockin
	}
	else {
		return [...stockin].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(stocksell: StockHeader, term: string, pipe: PipeTransform) {
	return stocksell.StockSheetCode.toLowerCase().includes(term.toLowerCase()) 
		 || stocksell.SheetDate.toLowerCase().includes(term.toLowerCase())
		 // || stocksell.BillProviderName.toLowerCase().includes(term.toLowerCase()) 因為Server那邊不存這個
		 || pipe.transform(stocksell.DeliverZipCode).includes(term)
		 || stocksell.DeliverZipName.toLowerCase().includes(term.toLowerCase())
		 || stocksell.DeliverAddress.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
	providedIn: 'root'
})
export class StockSellService {

	// Define API URL參數
	apiURL = config.APIURL;
	fileURL = config["ERP_GeneratePDF_URL"];

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _stockSells$ = new BehaviorSubject<StockHeader[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(
		private pipe: DecimalPipe, 
		private http: HttpClient, 
		private loadingService: LoadingService
	) {
		this.loadingService.startLoading('取得銷貨單資料中...');
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getStockSellList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.result) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.SheetList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						this._stockSells$.next(result.stockSell);
						this._total$.next(result.total);
						this.loadingService.stopLoading();
					});
					this._search$.next();
				}
			});
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		this.loadingService.startLoading('取得銷貨單資料中...');
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getStockSellList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.result) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.SheetList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						this._stockSells$.next(result.stockSell);
						this._total$.next(result.total);
						this.loadingService.stopLoading();
					});
					this._search$.next();
				}
			});
		}
	}


	// 從外部取參數
	get stocksells$() { return this._stockSells$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(stockSellList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let stockSell = sort(stockSellList, sortColumn, sortDirection);

		// 2. filter
		stockSell = stockSell.filter(stocksell => matches(stocksell, searchTerm, this.pipe));
		const total = stockSell.length;

		// 3. paginate
		stockSell = stockSell.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		console.log("stockSell : ", stockSell);
		return of({stockSell, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得銷貨單據列表
	getStockSellList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			SearchStr: '',
			Type: 2 // 1: 進貨單, 2: 銷貨單
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_stocksell_sheet_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得客戶列表
	getCustomerList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_customer_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得產品列表
	getProductItemsList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_items_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 產生銷貨單單號
	generateStockSellCode(_erpCompid, _sheetDate, _sheetType): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			SheetDate: _sheetDate,
			SheetType: _sheetType
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/generate_stocksell_code', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得倉庫列表
	getWarehouseList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_active_warehouse_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得銷貨單對應的訂單資料
	getOrderList(_erpCompid, _billCode): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			BillCode: _billCode, 
			Type: 0 // 0: 訂單, 1:  採購單
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_single_bill', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 根據主題ID拿取DiscID
	getDiscIDBySubjID(_succCompid, _succUserid, _succSubjid, _token): Observable<any>{
		var param = {
			CompID: _succCompid,
			UserID: _succUserid,
			SubjID: _succSubjid,
			token: _token
		};

		return this.http.post<any>(
			this.apiURL + '/api/subj/get_subj_discid', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()		
	}

	// 取得討論組的收件人陣列
	initNewMsgReceiver(_succCompid, _succDiscid, _succUserid, _token): Observable<any>{
		var param = {
			token: _token,
			CompID: _succCompid,
			DiscID: _succDiscid
		};

		return this.http.post<any>(
			this.apiURL + '/api/user/get_disc_member', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 根據倉庫及品項編號 取得目前庫存數量
	getItemInventoryQuantity(_erpCompid, _warehouseID, _materialCode): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			WarehouseID: _warehouseID,
			ItemMaterialCode: _materialCode
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_single_item_inventory', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 作廢銷貨單動作列表
	// 1. 作廢銷貨單
	returnStockSell(_erpCompid, _stocksSheetCode, _inventoryType, _inventoryTypeName, _operatorid, _loguserDeivce): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			StockSheetCode: _stocksSheetCode,
			InventoryType: _inventoryType,
			InventoryTypeName: _inventoryTypeName,
			OperatorID: _operatorid,
			LogUserDevice: _loguserDeivce
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/return_stocksell_sheet', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}


	// 建立銷貨單動作列表
	// 1. 產生銷貨單PDF
	generateStockSellPDF(param): Observable<any>{
		var formData = new FormData();
		formData.append("StockSellSheet", JSON.stringify(param));

		return this.http.post<any>(
			this.fileURL + '/SuccErpApi/GenerateStockSellPDF', 
			formData
		).pipe()
	}
	// 2. 建立銷貨單
	insertStockSellSheet(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_stocksell_sheet', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}
	// 3.1 透過SubjID取得搜尋內容
	getSearchContent(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/log/get_subj_search_content', 
			param
		).pipe()
	}
	// 3.2 建立Succmail回覆
	createStockSellSuccReply(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/reply/create_reply', 
			param
		).pipe()
	}
	// 4. 把ReplyID 寫入這一筆銷貨單據
	updateStockSellRelatedReplyID(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/update_stocksell_replyid', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}



}