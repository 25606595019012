import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
// import config file
import config from '../config/constant.json';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

	// Define API URL參數
	apiURL = config.APIURL;

	constructor(private http: HttpClient) { }

	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}
	// 建立ERP使用者
	createERPUser(_param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_erp_member', 
			JSON.stringify(_param),
			this.httpOptions
		).pipe(retry(1))
	}
	// 建立ERP公司
	createERPComp(_param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_erp_comp', 
			JSON.stringify(_param),
			this.httpOptions
		).pipe(retry(1))
	}
	// 如果要新增公司的話，先用UserID去撈可以進去的公司，來做CompID的綁定
	getUserAccessComps(_param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/comp/get_user_access_comps', 
			JSON.stringify(_param),
			this.httpOptions
		).pipe(retry(1))
	}
	// 判斷公司是否已經綁定過了 by SuccCompID
	CheckERPCompIsBinding(_param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/check_erp_comp_binding', 
			JSON.stringify(_param),
			this.httpOptions
		).pipe(retry(1))
	}
	// 取得公司成員列表
	getERPMemberList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};
		return this.http.post<any>(
			this.apiURL + '/api/erp/get_erp_member_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))		
	}
	// 更新公司資料
	updateCompanyData(_erpCompid, _erpUserid, _compEntity, _device, _managerid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			CompName: _compEntity.CompName,
			CompAddress: _compEntity.CompAddr,
			CompTaxCode: _compEntity.CompTaxCode,
			CompPhoneNum: _compEntity.PhoneNum,
			CompFaxNum: _compEntity.FaxNum,
			OperatorID: _erpUserid,
			LogUserDevice: _device,
			ManagerID: _managerid
		};
		return this.http.post<any>(
			this.apiURL + '/api/erp/update_erp_comp', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}
	// 單獨取得公司 尚未啟用，目前暫時由更新公司後，直接吐回新的公司資料
	getERPSingleComp(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};
		return this.http.post<any>(
			this.apiURL + '/api/erp/get_erp_single_comp', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

}
