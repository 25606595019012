import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import config file
import config from '../../config/constant.json';
// import Service
import { LoginService } from '../../service/login.service';
import { LoadingService } from '../../service/loading.service';

@Component({
  selector: 'app-complist',
  templateUrl: './complist.component.html',
  styleUrls: ['./complist.component.css']
})
export class ComplistComponent implements OnInit {

	// 已經綁定的公司名單列表
	compList: any = [];

	constructor(
		private router: Router,
		public loginService: LoginService,
		private loadingService: LoadingService
	) { }

	ngOnInit() { }
	// 準備讀取已綁定的公司列表資料
	ngAfterContentInit() {
		this.loadingService.startLoading("資料讀取中...");
		let _userInfo = JSON.parse(localStorage.getItem('userInfo'));
		if (_userInfo != null) {
			let _succUserID = _userInfo[0]['UserID'];
			let _isExistERPCompany = this.loginService.checkERPEntityIsExist(_succUserID);
			_isExistERPCompany.subscribe(response => {
				if (response.IsSuccess) {
					this.compList = response.CompList;
				}
				this.loadingService.stopLoading();
			});
		}
		else {
			this.loadingService.stopLoading();
		}
	}
	// 產生公司Logo
	onGenerateCompImg(_compid: string, _logoPath: string){
	    // 公司Logo路徑
	    if (_logoPath != null) {
	      _logoPath = _logoPath.toLowerCase();
	      let _attid = _compid.toLowerCase();
	      let _fileExt = _logoPath.toLowerCase().slice(_logoPath.lastIndexOf(".") + 1);
	      let RequestPath = config.Download_URL + "?CompID=" + _compid + "&AttID=" + _attid + "&Ext=" + _fileExt + "&FileName=" + _attid;
	      return RequestPath;
	    }
	    else {
	      return './assets/images/Default_Company3.jpg';
	    }
	}
	// 新增公司
	newCompFunction(_evnet) {
		// Type = 0, 完全新增，不給公司名稱預設值
		this.router.navigate(['/companyBinding']);
	}
	// 導到選單頁面
	DirectToMenuPage(_item) {
		// 刪除目前的localS 存放的登入ERP Comp資料
		localStorage.removeItem('currentERPComp');
		localStorage.removeItem('currentERPUser');
		localStorage.removeItem('IsERPLogin');
		// 把loginInfo&compInfo 敏感資料再登入後刪除
		localStorage.removeItem('loginInfo');
		localStorage.removeItem('compInfo');
		// 寫入現在登入的成員資料，登入成員用 SuccUserID + ERPCompID 決定
		let _userInfo = JSON.parse(localStorage.getItem("userInfo"));
		if (_userInfo != null) {
			let _succUserID = _userInfo[0]['UserID'];
			this.loginService.getSingleERPMemberInfo(_item.ERPCompID, _succUserID).subscribe(response => {
				if (response.result) {
					// 寫入現在登入的公司資料
					localStorage.setItem('currentERPComp', JSON.stringify(_item));
					localStorage.setItem('currentERPUser', JSON.stringify(response.MemberInfo));
					// 寫入到 loginService
					this.loginService.setCurrentLogincomp({
						ERPComp: _item.ERPCompID,
						SuccCompID: _item.SuccCompID,
						LogoPath: _item.LogoPath,
						CompName: _item.CompName,
						UserName: response.MemberInfo.UserName
					});
					localStorage.setItem('IsERPLogin', "true");
					this.router.navigate(['/home']);
				}
				else {
					this.router.navigate(['/login']);	
				}
			});
		}
		else {
			this.router.navigate(['/login']);
		}
	}



}
