import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { CustomerService } from '../../service/customer.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import {Customer} from '../../interface/customer';
// 建立表單控制套件
import { FormGroup, FormControl } from '@angular/forms';
// 城市選擇
import zipCode from '../../config/zipCode.json';

@Component({
	selector: 'app-customer',
	templateUrl: './customer.component.html',
	styleUrls: ['./customer.component.css'],
	providers: [CustomerService, DecimalPipe]
})
export class CustomerComponent implements OnInit {

	customers$: Observable<Customer[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	city$: any = [];
	area$: any = [];
	userPower = 103;

	// 新增使用的 form
	customerForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		CustomerCode: new FormControl(''),
		CustomerName: new FormControl(''),
		CustomerTaxCode: new FormControl(''),
		CustomerPhoneNum: new FormControl(''),
		CustomerFaxNum: new FormControl(''),
		CustomerEmail: new FormControl(''),
		CustomerCityCode: new FormControl(''),
		CustomerCityName: new FormControl(''),
		CustomerZipCode: new FormControl(''),
		CustomerZipName: new FormControl(''),
		CustomerAddress: new FormControl(''),
		CustomerBankCode: new FormControl(''),
		CustomerBankAccount: new FormControl(''),
		IsActive: new FormControl('1'),
	});
	// 編輯使用的 form
	modifyCustomerForm = new FormGroup({
		CustomerID: new FormControl(''), // 供應商的PK
		ERPCompID: new FormControl(''), // 隱藏參數
		CustomerCode: new FormControl(''),
		CustomerName: new FormControl(''),
		CustomerTaxCode: new FormControl(''),
		CustomerPhoneNum: new FormControl(''),
		CustomerFaxNum: new FormControl(''),
		CustomerEmail: new FormControl(''),
		CustomerCityCode: new FormControl(''),
		CustomerCityName: new FormControl(''),
		CustomerZipCode: new FormControl(''),
		CustomerZipName: new FormControl(''),
		CustomerAddress: new FormControl(''),
		CustomerBankCode: new FormControl(''),
		CustomerBankAccount: new FormControl(''),
		IsActive: new FormControl('1'),
	});

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public customerService: CustomerService
	) {
		this.customers$ = customerService.customers$;
		console.log(this.customers$);
		this.total$ = customerService.total$;

		this.city$ = zipCode.City.sort((a, b) => (a.value > b.value ? 1: -1));
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
	}

	onSort({column, direction}: SortEvent) {
		// resetting other headers
		console.log("headers : ", this.headers);
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.customerService.sortColumn = column;
		this.customerService.sortDirection = direction;
	}

	// 前往建立客戶頁面
	goToNewCustomerPage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		this.IsBrowsing = false;
		this.IsCreate = true;
		this.IsEdite = false;
		// 呼叫Server產生新的供應商編號
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.customerService.getCustomerNewCode(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					// 綁定確定後 幫資料送入Form表格
					this.customerForm.patchValue({
						ERPCompID: _erpCompid,
						CustomerCode: response.CustomerCode,
						CustomerName: '',
						CustomerTaxCode: '',
						CustomerPhoneNum: '',
						CustomerFaxNum: '',
						CustomerEmail: '',
						CustomerCityCode: '',
						CustomerCityName: '',
						CustomerZipCode: '',
						CustomerZipName: '',
						CustomerAddress: '',
						CustomerBankCode: '',
						CustomerBankAccount: '',
						IsActive: '1'
					});
					console.log(this.customerForm);
					this.loadingService.stopLoading();
				}
				else {
					this.loadingService.stopLoading();
					this.router.navigate(['/supplier']);
				}
			});
		}
		else {
			this.loadingService.stopLoading();
			this.router.navigate(['/login']);
		}
	}

	// 建立客戶到Server端
	onSubmitCustomer() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('建立供應商中...');
		console.log(this.customerForm.value);
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;
		let param = {
			ERPCompID: _erpCompid,
			CustomerCode: this.customerForm.value['CustomerCode'],
			OperatorID: _erpUserid,
			CustomerName: this.customerForm.value['CustomerName'],
			CustomerTaxCode: this.customerForm.value['CustomerTaxCode'],
			CustomerPhoneNum: this.customerForm.value['CustomerPhoneNum'],
			CustomerEmail: this.customerForm.value['CustomerEmail'],
			CustomerFaxNum: this.customerForm.value['CustomerFaxNum'],
			CustomerCityCode: this.customerForm.value['CustomerCityCode'],
			CustomerCityName: this.customerForm.value['CustomerCityName'],
			CustomerZipCode: this.customerForm.value['CustomerZipCode'],
			CustomerZipName: this.customerForm.value['CustomerZipName'],
			CustomerAddress: this.customerForm.value['CustomerAddress'],
			CustomerBankAccount: this.customerForm.value['CustomerBankAccount'],
			LogUserDevice: _logUserDevice,
			IsActive: this.customerForm.value['IsActive']
		};
		this.customerService.createCustomer(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.customerService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 縣市值 發生變化
	onCityChange(_target, _type) {
		let cityName = this.city$.filter(item => {
			return item.value == parseInt(_target);
		})[0]['name'];
		if (_type == 0) {
			// 建立供應商情況
			this.customerForm.patchValue({
				CustomerCityName: cityName
			});
		}
		else {
			// 修改供應商情況
			this.modifyCustomerForm.patchValue({
				CustomerCityName: cityName
			});
		}

		let area = Object.keys(zipCode.Areas)
		.filter(keys => {
			return keys == _target
		})
		.reduce((obj, key) => {
			obj[key] = zipCode.Areas[key];
			return obj;
		}, {});
		this.area$ = area[Object.keys(area)[0]];
	}

	// 區域值 發生變化
	onAreaChange(_target, _type) {
		let zipName = this.area$.filter(item => {
			return item.value == parseInt(_target);
		})[0]['name'];
		if (_type == 0) {
			this.customerForm.patchValue({
				CustomerZipName: zipName
			});
		}
		else {
			this.modifyCustomerForm.patchValue({
				CustomerZipName: zipName
			});
		}
		console.log(this.modifyCustomerForm.value);
	}

	// 取消建立客戶的畫面
	cancelCreateCustomer() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 取消更新客戶
	cancelUpdateCustomer() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 前往客戶修改畫面
	updateCustomer(_customer) {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = true;
		// 灌值給Form表
		this.modifyCustomerForm.patchValue({
			CustomerID: _customer.CustomerID,
			ERPCompID: _customer.ERPCompID,
			CustomerCode: _customer.CustomerCode,
			CustomerName: _customer.CustomerName,
			CustomerTaxCode: _customer.CustomerTaxCode,
			CustomerPhoneNum: _customer.CustomerPhoneNum,
			CustomerFaxNum: _customer.CustomerFaxNum,
			CustomerEmail: _customer.CustomerEmail,
			CustomerCityCode: _customer.CustomerCityCode,
			CustomerCityName: _customer.CustomerCityName,
			CustomerZipCode: _customer.CustomerZipCode,
			CustomerZipName: _customer.CustomerZipName,
			CustomerAddress: _customer.CustomerAddress,
			CustomerBankCode: '',
			CustomerBankAccount: _customer.CustomerBankAccount,
			IsActive: _customer.IsActive.toString()
		});
		console.log("modifyForm");
		console.log(this.modifyCustomerForm.value);
		this.onCityChange(_customer.CustomerCityCode, 1);

		this.loadingService.stopLoading();
	}

	// 送更新客戶動作到Server
	onUpdateCustomer() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('資料修正中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpUserid = _currentUserInfo.ERPUserID;

		let param = {
			CustomerID: this.modifyCustomerForm.value['CustomerID'],
			CustomerCode: this.modifyCustomerForm.value['CustomerCode'],
			OperatorID: _erpUserid,
			CustomerName: this.modifyCustomerForm.value['CustomerName'],
			CustomerTaxCode: this.modifyCustomerForm.value['CustomerTaxCode'],
			CustomerPhoneNum: this.modifyCustomerForm.value['CustomerPhoneNum'],
			CustomerEmail: this.modifyCustomerForm.value['CustomerEmail'],
			CustomerFaxNum: this.modifyCustomerForm.value['CustomerFaxNum'],
			CustomerCityCode: this.modifyCustomerForm.value['CustomerCityCode'],
			CustomerCityName: this.modifyCustomerForm.value['CustomerCityName'],
			CustomerZipCode: this.modifyCustomerForm.value['CustomerZipCode'],
			CustomerZipName: this.modifyCustomerForm.value['CustomerZipName'],
			CustomerAddress: this.modifyCustomerForm.value['CustomerAddress'],
			CustomerBankAccount: this.modifyCustomerForm.value['CustomerBankAccount'],
			IsActive: this.modifyCustomerForm.value['IsActive'],
			ERPCompID: this.modifyCustomerForm.value['ERPCompID'],
			LogUserDevice: _logUserDevice,
		};
		this.customerService.updateCustomer(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料修改成功!');
				this.customerService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料修改錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);		
	}


	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

}
