import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

// http 使用
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import config file
import config from '../config/constant.json';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

	private remindStateSource = new BehaviorSubject("closed");
	private remindTypeSource = new BehaviorSubject(0);
	private remindMsgSource = new BehaviorSubject("");

	currentState = this.remindStateSource.asObservable();
	currentType = this.remindTypeSource.asObservable();
	currentMsg = this.remindMsgSource.asObservable();

	// Define API URL參數
	apiURL = config.APIURL;

	constructor(private http: HttpClient) { }

	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 偵測使用哪一種瀏覽器
	detectUserAgent(): string{
		let _userAgent = "";
		// CHROME
		if (navigator.userAgent.indexOf("Chrome") != -1 ) {
			_userAgent = "Google Chrome";
		}
		// FIREFOX
		else if (navigator.userAgent.indexOf("Firefox") != -1 ) {
			_userAgent = "Mozilla Firefox";
		}
		// INTERNET EXPLORER
		else if (navigator.userAgent.indexOf("MSIE") != -1 ) {
			_userAgent = "Internet Exploder";
		}
		// EDGE
		else if (navigator.userAgent.indexOf("Edge") != -1 ) {
			_userAgent = "Internet Exploder";
		}
		// SAFARI
		else if (navigator.userAgent.indexOf("Safari") != -1 ) {
			_userAgent = "Safari";
		}
		// OPERA
		else if (navigator.userAgent.indexOf("Opera") != -1 ) {
			_userAgent = "Opera";
		}
		// YANDEX BROWSER
		else if (navigator.userAgent.indexOf("Opera") != -1 ) {
			_userAgent = "YaBrowser";
		}
		// OTHERS
		else {
			_userAgent = "Others";
		}
		return _userAgent;
	}
	// 偵測是 Mobile 還是 DeskTop
	detectUserDevice(): string{
		let _device = "DeskTop";
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			_device = "Mobile";
		}
		return _device;
	}
	// 產生GUID
	generateGUID(): string{
		let d = Date.now();
		if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
		  	return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16).toUpperCase();
		});
	}
	// ========== 控制提示視窗 ========== 
	// state = {open, closed}
	changeRemindState(state: string) {
		this.remindStateSource.next(state);
		if (state == 'open') {
			console.log("in timeout1");
			let timeoutState = window.setTimeout(() => {
				console.log("in timeout");
				this.remindStateSource.next('closed'); 
			}, 2000);
			// window.clearTimeout(timeoutState);
		}
	}
	// type = {0: success, 1: error}
	changeRemindType(type: number) {
		this.remindTypeSource.next(type);
	}
	// 提示顯示的訊息
	changeRemindMsg(msg: string) {
		this.remindMsgSource.next(msg);
	}
	// =================================

	// 取得目前使用者權限
	getUserPowerID(_erpCompid, _erpUserid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			ERPUserID: _erpUserid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_user_powerid', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 前端Succmail導頁使用檢查權限 使用者用 SuccUserID
	getUserPowerIDBySuccUserID(_erpCompid, _succUserid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			SuccUserID: _succUserid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_user_powerid_by_succuserid', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}	


	// ========= ERP 的全域方式 =========
	// 根據稅別碼計算 總金額&稅額
	calculateTotalAndTaxFee(_isFiveAtFirstPoint, _itemLength, _total, _priceOfTax, _taxState) {
		console.log("priceOfTax : ", _priceOfTax);
		console.log("taxState : ", _taxState);
		console.log(_total);
		// 定義要回傳的數值
		let _taxFee = 0;
		let _noTaxTotal = 0;
		let _withTaxTotal = 0;
		// 計算使用參數
		let _roundDecimalPoint = 3;
		// 計算總金額
		for (var i = _roundDecimalPoint; i >= 0; i--) {
			_total = parseFloat(_total.toFixed(i));
		}
		// 根據稅別碼 計算稅額 無稅總金額
		// 應稅而產品未稅 -> 應稅外加
		// 應稅而產品含稅 -> 應稅內含
		// 1: 免稅, 2: 應稅, 3: 零稅
		if (_priceOfTax == 0 && _taxState == 1) {
			// 應稅外加
			_noTaxTotal = _total;
			let _paramTaxFee = _total*0.05;
			if (_isFiveAtFirstPoint) {
				_taxFee = parseFloat(_paramTaxFee.toFixed(0));
				console.log("RTaxFee：", _taxFee);
			}
			else {
				_taxFee = _paramTaxFee;
				for (var i = _roundDecimalPoint; i >= 0; i--) {
					_taxFee = parseFloat(_taxFee.toFixed(i));
					console.log("TaxFee : ", _taxFee);
				}
			}
		}
		else if (_priceOfTax == 1 && _taxState == 1) {
			// 應稅內含			
			_taxFee = parseFloat((_total - (_total/1.05)).toFixed(0));
			_noTaxTotal = _total - _taxFee;
		}
		else {
			// 免稅及零稅
			_noTaxTotal = _total;
			_taxFee = 0;
		}
		// 總價格
		_withTaxTotal = parseInt(_noTaxTotal.toString()) + parseInt(_taxFee.toString());
		// 最後回傳參數
		let result = {
			TaxFee: _taxFee,
			NoTaxTotal: _noTaxTotal,
			WithTaxTotal: _withTaxTotal
		};
		console.log("Price Info Result : ", result)
		return result;
	}
	// 轉換日期字串
	convertWesternDateStr(_datetime, _seperator): string {
		let _dateString = "";
		let _year = _datetime.getFullYear();
		let _month = _datetime.getMonth() + 1;
		let _date = _datetime.getDate();
		_dateString = _year + _seperator + _month.toString().padStart(2, '0') + _seperator + _date.toString().padStart(2, '0');
		return _dateString;
	}
	// 將日期字串轉換成DatePicker所需要的物件
	convertDateStrToObj(_dateStr) {
		let _date = new Date(_dateStr);
		return {
			year: _date.getFullYear(),
			month: _date.getMonth() + 1,
			day: _date.getDate()
		};
	}
	// 產生SuccMail發布主題要用的Title
	generateSubjTitle(_itemLength, _itemArray): string {
		let result = "";
		for (var i = 0; i < _itemLength; i++) {
			if (i > 0) {
				result += "、";
			}
			let _itemCode = _itemArray.at(i).value['ItemMaterialCode'];
			let _itemQuantity = _itemArray.at(i).value['ItemQuantity'];
			let _itemUnit = _itemArray.at(i).value['ItemUnitName'];
			result += _itemCode + "：" + _itemQuantity + _itemUnit + " ";
		}
		if (result.length > 35) {
			result = result.substring(0, 32) + "・・・";
		}
		return result;
	}
	// 轉換 Currency Code to ShortID
	convertCurrencyShortID(_currencyCode) {
		switch (_currencyCode) {
			case 1:
				return "TWD";
				break;
			case 2:
				return "USD";
				break;
			case 3:
				return "RMB";
				break;
			case 4:
				return "JPY";
				break;
			default:
				return "TWD";
				break;
		}
	}
	// 轉換 Currency Code to Name
	convertCurrencyName(_currencyCode) {
		switch (_currencyCode) {
			case 1:
				return "台幣";
				break;
			case 2:
				return "美金";
				break;
			case 3:
				return "人民幣";
				break;
			case 4:
				return "日圓";
				break;
			default:
				return "";
				break;
		}
	}
	// 稅別碼轉換
	convertTaxState(_taxCode) {
		switch (_taxCode) {
			case "1":
				return "應稅";
				break;
			case "2":
				return "免稅";
				break;
			case "3":
				return "零稅";
				break;
			default:
				return "";
				break;
		}
	}
	// =================================


}
