import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// import Service
import { LoadingService } from '../../service/loading.service';

// import handsonTable for 料帳管理
import Handsontable from 'handsontable';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})


export class MenuComponent implements OnInit {

	// 料帳管理資料
	dataset: any[] = [
		{id: 1, name: 'Ted Right', address: 'Wall Street'},
		{id: 2, name: 'Frank Honest', address: 'Pennsylvania Avenue'},
		{id: 3, name: 'Joan Well', address: 'Broadway'},
		{id: 4, name: 'Gail Polite', address: 'Bourbon Street'},
		{id: 5, name: 'Michael Fair', address: 'Lombard Street'},
		{id: 6, name: 'Mia Fair', address: 'Rodeo Drive'},
		{id: 7, name: 'Cora Fair', address: 'Sunset Boulevard'},
		{id: 8, name: 'Jack Right', address: 'Michigan Avenue'},
	];

	tableSettings: Handsontable.GridSettings = {
		data: this.dataset,
		dropdownMenu: ['filter_action_bar', 'filter_by_value'],
		filters: true,
		colHeaders: true,
		rowHeaders: true,
		contextMenu: true,
		licenseKey: "non-commercial-and-evaluation",
		// rowHeights: 30,
		colWidths: [200, 150, 75],
		bindRowsWithHeaders: true,
		columnSorting: true,
		manualColumnResize: true,
		manualRowResize: true,
		width:'100%',
		height:'800px',
		// htCenter
		columns: [
			{ data: 'name', title: '料號', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '品名', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '圖檔日期', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '版次', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '庫存數量', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '備料數量', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '待入數量', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '已購未進', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '需求日期', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '預計交期', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '採購/加工單號', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '供應商/托工廠商', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '前推天數', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '安全存量', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '最低定量', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '檢測規則', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '使用狀態', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '更新日期', readOnly: true, className: 'htMiddle' },
			{ data: 'name', title: '備註', readOnly: true, className: 'htMiddle' }
			// 可以用render func 來產生資料
			// { data: 'name', title: 'Name', readOnly: true, className: 'htMiddle', renderer: this.nameLinkRenderer.bind(this) },
		]
	};


	// private nameLinkRenderer(instance:any, td:Element, row:number, col:number, columnKey:string, data:any, cellProperties:Array<any>) : Element {
 //    Handsontable.default.renderers.BaseRenderer.apply(this, arguments);
 //    const $td = $(td);
 //    if ($td.find('a.presentation-link').length === 0) {
 //      const $linkTag = $(`<a class="presentation-link" href="${this.allPresentations[row].link}"></a>`);
 //      $linkTag.text(data);
 //      $td.append($linkTag);
 //    }
 //    return td;
 //  }

	// handsonTable 要設定 licenseKey
	licenseKey = "non-commercial-and-evaluation";

	constructor(private router: Router, private loadingService: LoadingService) { }

	ngOnInit() {
		this.loadingService.startLoading("資料讀取中...");
		// 假如沒有下述所有的 localS Item 就把它導回登入頁面
		let _userInfo = JSON.parse(localStorage.getItem('userInfo'));
		let _token = localStorage.getItem('Token');
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _isERPLogin = localStorage.getItem('IsERPLogin');
		this.loadingService.stopLoading();
		if (!_userInfo || !_token || !_currentERPComp || !_isERPLogin) {
			alert('請重新登入ERP系統');
			this.router.navigate(["/login"]);
		}
	}	
}
