import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import components
import { MenuComponent } from './component/menu/menu.component';
import { CompanyComponent } from './component/company/company.component';
import { SupplierComponent } from './component/supplier/supplier.component';
import { CustomerComponent } from './component/customer/customer.component';
import { ProductComponent } from './component/product/product.component';
import { WarehouseComponent } from './component/warehouse/warehouse.component';
import { OrderComponent } from './component/order/order.component';
import { PurchaseComponent } from './component/purchase/purchase.component';
import { ConfigComponent } from './component/config/config.component';
import { LoginComponent } from './component/login/login.component';
import { ComplistComponent } from './component/complist/complist.component';
import { CompbindingComponent } from './component/compbinding/compbinding.component';
import { CompmodifyComponent } from './component/compmodify/compmodify.component';
import { StockInComponent } from './component/stock-in/stock-in.component';
import { StockSellComponent } from './component/stock-sell/stock-sell.component';
import { ProductUnitComponent } from './component/product-unit/product-unit.component';
// ========
import { ValidateruleComponent } from './component/validaterule/validaterule.component';

const routes: Routes = [
	{ path: '', component: MenuComponent},
	{ path: 'home', component: MenuComponent},
	// { path: 'check/:CompID/:UserID', component: CheckComponent },
	{ path: 'login/:CompID/:UserID', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'companyInit/:Type', component: CompanyComponent},
	{ path: 'companyList', component: ComplistComponent},
	{ path: 'companyBinding', component: CompbindingComponent},
	{ path: 'companyModify', component: CompmodifyComponent},
	{ path: 'supplier', component: SupplierComponent},
	{ path: 'customer', component: CustomerComponent},
	{ path: 'product', component: ProductComponent},
	{ path: 'productUnit', component: ProductUnitComponent},
	{ path: 'stockIn/:purchaseID/:erpCompID/:succUserID', component: StockInComponent},
	{ path: 'stockIn/:purchaseID', component: StockInComponent},
	{ path: 'stockIn', component: StockInComponent},
	{ path: 'stockSell', component: StockSellComponent},
	{ path: 'stockSell/:orderCode/:erpCompID/:succUserID', component: StockSellComponent},
	{ path: 'stockSell/:orderCode', component: StockSellComponent},
	{ path: 'warehouse', component: WarehouseComponent},
	{ path: 'order', component: OrderComponent},
	{ path: 'purchase', component: PurchaseComponent},
	{ path: 'config', component: ConfigComponent},
	{ path: 'validaterule', component: ValidateruleComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
