import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { StockinService } from '../../service/stockin.service';
import { LoginService } from '../../service/login.service';

// 建立表單控制套件
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import { StockHeader } from '../../interface/stockheader';
// 稅別選擇
import TaxStateObj from '../../config/taxState.json';
import PriceOfTaxObj from '../../config/priceoftax.json';
import CurrencyObj from '../../config/currency.json';

// 備註編輯器
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-stock-in',
	templateUrl: './stock-in.component.html',
	styleUrls: ['./stock-in.component.css'],
	providers: [StockinService, DecimalPipe]
})
export class StockInComponent implements OnInit {

	stockIns$: Observable<StockHeader[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsDetail: boolean = false;
	IsCreate: boolean = false;
	provider$: any = [];
	products$: any = [];
	taxstates$: any = [];
	pricetax$: any = [];
	currency$: any = [];
	warehouses$: any = [];

	// 預設使用者權限為最低
	userPower = 103;

	// 採購單資料
	PurchaseSheet: any = {};
	// 供應商資料實體
	ProviderData: any = {};


	// 新增使用的 form
	stockinForm = new FormGroup({
		StockSheetCode: new FormControl(''),
		ERPCompID: new FormControl(''),
		SheetDate: new FormControl(''),
		SheetType: new FormControl(1),
		Memo: new FormControl('<p><br /></p>'),
		SheetProviderID: new FormControl(''),
		DeliverCityCode: new FormControl(''),
		DeliverCityName: new FormControl(''),
		DeliverZipCode: new FormControl(''),
		DeliverZipName: new FormControl(''),
		DeliverAddress: new FormControl(''),
		CurrencyCode: new FormControl(1),
		PriceOfTax: new FormControl(1),
		TotalQuantity: new FormControl(0),
		TotalPrice: new FormControl(0),
		TaxFee: new FormControl(0),
		TaxState: new FormControl(1),
		SheetState: new FormControl(1),
		StockInItems: new FormArray([]),
		SubjID: new FormControl(''),
		ReceiverArray: new FormControl(null),
		ReceiverNameArray: new FormControl(null)
	});

	// 查看詳細資料
	detailStockInForm = new FormGroup({
		StockSheetCode: new FormControl(''),
		ERPCompID: new FormControl(''),
		SheetDate: new FormControl(''),
		SheetType: new FormControl(1),
		Memo: new FormControl(''),
		SheetProviderID: new FormControl(''),
		ProviderCode: new FormControl(''),
		ProviderPhoneNum: new FormControl(''),
		ProviderFaxNum: new FormControl(''),
		ProviderTaxCode: new FormControl(''),
		DeliverCityCode: new FormControl(''),
		DeliverCityName: new FormControl(''),
		DeliverZipCode: new FormControl(''),
		DeliverZipName: new FormControl(''),
		DeliverAddress: new FormControl(''),
		CurrencyCode: new FormControl(1),
		PriceOfTax: new FormControl(1),
		TotalQuantity: new FormControl(0),
		TotalPrice: new FormControl(0),
		TaxFee: new FormControl(0),
		TaxState: new FormControl(1),
		SheetState: new FormControl(1),
		StockInItems: new FormArray([]),
		ReplyID: new FormControl('')
	});

	editorConfig: AngularEditorConfig = {
	    editable: true,
	      height: 'auto',
	      minHeight: '50',
	      maxHeight: '200',
	      width: 'auto',
	      minWidth: '0',
	      enableToolbar: false,
	      showToolbar: false,
	      placeholder: 'Enter memo here...',
	      defaultParagraphSeparator: 'p',
	      defaultFontName: '',
	      defaultFontSize: '12',
	      fonts: [
	        {class: 'arial', name: 'Arial'},
	        {class: 'times-new-roman', name: 'Times New Roman'},
	        {class: 'calibri', name: 'Calibri'},
	        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
	      ],
	      customClasses: [
	      {
	        name: 'quote',
	        class: 'quote',
	      },
	      {
	        name: 'redText',
	        class: 'redText'
	      },
	      {
	        name: 'titleText',
	        class: 'titleText',
	        tag: 'h1',
	      },
	    ],
	    uploadUrl: 'v1/image',
	    sanitize: false,
	    toolbarPosition: 'top'
	};

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private route: ActivatedRoute, 
		private router: Router,
		private loginService: LoginService,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public stockinService: StockinService
	) { 
		this.stockIns$ = stockinService.stockins$;
		this.total$ = stockinService.total$;

		this.taxstates$ = TaxStateObj.TaxStates;
		this.pricetax$ = PriceOfTaxObj.PriceOfTax;
		this.currency$ = CurrencyObj.Currency;
	}

	ngOnInit() {
		let _year = new Date().getFullYear();
		let _month = new Date().getMonth() + 1;
		let _date = new Date().getDate();
		let _todayDateStr = _year + "-" + _month + "-" + _date;
		// 參數
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _token = localStorage.getItem('Token');

		this.loadingService.startLoading("權限確認中...");
		// 如果 StockIn 有掛公司和人員ID 就代表建立入庫單後 要進行導頁
		if (this.route.snapshot.paramMap.get('erpCompID') || this.route.snapshot.paramMap.get('succUserID')) {
			localStorage.removeItem('DirectURL');
			localStorage.setItem('DirectURL', 'https://erp.succmail.com/stockIn/'+this.route.snapshot.paramMap.get('purchaseID'));
		}

		// 取得使用者權限
		if (_currentCompInfo != null && _currentUserInfo != null) {
			if (this.route.snapshot.paramMap.get('erpCompID') || this.route.snapshot.paramMap.get('succUserID')) {
				this.globalService.getUserPowerIDBySuccUserID(this.route.snapshot.paramMap.get('erpCompID'), this.route.snapshot.paramMap.get('succUserID')).subscribe(response => {
					if (response.result) {
						this.userPower = response.MemberInfo['Power'];
						localStorage.removeItem('currentERPUser');
						localStorage.setItem('currentERPUser', JSON.stringify(response.MemberInfo));
					}
					this.loadingService.stopLoading();
				});
			}
			else {
				this.globalService.getUserPowerID(_currentCompInfo.ERPCompID, _currentUserInfo.ERPUserID).subscribe(response => {
					if (response.result) {
						this.userPower = response.MemberInfo['Power'];
					}
					this.loadingService.stopLoading();
				});
			}
		}
		else {
			alert('ERP系統尚未登入！');
			localStorage.removeItem('DirectERPCompID');
			localStorage.removeItem('DirectSuccUserID');
			localStorage.setItem('DirectERPCompID', this.route.snapshot.paramMap.get('erpCompID'));
			localStorage.setItem('DirectSuccUserID', this.route.snapshot.paramMap.get('succUserID'));
			// 回到登入畫面
			this.router.navigate(['/login']);
			this.loadingService.stopLoading();
		}

		if (_currentCompInfo != null && (this.route.snapshot.paramMap.get('erpCompID') || this.route.snapshot.paramMap.get('succUserID'))) {
			if (_currentCompInfo.ERPCompID.toLowerCase() != this.route.snapshot.paramMap.get('erpCompID').toLowerCase()) {
				// 如果進來的單據 跟 localStorage的CompID不一樣
				let _isExistERPCompany = this.loginService.checkERPEntityIsExist(this.route.snapshot.paramMap.get('succUserID'));
				_isExistERPCompany.subscribe(response => {
					if (response.IsSuccess) {
						let _compData = response.CompList.filter(x => x.ERPCompID.toLowerCase() == this.route.snapshot.paramMap.get('erpCompID').toLowerCase())[0];
						localStorage.removeItem('currentERPComp');
						localStorage.setItem('currentERPComp', JSON.stringify(_compData));
						// 寫入到 loginService
						this.loginService.setCurrentLogincomp({
							ERPComp: _compData.ERPCompID,
							SuccCompID: _compData.SuccCompID,
							LogoPath: _compData.LogoPath,
							CompName: _compData.CompName
						});
						this.extractPurchaseData(_compData, _currentUserInfo, _token);
					}
				});
			}
			else {
				this.extractPurchaseData(_currentCompInfo, _currentUserInfo, _token);
			}
		}
		else {
			this.extractPurchaseData(_currentCompInfo, _currentUserInfo, _token);
		}

	}

	// 取得採購單資料
	extractPurchaseData(_currentCompInfo, _currentUserInfo, _token) {
		let _year = new Date().getFullYear();
		let _month = new Date().getMonth() + 1;
		let _date = new Date().getDate();
		let _todayDateStr = _year + "-" + _month + "-" + _date;
		// 採購單編號ID
		var purchaseID = this.route.snapshot.paramMap.get('purchaseID');
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;

			// 一般瀏覽畫面
			if (purchaseID == null) {
				// 取得供應商列表資料
				this.stockinService.getSupplierList(_erpCompid).subscribe(response => {
					if (response.IsSuccess) {
						this.provider$ = response.SupplierList;
					}
				});
			}

			// 採購單點擊轉單進入
			if (purchaseID != null) {
				// 進入建立入庫單狀態
				this.loadingService.startLoading('比對採購單資料中...');
				this.IsBrowsing = false;
				this.IsCreate = true;
				this.IsDetail = false;
				// 取得對應的採購單據
				this.stockinService.getPurchaseList(
					_erpCompid,
					purchaseID
				).subscribe(response => {
					if (response.result) {
						console.log(response.BillData);
						// 根據進貨單的來源採購單狀態 作出對應動作
						this.checkPurchaseState(response.BillData.BillState, response.BillData.SuccSubjID);
						this.PurchaseSheet = response.BillData;
						// 寫入Form的值
						this.stockinForm.patchValue({
							ERPCompID: this.PurchaseSheet.ERPCompID,
							SheetDate: { "year": _year, "month": _month, "day": _date},
							SheetProviderID: this.PurchaseSheet.BillProviderID,
							CurrencyCode: this.PurchaseSheet.CurrencyCode,
							PriceOfTax: this.PurchaseSheet.PriceOfTax,
							TaxState: this.PurchaseSheet.TaxState,
							SubjID: this.PurchaseSheet.SuccSubjID,
							Memo: '<p><br /></p>',
						});
						// 進入下一階段loading
						this.loadingService.stopLoading();
						this.loadingService.startLoading('取得廠商資料中...');
						// 取得供應商列表資料
						this.stockinService.getSupplierList(
							_erpCompid
						).subscribe(response => {
							if (response.IsSuccess) {
								this.provider$ = response.SupplierList;
								console.log(this.provider$);
								let _provider = this.provider$.filter(x => { return x.SupplierID == this.PurchaseSheet.BillProviderID; })[0];
								this.ProviderData.SupplierID = _provider.SupplierID;
								this.ProviderData.SupplierCode = _provider.SupplierCode;
								this.ProviderData.SupplierName = _provider.SupplierName;
								this.ProviderData.SupplierTaxCode = _provider.SupplierTaxCode;
								this.ProviderData.SupplierPhoneNum = _provider.SupplierPhoneNum;
								this.ProviderData.SupplierFaxNum = _provider.SupplierFaxNum;
								this.ProviderData.SupplierCityCode = _provider.SupplierCityCode;
								this.ProviderData.SupplierCityName = _provider.SupplierCityName;
								this.ProviderData.SupplierZipCode = _provider.SupplierZipCode;
								this.ProviderData.SupplierZipName = _provider.SupplierZipName;
								this.ProviderData.SupplierAddress = _provider.SupplierAddress;
								this.loadingService.stopLoading();
							}
							else {
								this.failHttpRequest('open', 1, '採購單不存在!');
								this.router.navigate(['/stockIn']);
							}
						});
						// 取得倉庫列表
						this.stockinService.getWarehouseList(
							_erpCompid,
						).subscribe(warehouse => {
							if (warehouse.IsSuccess) {
								this.warehouses$ = warehouse.WarehouseList;
								// 塞入品項值
								for (var i = 0; i<response.BillData.purchase_bill_data.length; i++) {
									(this.stockinForm.get('StockInItems') as FormArray).push(
										this.stockInDetailForm(response.BillData, i)
									);
								}
							}
						});
						// 根據主題ID拿取DiscID，再用DiscID拿取收件人
						this.stockinService.getDiscIDBySubjID(
							_currentCompInfo.SuccCompID,
							_currentUserInfo.SuccUserID,
							this.PurchaseSheet.SuccSubjID,
							_token
						).subscribe(response => {
							if (response.result) {
								// 指派DiscID
								this.PurchaseSheet.DiscID = response.DiscID;
								// 拿收件人
								this.stockinService.initNewMsgReceiver(
									_currentCompInfo.SuccCompID,
									response.DiscID,
									_currentUserInfo.SuccUserID,
									_token
								).subscribe(receiverRes => {
									console.log(receiverRes);
									let _activeReceiver = receiverRes.data.filter(x => x.relate_comp_user != null);
									let _fixReceiverArray = [];
									let _receiverNameArray = [];
									_activeReceiver.filter(x => x.DefaultReceiver == 1).forEach(function(_item) {
										_fixReceiverArray.push(_item.UserID);
										_receiverNameArray.push({
											'UserName': _item.relate_comp_user.LastName + _item.relate_comp_user.FirstName
										});
									});
									// 如果當中不包含建立者本人
									if (_fixReceiverArray.filter(x => x == _currentUserInfo['SuccUserID']).length < 1) {
										_fixReceiverArray.push(_currentUserInfo['SuccUserID']);
										_receiverNameArray.push({
											'UserName': _currentUserInfo.UserName
										});
									}
									this.stockinForm.patchValue({
										ReceiverArray: _fixReceiverArray,
										ReceiverNameArray: _receiverNameArray
									});
								});
							}
						});
						// 抽取主題的搜尋內容
						this.stockinService.getSearchContent({
							CompID: _currentCompInfo.SuccCompID,
							SubjID: this.PurchaseSheet.SuccSubjID,
							token: _token
						}).subscribe(response => {
							console.log(response);
							if (response.result) {
								this.PurchaseSheet.SearchContent = response.searchContent.sContent;
								this.PurchaseSheet.Attachment = response.searchContent.Attachment;
							}
						});
					}
					else {	
						this.failHttpRequest('open', 1, '採購單不存在!');
						this.router.navigate(['/stockIn']);
					}
				});
			}

			// 取得產品列表
			this.stockinService.getProductItemsList(
				_erpCompid
			).subscribe(response => {
				if (response.IsSuccess) {
					this.products$ = response.ItemsList;
				}
			});
			// 產生進貨單號
			this.stockinService.generateStockInCode(
				_erpCompid,
				_todayDateStr,
				1
			).subscribe(response => {
				if (response.IsSuccess) {
					this.stockinForm.patchValue({
						StockSheetCode: response.SheetCode
					});
				}
			});
		}
	}

	onSort({column, direction}: SortEvent) {
		// resetting other headers
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.stockinService.sortColumn = column;
		this.stockinService.sortDirection = direction;
	}

	// 根據進貨單的來源採購單狀態 作出對應動作
	checkPurchaseState(_purchaseState, _subjID) {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _directURL = localStorage.getItem('DirectURL');
		switch (_purchaseState) {
			case 0:
				// 未完成
				return true;
				break;
			case 1:
				// 已完成
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('採購單據已完成進貨!');
				alert("採購單據已完成進貨!");
				if (_directURL != null) {
					if (_directURL.length > 0) {
						localStorage.removeItem('DirectURL');
						window.location.href = "https://succmail.com/Spage/" + _currentCompInfo.SuccCompID + "/" + _currentUserInfo.SuccUserID + "/" + _subjID;
					}
				}
				else {
					this.IsBrowsing = true;
					this.IsCreate = false;
					this.IsDetail = false;
				}
				break;
			case 9:
				// 作廢(取消)
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('採購單據已作廢!');
				alert("採購單據已作廢!");
				if (_directURL.length > 0) {
					localStorage.removeItem('DirectURL');
					window.location.href = "https://succmail.com/Spage/" + _currentCompInfo.SuccCompID + "/" + _currentUserInfo.SuccUserID + "/" + _subjID;
				}
				else {
					this.IsBrowsing = true;
					this.IsCreate = false;
					this.IsDetail = false;
				}
				break;
		}
	}

	// 產生入庫品項
	private stockInDetailForm(_stockIn, _index): FormGroup {
		let _warehouseID = "";
		if (this.warehouses$.length > 0) {
			_warehouseID = this.warehouses$[0]['WarehouseID'];
			console.log(_warehouseID);
		}
		return new FormGroup({
			PurchaseItemID: new FormControl(_stockIn.purchase_bill_data[_index]["ID"]), // 對應的purchase Item 主key
			ERPCompID: new FormControl(_stockIn.ERPCompID),
			Serial: new FormControl(_stockIn.purchase_bill_data[_index]["Serial"]),
			StockSheetCode: new FormControl(this.stockinForm.value['StockSheetCode']),
			WarehouseID: new FormControl(_warehouseID),
			PurchaseCode: new FormControl(_stockIn.purchase_bill_data[_index]["PurchaseCode"]),
			ItemCategoryID: new FormControl(_stockIn.purchase_bill_data[_index]["ItemCategoryID"]),
			ItemMaterialCode: new FormControl(_stockIn.purchase_bill_data[_index]["ItemMaterialCode"]),
			ItemName: new FormControl(_stockIn.purchase_bill_data[_index]["ItemName"]),
			ItemSpec: new FormControl(_stockIn.purchase_bill_data[_index]["ItemSpec"]),
			ItemUnitCode: new FormControl(_stockIn.purchase_bill_data[_index]["ItemUnitCode"]),
			ItemUnitName: new FormControl(_stockIn.purchase_bill_data[_index]["ItemUnitName"]),
			ItemCurrency: new FormControl(_stockIn.purchase_bill_data[_index]["ItemCurrency"]),
			PurchaseQuantity: new FormControl(_stockIn.purchase_bill_data[_index]["ItemQuantity"]), // 品項採購數量 purchase.ItemQuantity
			AcceptedQuantity: new FormControl(_stockIn.purchase_bill_data[_index]["ItemAcceptQuantity"]), // 品項已入庫數量 purchase.AcceptQuantity
			AcceptQuantity: new FormControl(
				0, 
				[Validators.max(_stockIn.purchase_bill_data[_index]["ItemQuantity"] - _stockIn.purchase_bill_data[_index]["ItemAcceptQuantity"])]
			), // 品項欲入庫數量
			ItemPrice: new FormControl(_stockIn.purchase_bill_data[_index]["ItemPrice"]),
			Cost: new FormControl(0) // AcceptQuantity*ItemPrice
		});
	}

	// 將建立進貨單的需求送到 Server
	onSubmitStockIn() {
		this.loadingService.startLoading('產生進貨單據PDF中...');
		// 全域參數
		let _token = localStorage.getItem("Token");
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserName = _currentUserInfo.UserName;
		let _erpUserid = _currentUserInfo.ERPUserID;

		// 取得判斷所有計算出的品項金額 小數點後第一位 是不是都是5 
		// 如果 true: 計算應稅外加時，稅額不進位只取到整數位
		// 如果 false: 計算應稅外加時，稅額從小數點後面逐個進位
		let _isFiveAtFirstPoint = this.calculateTotalPointIsFive();
		// 總數量
		let _totalQuantity = this.calculateTotalAmount();
		// 品項數量
		let _itemLength = (this.stockinForm.get('StockInItems') as FormArray).length;
		// 錢相關
		let _total = 0;
		for (var i = 0; i < _itemLength; i++) {
			_total += (this.stockinForm.get('StockInItems') as FormArray).at(i).value['Cost'];
		}

		let _priceInfo = this.globalService.calculateTotalAndTaxFee(
			_isFiveAtFirstPoint,
			_itemLength,
			_total,
			this.stockinForm.value['PriceOfTax'],
			this.stockinForm.value['TaxState']
		);
		let _taxFee = _priceInfo.TaxFee;
		let _noTaxTotal = _priceInfo.NoTaxTotal;
		let _withTaxTotal = _priceInfo.WithTaxTotal;
		// 表頭參數
		let _billDate = this.stockinForm.value['SheetDate']['year']+"/"+this.stockinForm.value['SheetDate']['month']+"/"+this.stockinForm.value['SheetDate']['day'];

		// 準備要使用的列印PDF的參數
		let _paramObjForCreatePDF = {
			ERPCompName: _currentCompInfo.CompName,
			ERPCompAreaCode: '',
			ERPCompAddress: _currentCompInfo.CompAddress,
			ERPCompPhone: _currentCompInfo.CompPhoneNum,
			ERPCompFax: _currentCompInfo.CompFaxNum,
			WesternTodayDate: this.globalService.convertWesternDateStr(new Date(),"/"),
			WesternStockInDate: _billDate,
			SupplierID: this.ProviderData.SupplierCode,
			SupplierCompName: this.ProviderData.SupplierName,
			SupplierAddress: this.ProviderData.SupplierZipCode + " " + this.ProviderData.SupplierCityName + this.ProviderData.SupplierZipName + this.ProviderData.SupplierAddress,
			SupplierPhoneNum: this.ProviderData.SupplierPhoneNum,
			SupplierFaxNum: this.ProviderData.SupplierFaxNum,
			CurrencyID: this.stockinForm.value['CurrencyCode'],
			CurrencyName: this.globalService.convertCurrencyShortID(this.stockinForm.value['CurrencyCode']),
			CurrencyRate: 1,
			SalesManID: '',
			SalesManName: _erpUserName,
			CreatorName: _erpUserName,
			Memo: encodeURIComponent(this.stockinForm.value['Memo']),
			BillNo: this.stockinForm.value['StockSheetCode'],
			ItemTaxState: this.stockinForm.value['PriceOfTax'] == 0 ? "未稅":"含稅",
			TotalItemCount: _itemLength,
			TotalItemAmount: _totalQuantity,
			NoTaxTotal: _priceInfo.NoTaxTotal,
			TaxFee: _priceInfo.TaxFee,
			WithTaxTotal: _priceInfo.WithTaxTotal,
			StockInSheetItem: this.generateStockInItemArray(_itemLength),
			FooterTerms: '',
			CreatorCompID: _currentCompInfo.SuccCompID
		};

		// 建立入庫單據物件
		let _stockInSheet = {
			ERPCompID: _erpCompid,
			SheetDate: _billDate,
			StockSheetCode: this.stockinForm.value['StockSheetCode'],
			SheetType: this.stockinForm.value['SheetType'],
			Memo: this.stockinForm.value['Memo'],
			OperatorID: _erpUserid,
			SheetProviderID: this.stockinForm.value['SheetProviderID'],
			DeliverCityCode: this.ProviderData.SupplierCityCode,
			DeliverCityName: this.ProviderData.SupplierCityName,
			DeliverZipCode: this.ProviderData.SupplierZipCode,
			DeliverZipName: this.ProviderData.SupplierZipName,
			DeliverAddress: this.ProviderData.SupplierAddress,
			CurrencyCode: this.stockinForm.value['CurrencyCode'],
			PriceOfTax: this.stockinForm.value['PriceOfTax'],
			TotalQuantity: _totalQuantity,
			TotalPrice: _noTaxTotal,
			TaxFee: _taxFee,
			TaxState: this.stockinForm.value['TaxState'],
			SheetBody: JSON.stringify(this.stockinForm.value['StockInItems'].filter(x => x.AcceptQuantity > 0)),
			LogUserDevice: this.globalService.detectUserAgent(),
			InventoryType: '202',
			InventoryTypeName: '採購入庫'
		}
		let _subjTitle = this.globalService.generateSubjTitle(_itemLength, (this.stockinForm.get('StockInItems') as FormArray));

		var replyFormData = new FormData();
		replyFormData.append("token", _token);
		replyFormData.append("CompID", _currentCompInfo.SuccCompID);
		replyFormData.append("DiscID", this.PurchaseSheet.DiscID);
		replyFormData.append("SubjID", this.stockinForm.value['SubjID']);
		replyFormData.append("UserID", _currentUserInfo.SuccUserID);
		replyFormData.append("sContent", this.generateReplyContent(_priceInfo));
		replyFormData.append("ReplyTo", "00000000-0000-0000-0000-000000000000");
		replyFormData.append("Receiver", JSON.stringify(this.stockinForm.value['ReceiverArray']));
		replyFormData.append("ByUserDevice", this.globalService.detectUserDevice());
		replyFormData.append("ByUserBrowser", this.globalService.detectUserAgent());		
		replyFormData.append("DetailSubjTitle", "入庫 - " + this.ProviderData.SupplierName + " - " + _subjTitle + " - " + this.globalService.convertWesternDateStr(new Date(),"/"));
		replyFormData.append("SubjRead", JSON.stringify(this.stockinForm.value['ReceiverNameArray']));
		replyFormData.append("ReplyType", '3002'); // 2001: 正航採購, 2003: 正航訂單, 3001: Succ採購, 3002: Succ入庫, 3003: Succ訂單, 3004: Succ銷貨

		// 準備存放上傳完成的檔案
		let AttList = [];
		this.stockinService.generateStockInPDF(_paramObjForCreatePDF).subscribe(pdfRes => {
			if (pdfRes.IsSuccess) {
				let _attID = pdfRes.AttID;
				let _filePath = pdfRes.FilePath;
				let _filSize = pdfRes.FileSize;
				let _fileSizeView = pdfRes.FileSizeView;
				let _fileExt = pdfRes.FileExt;
				AttList.push({
					'AttID': pdfRes.AttID,
					'FileName': _paramObjForCreatePDF.WesternTodayDate + "-" + _paramObjForCreatePDF.SupplierCompName + "-" + "進貨單憑證.pdf",
					'FilePath': pdfRes.FilePath,
					'FileSize': pdfRes.FileSize,
					'FileSizeView': pdfRes.FileSizeView,
					'FileType': pdfRes.FileExt.toString().toUpperCase(),
					'IsDel': false,
					'IsEmbed': false,
					'IsSuccess': true,
					'uid': "rc-upload-1554787902309-2"
				});

				// 指派Succ建立回覆物件的檔案資料
				replyFormData.append("AttList", JSON.stringify(AttList)); 
				// 產生Succ搜尋內容
				let _searchContent = this.PurchaseSheet.SearchContent + "@||@" + this.generateReplyContent(_priceInfo);
				let _searchAttachment = this.PurchaseSheet.Attachment + "@||@" + AttList[0]['FileName'];
				replyFormData.append("Search_Content", _searchContent);
				replyFormData.append("Search_Att", _searchAttachment);

				// 進入下一階段loading
				this.loadingService.stopLoading();
				this.loadingService.startLoading('建立進貨單據中...');

				// 建立入庫單據
				this.stockinService.insertStockInSheet(_stockInSheet).subscribe(stockRes => {
					if (stockRes.IsSuccess) {
						// 進入下一階段loading
						this.loadingService.stopLoading();
						this.loadingService.startLoading('建立SuccMail回覆中...');
						// 指派Reply屬於哪一個BillNo
						let _sheetCode = stockRes.SheetCode; // 進server有再判斷一次這個billCode有無重複
						replyFormData.append("SheetCode", _sheetCode);
						// 建立Succmail Reply
						this.stockinService.createStockInSuccReply(
							replyFormData
						).subscribe(succRes => {
							// 進入下一階段loading
							this.loadingService.stopLoading();
							this.loadingService.startLoading('資料最後確認...');
							if (succRes.result) {
								// 寫入進貨單對應的 ReplyID
								this.stockinService.updateStockInRelatedReplyID({
									ERPCompID: _erpCompid,
									SheetType: 1,
									SheetCode: this.stockinForm.value['StockSheetCode'],
									ReplyID: succRes.ReplyID
								}).subscribe(updateReplyRes => {
									this.loadingService.stopLoading();
									window.scrollTo(0, 0);
									this.globalService.changeRemindState('open');
									this.globalService.changeRemindType(0);
									this.globalService.changeRemindMsg('成功建立進貨單據!');
									this.stockinService.refreshDataList();
									this.IsBrowsing = true;
									this.IsCreate = false;
									this.IsDetail = false;
									let _directURL = localStorage.getItem('DirectURL');
									if (_directURL != null) {
										if (_directURL.length > 0 && _directURL.indexOf("stockIn") > 0) {
											localStorage.removeItem('DirectURL');
											window.location.href = "https://succmail.com/Spage/" + _currentCompInfo.SuccCompID + "/" + _currentUserInfo.SuccUserID + "/" + this.stockinForm.value['SubjID'];
										}
										else {
											this.router.navigate(['/stockIn']);
										}
									}
								})
							}
							else {
								this.failHttpRequest('open', 1, '建立SuccMail回覆失敗!');
							}
						});
					}
					else {
						this.failHttpRequest('open', 1, '建立進貨單據資料失敗!');
					}
				});
			}
			else {
				this.failHttpRequest('open', 1, '建立進貨單據PDF失敗!');
			}
		});
	}

	// HTTP Request 失敗狀態
	failHttpRequest(_msgState, _remindType, _remindMsg) {
		// remindType 0: Success, 1: Fail
		this.loadingService.stopLoading();
		window.scrollTo(0, 0);
		this.globalService.changeRemindState(_msgState);
		this.globalService.changeRemindType(_remindType);
		this.globalService.changeRemindMsg(_remindMsg);
	}

	// 取消建立入庫單
	cancelCreateStockIn() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsDetail = false;
		this.router.navigate(['/purchase']);
	}

	// 品項入庫數量發生變化
	updateStockInItem(_index) {
		console.log(_index);
		// 預計入庫數量 輸入值
		let _acceptQuantity = (this.stockinForm.get('StockInItems') as FormArray).at(_index).value['AcceptQuantity'];
		let _purchaseQuantity = (this.stockinForm.get('StockInItems') as FormArray).at(_index).value['PurchaseQuantity'];
		let _acceptedQuantity = (this.stockinForm.get('StockInItems') as FormArray).at(_index).value['AcceptedQuantity'];
		if (_acceptQuantity > (_purchaseQuantity - _acceptedQuantity) || _acceptQuantity == null) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('數值輸入異常!');
			// 塞入值
			this.stockinForm.get('StockInItems')['controls'][_index].patchValue({
				Cost: 0
			});
		}
		else {
			// 參數宣告
			let result = 0; // 最後計算完小數點回傳的品項總金額
			let total = 0; // 未計算小數點前的品項總金額
			let total_length = 0;
			let dot_index = 0;
			let dot_last_index;

			let _price = (this.stockinForm.get('StockInItems') as FormArray).at(_index).value['ItemPrice'];
			let _acceptQuantity = (this.stockinForm.get('StockInItems') as FormArray).at(_index).value['AcceptQuantity'];
			total = _acceptQuantity * _price;
			total_length = total.toString().length;
			dot_index = total.toString().indexOf(".");
			dot_last_index = total.toFixed(5).lastIndexOf('.')+5; // +5 用意是最小取至第五位進行四捨五入
			if (dot_index == -1) {
				result = parseFloat(total.toString());
			}
			else {
				result = parseFloat(total.toFixed(5).substring(0, dot_last_index));
			}
			// 塞入值
			this.stockinForm.get('StockInItems')['controls'][_index].patchValue({
				Cost: result
			});
		}
	}

	// 計算品項金額小數點後第一位 是不是都是5
	calculateTotalPointIsFive() {
		let result = true;
		let _itemLength = (this.stockinForm.get('StockInItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
			let _isFiveIndex = (this.stockinForm.get('StockInItems') as FormArray).at(i).value['Cost'].toString().indexOf(".5");
			if (_isFiveIndex == -1) {
				result = false;
				return result;
			}
		}
		return result;
	}

	// 計算品項總數量
	calculateTotalAmount() {
		let result = 0;
		let _itemLength = (this.stockinForm.get('StockInItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
			result += (this.stockinForm.get('StockInItems') as FormArray).at(i).value['AcceptQuantity'];
		}
		return result;
	}

	// 轉Item Array 的方法，後續完整後要轉到Action裡面 For PDF
	generateStockInItemArray(_itemLength) {
		let result = [];
		for (var i = 0; i < _itemLength; i++) {
			let _warehouseName = 
			result.push({
				'Serial': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['Serial'].toString(),
				'ItemID': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemMaterialCode'],
				'ItemName': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemName'] + '-' + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemSpec'],
				'ItemAmount': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['AcceptQuantity'],
				'ItemUnit': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemUnitName'],
				'ItemPrice': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemPrice'],
				'ItemTotal': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['Cost'],
				'ItemWarehouseName': this.generateWarehouseName((this.stockinForm.get('StockInItems') as FormArray).at(i).value['WarehouseID']),
				'NotAcceptQuantity': (this.stockinForm.get('StockInItems') as FormArray).at(i).value['PurchaseQuantity'] - (this.stockinForm.get('StockInItems') as FormArray).at(i).value['AcceptedQuantity'] - (this.stockinForm.get('StockInItems') as FormArray).at(i).value['AcceptQuantity']
			});
		}
		return result;
	}

	// 產生倉庫名稱
	generateWarehouseName(_warehouseID): string {
		return this.warehouses$.filter(x => {return x.WarehouseID == _warehouseID;})[0]['WarehouseName'];
	}

	// 產生回覆內容
	generateReplyContent(_priceInfo) {
		let ContentString = "";

		let _purchaseCodeLabel = "採購單號：";
		let _stockInCodeLabel = "進貨單號：";
		let customerIDLabel = "廠商編號：";
    	let customerNameLabel = "廠商名稱：";
    	let addressLabel = "廠商地址：";
    	let phoneLabel = "連絡電話：";
    	let faxLabel = "傳真號碼：";
    	let linkManLabel = "聯絡人員：";
    	let taxCodeLabel = "稅別種類：";
    	let currencyIDLabel = "幣　　別：";
    	let currencyRateLabel = "匯　　率：";
    	let itemIncludeTaxLabel = "產品單價：";

    	let serialLabel = "序　　號：";
    	let itemCodeLabel = "產品編號：";
    	let itemNameLabel = "品名規格：";
    	let itemUnitLabel = "品項單位：";
    	let itemNumberLabel = "採購數量：";
    	let itemAcceptLabel = "進貨數量：";
    	let itemPriceLabel = "單　　價：";
    	let warehouseLabel = "倉　　庫：";
    	let eachPriceLabel = "成本金額：";
    	let memoLabel = "備　　註：";

    	let orderPriceLabel = "原幣合計：";
    	let taxPriceLabel = "原幣稅金：";
    	let totalPriceLabel = "原幣總計：";

    	let emptyRow = "<p><br /></p>";
    	let seperateRow = "<p>------------------------------ 分隔線 ------------------------------</p>";
    	// 原幣
    	let _currencyID = this.globalService.convertCurrencyShortID(this.stockinForm.value['CurrencyCode']);
    	let _currencyName = this.globalService.convertCurrencyName(this.stockinForm.value['CurrencyCode']);
    	
    	let orderPriceRow = "<p>" + orderPriceLabel + _priceInfo.NoTaxTotal + " " + _currencyID + "</p>";
    	let taxFeeRow = "<p>" + taxPriceLabel + _priceInfo.TaxFee + " " + _currencyID + "</p>";
    	let withTaxTotalRow = "<p>" + totalPriceLabel + _priceInfo.WithTaxTotal + " " + _currencyID + "</p>";

    	// 統一內容區域
    	let purchaseCodeRow = "<p>" + _purchaseCodeLabel + this.PurchaseSheet.BillCode + "</p>";
    	let stockInCodeRow = "<p>" + _stockInCodeLabel + this.stockinForm.value['StockSheetCode'] + "</p>";
    	let custIDRow = "<p>" + customerIDLabel + this.ProviderData.SupplierCode + "</p>";
    	let custNameRow = "<p>" + customerNameLabel + this.ProviderData.SupplierName + "</p>";
    	let addressRow = "<p>" + addressLabel + this.ProviderData.SupplierZipCode + " " + this.ProviderData.SupplierCityName + this.ProviderData.SupplierZipName + this.ProviderData.SupplierAddress + "</p>";
    	let phoneRow = "<p>" + phoneLabel + this.ProviderData.SupplierPhoneNum + "</p>";
    	let faxRow = "<p>" + faxLabel + this.ProviderData.SupplierFaxNum + "</p>";
    	// let linkManRow = "<p>" + linkManLabel + this.props.CustomerDetail.get("LinkMan") + "</p>";
    	let taxRow = "<p>" + taxCodeLabel + this.globalService.convertTaxState(this.stockinForm.value['TaxState'].toString());
    	let currencyIDRow = "<p>" + currencyIDLabel + _currencyID + " " + _currencyName + "</p>";
    	let currencyRateRow = "<p>" + currencyRateLabel + 1 + "</p>";
    	// 產品品項是否含稅
    	let itemIncludeTaxVal = this.stockinForm.value['PriceOfTax'] == 0 ? "未稅":"含稅";
    	let itemIncludeTaxRow = "<p>" + itemIncludeTaxLabel + itemIncludeTaxVal + "</p>";
    	let memoStr = this.stockinForm.value['Memo'];
    	let memoLabelRow = "<p>" + memoLabel + memoStr + "</p>";

    	// 統一訊息部分
    	ContentString += purchaseCodeRow + stockInCodeRow + custIDRow + custNameRow + addressRow + phoneRow + faxRow + taxRow + currencyIDRow + currencyRateRow + itemIncludeTaxRow + emptyRow + memoLabelRow + emptyRow;

    	// 個別品項訊息部分
		let _itemLength = (this.stockinForm.get('StockInItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
    		let SerialRow = "<p>" + serialLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['Serial'] + "</p>";
    		let ItemCodeRow = "<p>" + itemCodeLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemMaterialCode'] + "</p>";
    		let ItemNameRow = "<p>" + itemNameLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemName'] + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemSpec'] + "</p>";
    		let ItemUnitRow = "<p>" + itemUnitLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemUnitName'] + "</p>";
    		let ItemQuantityRow = "<p>" + itemNumberLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['PurchaseQuantity'] + "</p>";
    		let AccpectQuantityRow = "<p>" + itemAcceptLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['AcceptQuantity'] + "</p>";
    		let ItemPriceRow = "<p>" + itemPriceLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['ItemPrice'] + " " + _currencyID + "</p>";
    		let WarehouseRow = "<p>" + warehouseLabel + this.generateWarehouseName((this.stockinForm.get('StockInItems') as FormArray).at(i).value['WarehouseID']) + "</p>";
    		let EachItemTotalRow = "<p>" + eachPriceLabel + (this.stockinForm.get('StockInItems') as FormArray).at(i).value['Cost'] + " " + _currencyID + "</p>";

    		ContentString += SerialRow + ItemCodeRow + ItemNameRow + ItemUnitRow + ItemQuantityRow + AccpectQuantityRow + ItemPriceRow + WarehouseRow + EachItemTotalRow + seperateRow; 
		}

    	// 金額訊息部分
    	ContentString += orderPriceRow + taxFeeRow + withTaxTotalRow;

    	return ContentString;
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);
	}

	// 透過 providerID 產生 供應商 名稱
	generateProviderName(_providerID) {
		let _providerName = this.provider$.filter(x => x.SupplierID == _providerID)[0]['SupplierName'];
		return _providerName;
	}

	// 查看入庫單詳細資料
	checkDetail(_item) {
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsDetail = true;
		console.log(_item);
		// 編輯器不可編輯
		this.editorConfig.editable = false;
		// 重置品項陣列
		if ((this.detailStockInForm.get('StockInItems') as FormArray) != null) {
			while ((this.detailStockInForm.get('StockInItems') as FormArray).length) {
				(this.detailStockInForm.get('StockInItems') as FormArray).removeAt(0);
			}
		}
		let _provider = this.provider$.filter(x => {return x.SupplierID == _item.SheetProviderID;})[0];
		this.detailStockInForm.patchValue({
			StockSheetCode: _item.StockSheetCode,
			ERPCompID: _item.ERPCompID,
			SheetDate: _item.SheetDate.substring(0, 10),
			SheetType: _item.SheetType,
			Memo: _item.Memo.length > 0 ? _item.Memo:"<p><br /></p>",
			SheetProviderID: _item.SheetProviderID,
			ProviderCode: _provider.SupplierCode,
			ProviderPhoneNum: _provider.SupplierPhoneNum,
			ProviderTaxCode: _provider.SupplierTaxCode,
			DeliverCityCode: _item.DeliverCityCode,
			DeliverCityName: _item.DeliverCityName,
			DeliverZipCode: _item.DeliverZipCode,
			DeliverZipName: _item.DeliverZipName,
			DeliverAddress: _item.DeliverAddress,
			CurrencyCode: _item.CurrencyCode,
			PriceOfTax: _item.PriceOfTax,
			TotalQuantity: _item.TotalQuantity,
			TotalPrice: _item.TotalPrice,
			TaxFee: _item.TaxFee,
			TaxState: _item.TaxState,
			SheetState: _item.SheetState,
			ReplyID: _item.SuccReplyID
		});
		for (var i = 0; i< _item['stock_in_data'].length; i++) {
			this.addNewStockInItem(_item['stock_in_data'][i]);
		}
	}

	// 產生單據日期字串
	generateSheetDate(_sheetDate) {
		return _sheetDate.substring(0, 10);
	}

	// 增加進貨品項
	addNewStockInItem(_item) {
		let _warehouseName = this.warehouses$.filter(x => { return x.WarehouseID == _item.WarehouseID})[0]['WarehouseName'];
		(this.detailStockInForm.get('StockInItems') as FormArray).push(this.stockInBodyForm(
			_item.ID,
			_item.Serial,
			_item.StockSheetCode,
			_item.WarehouseID,
			_warehouseName,
			_item.PurchaseCode,
			_item.ItemMaterialCode,
			_item.ItemName,
			_item.ItemSpec,
			_item.ItemUnitCode,
			_item.ItemUnitName,
			_item.ItemCurrency,
			_item.ItemQuantity,
			_item.AcceptQuantity,
			_item.Cost
		));
	}

	// 進貨品項
	private stockInBodyForm(_id, _serial, _sheetCode, _warehouseID, _warehouseName, _purhcaseCode, _materialCode, _itemName, _itemSpec, _itemUnitCode, _itemUnitName, _itemCurrency, _itemQuantity, _acceptQuantity, _cost): FormGroup {
		return new FormGroup({
			ID: new FormControl(_id), // 隱藏參數
			Serial: new FormControl(_serial),
			StockSheetCode: new FormControl(_sheetCode),
			WarehouseID: new FormControl(_warehouseID),
			WarehouseName: new FormControl(_warehouseName),
			PurchaseCode: new FormControl(_purhcaseCode),
			ItemMaterialCode: new FormControl(_materialCode),
			ItemName: new FormControl(_itemName),
			ItemSpec: new FormControl(_itemSpec),
			ItemUnitCode: new FormControl(_itemUnitCode),
			ItemUnitName: new FormControl(_itemUnitName),
			ItemCurrency: new FormControl(_itemCurrency),
			ItemQuantity: new FormControl(_itemQuantity),
			AcceptQuantity: new FormControl(_acceptQuantity),
			Cost: new FormControl(_cost)
		});
	}

	// 回到瀏覽畫面
	backToBrowsePage() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsDetail = false;
		window.scrollTo(0, 0);
	}

	// 確認該成員有無權限進行作廢動作
	// 只有權限 101, 104 才能進行作廢
	chekcIsDisAbleToDestroy() {
		switch (this.userPower) {
			case 101:
			case 104:
				return false;
				break;
			case 102:
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

	// 進行進貨單作廢
	returnStockIn() {
		this.loadingService.startLoading("入庫單作廢中...");
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		// _erpCompid, _stocksSheetCode, _inventoryType, _inventoryTypeName, _operatorid, _loguserDeivce
		this.stockinService.returnStockIn(
			this.detailStockInForm.value['ERPCompID'],
			this.detailStockInForm.value['StockSheetCode'],
			204,
			'入庫作廢',
			_currentUserInfo.ERPUserID,
			this.globalService.detectUserAgent()
		).subscribe(response => {
			if (response.IsSuccess) {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('成功作廢入庫單據!');
				this.stockinService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsDetail = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg(response.message);
			}
			window.scrollTo(0, 0);
			this.loadingService.stopLoading();
		});
	}

	// 進貨日期發生變化
	sheetDateChange($event) {
		this.loadingService.startLoading('重新產生進貨單號...');
		let _currentDateString = $event.year + "-" + $event.month + "-" + $event.day;
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.stockinService.generateStockInCode(
				_erpCompid,
				_currentDateString,
				1
			).subscribe(response => {
				if (response.IsSuccess) {
					this.stockinForm.patchValue({
						StockSheetCode: response.SheetCode,
						SheetDate: { "year": $event.year, "month": $event.month, "day": $event.day}
					});
				}
				this.loadingService.stopLoading();
			});
		}
	}

}
