import { Component } from '@angular/core';
import { Router } from '@angular/router';
// loading interface
import {Menuitem} from './interface/menuitem';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'SuccMail ERP';

  constructor(private router: Router){}

	onActivate(_event) {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
	}

  MenuItem: Menuitem[] = [
    {
      title: '公司資料',
      icon: './assets/images/compBuilding.svg',
      alt: '前往公司資料',
      href: 'companyModify'
    },
    {
      title: '供應商資料',
      icon: './assets/images/supplier.svg',
      alt: '前往供應商資料',
      href: 'supplier'
    },
    {
      title: '客戶資料',
      icon: './assets/images/customer.svg',
      alt: '前往客戶資料',
      href: 'customer'
    },
    {
      title: '成員設定',
      icon: './assets/images/setting.svg',
      alt: '前往成員設定',
      href: 'config'
    },
    {
      title: '品項資料',
      icon: './assets/images/items.svg',
      alt: '前往品項資料',
      href: 'product'
    },
    {
      title: '單位資料',
      icon: './assets/images/tags.svg',
      alt: '前往單位資料',
      href: 'productUnit'
    },
    {
      title: '採購單據',
      icon: './assets/images/order.svg',
      alt: '前往採購單資料',
      href: 'purchase'
    },
    {
      title: '進貨單據',
      icon: './assets/images/items.svg',
      alt: '前往進貨單資料',
      href: 'stockIn'
    },
    {
      title: '倉庫設定',
      icon: './assets/images/warehouse.svg',
      alt: '前往倉庫設定',
      href: 'warehouse'
    },
    {
      title: '訂單單據',
      icon: './assets/images/order.svg',
      alt: '前往訂單資料',
      href: 'order'
    },
    {
      title: '銷貨單據', 
      icon: './assets/images/stocksell.svg',
      alt: '前往銷貨單設定',
      href: 'stockSell'
    },
    {
      title: '檢驗規則',
      icon: './assets/images/setting.svg',
      alt: '前往檢驗規則設定',
      href: 'validaterule'
    }
  ];

  goToPage(_href: string) {
    console.log(_href);
    let _hrefStr = "/" + _href;
    this.router.navigate([_hrefStr]);
  }

}
