import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
// import Service 
import { GlobalService } from '../../service/global.service';

@Component({
	selector: 'app-msgremind',
	templateUrl: './msgremind.component.html',
	styleUrls: ['./msgremind.component.css'],
	animations: [
		trigger('RemindWindow', [
			state('open', style({
				opacity: 1,
				width: '100%',
				zIndex: 9
			})),
			state('closed', style({
				opacity: 0,
				height: '0%',
				zIndex: -1
			})),
			transition('void => *', [
			]),
			transition('* => closed', [
				animate('0.5s')
			])
		]),
	]
})
export class MsgremindComponent implements OnInit {

	// 傳遞訊息開關狀態
	remindState: string = "closed"
	// 傳遞訊息類型 0: 無動作, 1: 成功, 2: 失敗;
	remindType: number = 0;
	// 傳遞訊息
	remindMessage: string = "MSg";

	constructor(private globalService: GlobalService) { }

	ngOnInit() {
		// 提醒訊息開關狀態
		this.globalService.currentState.subscribe(state => {
			this.remindState = state;
		});
		// 提醒訊息類型
		this.globalService.currentType.subscribe(type => {
			this.remindType = type;
		});
		// 提醒訊息內容
		this.globalService.currentMsg.subscribe(msg => {
			this.remindMessage = msg;
		});

	}
}


		// trigger('RemindWindow', [
		// 	state('open', style({
		// 		opacity: 1.0,
		// 		height: '100%',
		// 		width: '100%',
		// 		display: 'block'
		// 	})),
		// 	state('closed', style({
		// 		opacity: 0,
		// 		height: '0%',
		// 		width: '0%'
		// 	}))
		// ])