import { Component, AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
// 把自己控制參數的 Service 拉進來
import { LoadingService } from '../../service/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements AfterViewInit {

	loadingMsg: string;
	loading: boolean = false;
	subscription: Subscription;

	constructor(
		private loadingService: LoadingService
	) { 
		this.subscription = this.loadingService.loadingSubject.subscribe((paramArr) => {
			this.loading = paramArr.isLoading;
			this.loadingMsg = paramArr.loadingMsg;
		});
		// simple service for receiving text
		// this.subscription = this.loadingService.getMessage().subscribe(message => {
		// 	console.log(message);
		// });
	}

	// ngOnInit() {
	// 	console.log("in loading init");
	// }

	ngAfterViewInit(): void {
		// console.log("1");
		// this._elmRef.nativeElement.style.display = 'none';
		// this.loadingSubscription = this.loadingService.loadingStatus.pipe(debounceTime(this.debounceTime)).subscribe(
		// 	(status: boolean) => {
		// 		console.log("2");
		//         this._elmRef.nativeElement.style.display = status ? 'block' : 'none';
		//         this._changeDetectorRef.detectChanges();
		// 	}
		// );
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
