import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// 平行化執行 Observable 事件
import { forkJoin } from 'rxjs';
// 呼叫Service
import { CompanyService } from '../../service/company.service';
import { GlobalService } from '../../service/global.service';
import { LoadingService } from '../../service/loading.service';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

	compModel: any = {
		CompName: "",
		CompAddr: "",
		CompTaxCode: "",
		PhoneNum: "",
		FaxNum: ""
	};
	userModel: any = {
		UserName: "",
		UserEmail: ""
	};

	constructor(
		private router: Router,
		private route: ActivatedRoute, 
		private compService: CompanyService,
		private globalService: GlobalService,
		private loadingService: LoadingService
	) { }

	ngOnInit() {
		// 檢查是不是需要導回去登入頁面
		let _tokenInfo = localStorage.getItem('Token');
		if(_tokenInfo == undefined || _tokenInfo == null) {
			// 有問題的存取狀況下，打回登入頁面
			this.router.navigate(['/login']);
		}
		// 將Succmail帶來的資料填入為預設值
		this.setDefaultValue();

	}

	setDefaultValue() {
		let _compInfo = JSON.parse(localStorage.getItem('compInfo'))[0];
		let _loginInfo  = JSON.parse(localStorage.getItem('loginInfo'))[0];
		let _userInfo  = JSON.parse(localStorage.getItem('userInfo'))[0];

		this.compModel.CompName = _compInfo.ShortName;
		this.userModel.UserEmail = _loginInfo.loginEmail;
		this.userModel.UserName = _userInfo.LastName + _userInfo.FirstName;
	}

	// 初次建立公司和使用者
	onSubmit() {
		this.loadingService.startLoading("資料初始中...");
		let _succUserID = JSON.parse(localStorage.getItem('userInfo'))[0]['UserID'];
		let _succCompID = JSON.parse(localStorage.getItem('userInfo'))[0]['CompID'];
		let _userAgent = this.globalService.detectUserAgent();
		let _userDevice = this.globalService.detectUserDevice();
		let _newERPUserID = this.globalService.generateGUID();
		let _newERPCompID = this.globalService.generateGUID();
		// 建立ERP使用者參數
		let _createUserParam = {
			NewERPUserID: _newERPUserID,
			SuccUserID: _succUserID,
			ERPCompID: _newERPCompID,
			SuccCompID: _succCompID,
			UserName: this.userModel.UserName,
			UserEmail: this.userModel.UserEmail,
			Power: 101,
			LogUserDevice: _userAgent + "|" + _userDevice
		};
		// 建立ERP公司參數
		let _createCompParam = {
			NewERPCompID: _newERPCompID,
			SuccCompID: _succCompID,
			CompName: this.compModel.CompName,
			CompAddress: this.compModel.CompAddr,
			CompTaxCode: this.compModel.CompTaxCode,
			CompPhoneNum: this.compModel.PhoneNum,
			CompFaxNum: this.compModel.FaxNum,
			ERPUserID: _newERPUserID,
			OperatorID: _newERPUserID,
			LogUserDevice: _userAgent + "|" + _userDevice
		};
		// 建立成員
		let _createUser = this.compService.createERPUser(_createUserParam);
		// 建立公司
		let _createComp = this.compService.createERPComp(_createCompParam);

		// 平行化執行
		forkJoin([_createUser, _createComp]).subscribe(results => {
			if(results[0]['IsSuccess'] && results[1]['IsSuccess']) {
				this.router.navigate(['/companyList']);	
			}
			else {
				alert("資料初始化失敗！");
			}
			this.loadingService.stopLoading();
		});
		
	}


}
