import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { ProductService } from '../../service/product.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import {ProductItem} from '../../interface/productitem';
// 建立表單控制套件
import { FormGroup, FormControl } from '@angular/forms';
// 下載資料連結
import config from '../../config/constant.json';
// 備註編輯器
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.css'],
	providers: [ProductService, DecimalPipe]
})
export class ProductComponent implements OnInit {

	productItems$: Observable<ProductItem[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	IsDetail: boolean = false;
	IsBatch: boolean = false;
	categories$: any = [];
	units$: any = [];
	activeUnits$: any = [];
	inventory$: any = [];
	warehouses$: any = [];
	userPower = 103;
	// 用戶自行上傳表單資料
	sheetFile: File = null;
	WarehouseID: string = "";

	// 新增品項使用的 form
	productItemForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		ItemMaterialCode: new FormControl(''),
		ItemName: new FormControl(''),
		ItemCategoryID: new FormControl(''),
		ItemSpec: new FormControl(''),
		ItemUnitCode: new FormControl(''),
		ItemUnitName: new FormControl(''),
		ItemResourceID: new FormControl(''),
		IsActive: new FormControl('1'),
		ItemInitQuantity: new FormControl(0),
		ItemPrice: new FormControl(0),
		ItemWarehouse: new FormControl(''),
		Memo: new FormControl('<p><br /></p>')
	});

	// 編輯品項使用的 form
	modifyItemForm = new FormGroup({
		ItemID: new FormControl(''), // 隱藏參數
		ERPCompID: new FormControl(''), // 隱藏參數
		ItemMaterialCode: new FormControl(''),
		ItemName: new FormControl(''),
		ItemPrice: new FormControl(0),
		ItemCategoryID: new FormControl(''),
		ItemSpec: new FormControl(''),
		ItemUnitCode: new FormControl(''),
		ItemUnitName: new FormControl(''),
		ItemResourceID: new FormControl(''),
		IsActive: new FormControl('1'),
		Memo: new FormControl('<p><br /></p>')
	});

	// 檢視品項使用的 form
	detailItemForm = new FormGroup({
		ItemID: new FormControl(''), // 隱藏參數
		ERPCompID: new FormControl(''), // 隱藏參數
		ItemMaterialCode: new FormControl(''),
		ItemName: new FormControl(''),
		ItemPrice: new FormControl(0),
		ItemCategoryID: new FormControl(''),
		ItemSpec: new FormControl(''),
		ItemUnitCode: new FormControl(''),
		ItemUnitName: new FormControl(''),
		ItemResourceID: new FormControl(''),
		IsActive: new FormControl('1'),
		Memo: new FormControl('<p><br /></p>')
	});

	editorConfig: AngularEditorConfig = {
	    editable: true,
	      height: 'auto',
	      minHeight: '50',
	      maxHeight: '200',
	      width: 'auto',
	      minWidth: '0',
	      enableToolbar: false,
	      showToolbar: false,
	      placeholder: 'Enter memo here...',
	      defaultParagraphSeparator: 'p',
	      defaultFontName: '',
	      defaultFontSize: '12',
	      fonts: [
	        {class: 'arial', name: 'Arial'},
	        {class: 'times-new-roman', name: 'Times New Roman'},
	        {class: 'calibri', name: 'Calibri'},
	        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
	      ],
	      customClasses: [
	      {
	        name: 'quote',
	        class: 'quote',
	      },
	      {
	        name: 'redText',
	        class: 'redText'
	      },
	      {
	        name: 'titleText',
	        class: 'titleText',
	        tag: 'h1',
	      },
	    ],
	    uploadUrl: 'v1/image',
	    sanitize: false,
	    toolbarPosition: 'top'
	};

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public productService: ProductService
	) {
		this.productItems$ = productService.productitems$;
		this.total$ = productService.total$;
	}

	onSort({column, direction}: SortEvent) {
		// resetting other headers
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.productService.sortColumn = column;
		this.productService.sortDirection = direction;
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
		this.loadingService.stopLoading();
		this.loadingService.startLoading('確認倉庫資料');
		// 預設可以進行編輯
		this.editorConfig.editable = true;
		// 確認是否有建立倉庫資料
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			// 取得倉庫資料
			this.productService.getWarehouseList(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this.warehouses$ = response.WarehouseList;
					if (response.WarehouseList.length < 1) {
						this.globalService.changeRemindState('open');
						this.globalService.changeRemindType(1);
						this.globalService.changeRemindMsg('請先建立倉庫資料!');
						this.router.navigate(['/warehouse']);
					}
					else {
						this.WarehouseID = response.WarehouseList[0]['WarehouseID'];
					}
				}
				this.loadingService.stopLoading();
			});
			// 取得公司產品類別資料
			this.productService.getProductCateList(_erpCompid).subscribe(response => {
				if (response.result) {
					this.categories$ = response.ItemCateList;
				}
			});
			// 取得產品單位資料
			this.productService.getItemsUnitList(_currentERPComp.ERPCompID).subscribe(response => {
				if (response.IsSuccess) {
					this.units$ = response.ItemsUnitList;
				}
			});
			// 取得公司啟用中單位資料
			this.productService.getActiveUnitList(_currentERPComp.ERPCompID).subscribe(response => {
				if (response.IsSuccess) {
					this.activeUnits$ = response.ItemsUnitList;
				}
			});
		}
		else {
			this.loadingService.stopLoading();
			this.router.navigate(['/login']);
		}
	}

	// 前往新增產品品項頁面
	goToNewProductPage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		// 產品備註可進行編輯
		this.editorConfig.editable = true;
		// 更改顯示頁面參數
		this.IsBrowsing = false;
		this.IsCreate = true;
		this.IsEdite = false;
		this.IsDetail = false;
		this.IsBatch = false;
		// 呼叫Server產生新的產品編號
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.productService.generateItemMaterialCode(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this.productItemForm.patchValue({
						ERPCompID: _erpCompid,
						ItemMaterialCode: response.ItemMaterialCode,
						ItemName: '',
						ItemCategoryID: '',
						ItemSpec: '',
						ItemUnitCode: '',
						ItemUnitName: '',
						ItemResourceID: '',
						IsActive: '1',
						ItemInitQuantity: 0,
						ItemPrice: 0,
						Memo: '<p><br /></p>'
					});
					this.loadingService.stopLoading();
				}
				else {
					this.loadingService.stopLoading();
					this.router.navigate(['/product']);
				}
			});
		}
		else {
			this.loadingService.stopLoading();
			this.router.navigate(['/login']);
		}
	}

	// 前往批次新增品項畫面
	goToNewMultiplePage() {
		this.loadingService.startLoading('讀取資料中...');
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = false;
		this.IsDetail = false;
		this.IsBatch = true;
		this.loadingService.stopLoading();
	}

	// 修改產品品項
	updateProduct(_productItem) {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		// 產品備註可進行編輯
		this.editorConfig.editable = true;
		// 如果原有的單位已經停用，進行防呆
		let _itemUnitCode = _productItem.ItemUnitCode;
		let _itemUnitName = _productItem.ItemUnitName;
		let _itemUnitObj = this.units$.filter(x => x.ItemUnitCode == _productItem.ItemUnitCode)[0];
		if (_itemUnitObj != undefined) {
			if (_itemUnitObj.IsActive == 9) {
				_itemUnitCode = null;
				_itemUnitName = "";
			}
		}
		else {
			_itemUnitCode = null;
			_itemUnitName = "";
		}

		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = true;
		this.IsDetail = false;
		this.IsBatch = false;
		this.modifyItemForm.patchValue({
			ItemID: _productItem.ItemID,
			ERPCompID: _productItem.ERPCompID,
			ItemMaterialCode: _productItem.ItemMaterialCode,
			ItemName: _productItem.ItemName,
			ItemCategoryID: _productItem.ItemCategoryID,
			ItemSpec: _productItem.ItemSpec,
			ItemUnitCode: _itemUnitCode,
			ItemUnitName: _itemUnitName,
			ItemResourceID: _productItem.ItemResourceID,
			Memo: _productItem.Memo.length > 0 ? _productItem.Memo:"<p><br /></p>",
			IsActive: _productItem.IsActive.toString()
		});
		this.loadingService.stopLoading();
	}

	// 建立產品品項送到 Server
	onSubmitProduct() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('建立品項中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;
		let param = {
			ERPCompID: _erpCompid,
			OperatorID: _erpUserid,
			Memo: this.productItemForm.value['Memo'],
			// WarehouseID: this.warehouses$[0]['WarehouseID'],
			WarehouseID: this.productItemForm.value['ItemWarehouse'],
			ItemMaterialCode: this.productItemForm.value['ItemMaterialCode'],
			ItemCategoryID: this.productItemForm.value['ItemCategoryID'],
			ItemName: this.productItemForm.value['ItemName'],
			ItemSpec: this.productItemForm.value['ItemSpec'],
			ItemUnitCode: this.productItemForm.value['ItemUnitCode'],
			ItemUnitName: this.productItemForm.value['ItemUnitName'],
			TotalInventory: this.productItemForm.value['ItemInitQuantity'],
			ItemPrice: this.productItemForm.value['ItemPrice'],
			ItemResourceID: this.productItemForm.value['ItemResourceID'],
			IsActive: this.productItemForm.value['IsActive'],
			LogUserDevice: _logUserDevice,
			InventoryType: 101,
			InventoryTypeName: '期初入庫'
		};
		this.productService.createProductItem(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.productService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 品項類別發生變化
	onCategoryChange(_target, _type) {
		console.log(_target);
		console.log(_type);
	}

	// 品項單位發生變化
	onUnitChange(_target, _type) {
		let unitName = this.units$.filter(item => {
			return item.ItemUnitCode == parseInt(_target);
		})[0]['ItemUnitName'];
		console.log(unitName);
		if (_type == 0) {
			this.productItemForm.patchValue({
				ItemUnitName: unitName
			});
		}
		else {
			this.modifyItemForm.patchValue({
				ItemUnitName: unitName
			});
		}
	}

	// 取消建立新品項
	cancelCreateProductItem() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		this.IsDetail = false;
		this.IsBatch = false;
		window.scrollTo(0, 0);
	}

	// 產生類別名稱
	generateCateName(_categoryid) {
		let _categoryName = this.categories$.filter(res => res.ID == _categoryid)[0] == undefined ? '未設定':this.categories$.filter(res => res.ID == _categoryid)[0]['ItemName'];
		return _categoryName;
	}

	// 將品項修改的需求送到 server
	onUpdateProduct() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('資料修正中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpUserid = _currentUserInfo.ERPUserID;

		let param = {
			ItemID: this.modifyItemForm.value['ItemID'],
			ERPCompID: this.modifyItemForm.value['ERPCompID'],
			OperatorID: _erpUserid,
			Memo: this.modifyItemForm.value['Memo'],
			ItemMaterialCode: this.modifyItemForm.value['ItemMaterialCode'],
			ItemCategoryID: this.modifyItemForm.value['ItemCategoryID'],
			ItemName: this.modifyItemForm.value['ItemName'],
			ItemPrice: this.modifyItemForm.value['ItemPrice'],
			ItemSpec: this.modifyItemForm.value['ItemSpec'],
			ItemUnitCode: this.modifyItemForm.value['ItemUnitCode'],
			ItemUnitName: this.modifyItemForm.value['ItemUnitName'],
			TotalInventory: 0,
			ItemResourceID: this.modifyItemForm.value['ItemResourceID'],
			IsActive: this.modifyItemForm.value['IsActive'],
			LogUserDevice: _logUserDevice
		};

		this.productService.updateProductItem(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料修改成功!');
				this.productService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
				this.IsDetail = false;
				this.IsBatch = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料修改錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 取消修改品項
	cancelUpdateProductItem() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		this.IsDetail = false;
		this.IsBatch = false;
		window.scrollTo(0, 0);
	}

	// 查看品項詳細資料
	checkStockIn(_productItem) {
		console.log("stock in");
		console.log(_productItem);
		this.loadingService.startLoading('讀取資料');
		// 產品備註不可進行編輯
		this.editorConfig.editable = false;
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = false;
		this.IsDetail = true;
		this.IsBatch = false;
		this.detailItemForm.patchValue({
			ItemID: _productItem.ItemID,
			ERPCompID: _productItem.ERPCompID,
			ItemMaterialCode: _productItem.ItemMaterialCode,
			ItemName: _productItem.ItemName,
			Memo: _productItem.Memo.length > 0 ? _productItem.Memo : "<p><br /></p>",
			ItemPrice: _productItem.ItemPrice,
			ItemCategoryID: _productItem.ItemCategoryID,
			ItemSpec: _productItem.ItemSpec,
			ItemUnitCode: _productItem.ItemUnitCode,
			ItemUnitName: _productItem.ItemUnitName,
			ItemResourceID: _productItem.ItemResourceID,
			IsActive: _productItem.IsActive.toString()
		});
		this.productService.getInventoryList(
			_productItem.ERPCompID, 
			_productItem.ItemMaterialCode
		).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				this.inventory$ = response.ItemInventoryList;
			}
			this.loadingService.stopLoading();
		});
		
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);		
	}

	// 回到瀏覽品項畫面
	backToBrowsePage() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		this.IsDetail = false;
		this.IsBatch = false;
		window.scrollTo(0, 0);
	}

	// 產生倉庫名稱
	generateWarehouseName(_warehouseID): string {
		return this.warehouses$.filter(x => {return x.WarehouseID == _warehouseID;})[0]['WarehouseName'];
	}

	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

	// 下載Excel Template
	DownloadExcelTemplate() {
		let FileExt = "xls";
		let _compID = "af00535b-7a92-4b7b-9964-bd3a02823718";
		let _attID = '560bd387-8151-41c9-a7bc-359763354ea5';
		let _filename = "The template for importing product items.xls";
		let RequestPath = config.Download_URL + "?CompID=" + _compID + "&AttID=" + _attID + "&Ext=" + "xls" + "&FileName=" + escape(_filename);
		window.open(RequestPath, '_blank', '');
	}

	// 匯入Excel表
	onImportExcel(_event) {
		this.loadingService.startLoading("表單解析中...");
		this.sheetFile = _event.target.files[0];
		if (!this.sheetFile) {
			return;
		}

		let _logUserDevice = this.globalService.detectUserAgent();

		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));

		let _succCompID = _currentERPComp.SuccCompID;
		let _erpCompid = _currentERPComp.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;

		const formData = new FormData();
		formData.append("CompID", _succCompID);
		formData.append("file", this.sheetFile);

		// 上傳表單
		this.productService.uploadSheetData(formData).subscribe(response => {
			
			if (response.IsSuccess) {
				this.loadingService.stopLoading();
				this.loadingService.startLoading("寫入產品資料中...");
				let _productItems = response.ProductItems;

				this.productService.createProductItemsFromSheet({
					ERPCompID: _erpCompid,
					OperatorID: _erpUserid,
					WarehouseID: this.WarehouseID,
					InventoryType: 101,
					InventoryTypeName: '期初入庫',
					SheetItem: JSON.stringify(_productItems),
					LogUserDevice: _logUserDevice
				}).subscribe(insert_response => {
					console.log(insert_response);
					if (insert_response.IsSuccess) {
						this.loadingService.stopLoading();
						this.globalService.changeRemindState('open');
						this.globalService.changeRemindType(0);
						this.globalService.changeRemindMsg('產品新增成功!');
						this.productService.refreshDataList();
						this.IsBrowsing = true;
						this.IsCreate = false;
						this.IsEdite = false;
						this.IsDetail = false;
						this.IsBatch = false;
						window.scrollTo(0, 0);
					}
					else {
						this.loadingService.stopLoading();
						this.globalService.changeRemindState('open');
						this.globalService.changeRemindType(1);
						this.globalService.changeRemindMsg('表單上傳失敗!');
						this.IsBrowsing = true;
						this.IsCreate = false;
						this.IsEdite = false;
						this.IsDetail = false;
						this.IsBatch = false;
						window.scrollTo(0, 0);
					}
				});

			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('表單上傳失敗!');
				this.loadingService.stopLoading();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
				this.IsDetail = false;
				this.IsBatch = false;
				window.scrollTo(0, 0);
			}

		});
	}

}