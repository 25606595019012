import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { WarehouseService } from '../../service/warehouse.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import {Warehouse} from '../../interface/warehouse';
// 建立表單控制套件
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: 'app-warehouse',
	templateUrl: './warehouse.component.html',
	styleUrls: ['./warehouse.component.css'],
	providers: [WarehouseService, DecimalPipe]
})
export class WarehouseComponent implements OnInit {

	warehouses$: Observable<Warehouse[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	// 預設使用者權限為最低
	userPower = 103;

	// 新增使用的 form
	warehouseForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		WarehouseID: new FormControl(''),
		WarehouseName: new FormControl(''),
		IsActive: new FormControl('1')
	});
	// 編輯使用的 form
	modifyWarehouseForm = new FormGroup({
		ID: new FormControl(''), // PK
		ERPCompID: new FormControl(''), // 隱藏參數
		WarehouseID: new FormControl(''),
		WarehouseName: new FormControl(''),
		IsActive: new FormControl('1')
	});

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public warehouseService: WarehouseService
	) {
		this.warehouses$ = warehouseService.warehouse$;
		this.total$ = warehouseService.total$;
	}

	onSort({column, direction}: SortEvent) {
		// resetting other headers
		console.log("headers : ", this.headers);
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.warehouseService.sortColumn = column;
		this.warehouseService.sortDirection = direction;
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
	}

	// 前往建立warehouse 頁面
	goToNewWarehousePage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		// 更改顯示頁面參數
		this.IsBrowsing = false;
		this.IsCreate = true;
		this.IsEdite = false;
		// 呼叫Server產生新的倉庫編號
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.warehouseService.generateWarehouseCode(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					// 綁定確定後 幫資料送入Form表格
					this.warehouseForm.patchValue({
						ERPCompID: _erpCompid,
						WarehouseID: response.WarehouseCode,
						WarehouseName: '',
						IsActive: '1'
					});
					console.log(this.warehouseForm);
					this.loadingService.stopLoading();
				}
				else {
					this.loadingService.stopLoading();
					this.router.navigate(['/warehouse']);
				}
			});
		}
		else {
			this.loadingService.stopLoading();
			this.router.navigate(['/login']);
		}
	}

	// 建立Warehouse資料到 Server
	onSubmitWarehouse() {
		console.log("submit warehouse to server");
		this.loadingService.startLoading('建立倉庫中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;
		let param = {
			ERPCompID: _erpCompid,
			WarehouseID: this.warehouseForm.value['WarehouseID'],
			WarehouseName: this.warehouseForm.value['WarehouseName'],
			OperatorID: _erpUserid,
			LogUserDevice: _logUserDevice,
			IsActive: this.warehouseForm.value['IsActive']
		};
		this.warehouseService.createWarehouse(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.warehouseService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 取消建立Warehouse
	cancelCreateWarehouse() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 前往更新倉庫頁面
	updateWarehouse(_warehouse) {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = true;
		// 灌值給Form表
		this.modifyWarehouseForm.patchValue({
			ID: _warehouse.ID,
			ERPCompID: _warehouse.ERPCompID,
			WarehouseID: _warehouse.WarehouseID,
			WarehouseName: _warehouse.WarehouseName,
			IsActive: _warehouse.IsActive.toString()
		});
		this.loadingService.stopLoading();
	}

	// 取消更新倉庫
	cancelUpdateWarehouse() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 更新倉庫資料到Server
	onUpdateWarehouse() {
		this.loadingService.startLoading('資料修正中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpUserid = _currentUserInfo.ERPUserID;

		let param = {
			ID: this.modifyWarehouseForm.value['ID'],
			ERPCompID: this.modifyWarehouseForm.value['ERPCompID'],
			WarehouseID: this.modifyWarehouseForm.value['WarehouseID'],
			WarehouseName: this.modifyWarehouseForm.value['WarehouseName'],
			OperatorID: _erpUserid,
			LogUserDevice: _logUserDevice,
			IsActive: this.modifyWarehouseForm.value['IsActive']
		};
		this.warehouseService.updateWarehouse(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料修正成功!');
				this.warehouseService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料修正錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);		
	}

	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

}
