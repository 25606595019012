import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// 呼叫Service
import { CompanyService } from '../../service/company.service';
import { GlobalService } from '../../service/global.service';
import { LoadingService } from '../../service/loading.service';


@Component({
  selector: 'app-compmodify',
  templateUrl: './compmodify.component.html',
  styleUrls: ['./compmodify.component.css']
})
export class CompmodifyComponent implements OnInit {

	// 判斷是否有進行修改
	isModify: boolean = false;
	// 存放公司資料模型
	compModel: any = {
		CompName: "",
		CompAddr: "",
		CompTaxCode: "",
		PhoneNum: "",
		FaxNum: ""
	};
	// 備存公司資料模型 (取消後可以還原)
	backUpCompModel: any = {
		CompName: "",
		CompAddr: "",
		CompTaxCode: "",
		PhoneNum: "",
		FaxNum: ""
	};
	// 成員列表
	memberList: any = [];
	// 最高權限成員的UserID
	managerID: string = "";
	// 儲存最高權限成員的UserID
	backUpManagerID: string = "";
	// 預設使用者權限為最低
	userPower = 103;


	constructor(
		private router: Router,
		private compService: CompanyService,
		private globalService: GlobalService,
		private loadingService: LoadingService
	) { }

	ngOnInit() {
		this.loadingService.startLoading("資料讀取中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		let _userInfo = JSON.parse(localStorage.getItem('userInfo'));
		if (_currentERPComp != null && _userInfo != null) {
			this.compModel.CompName = _currentERPComp.CompName;
			this.compModel.CompAddr = _currentERPComp.CompAddress;
			this.compModel.CompTaxCode = _currentERPComp.CompTaxCode;
			this.compModel.PhoneNum = _currentERPComp.CompPhoneNum;
			this.compModel.FaxNum = _currentERPComp.CompFaxNum;
			this.backUpCompModel = this.compModel;
			// 取得公司成員列表 目前先限定最高管理員只有一位
			this.compService.getERPMemberList(_currentERPComp.ERPCompID).subscribe(response => {
				if (response.result) {
					this.memberList = response.MemberList.filter(res => { return res.IsActive == 1; });
					this.managerID = response.MemberList.filter(res => {
						console.log("member : ", res);
						return res.Power == 101 && res.IsActive == 1;
					})[0]['ERPUserID'];
					this.backUpManagerID = this.managerID;
				}
				this.loadingService.stopLoading();
			});
			this.loadingService.startLoading("權限確認中...");
			// 取得使用者權限
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
		else {
			this.loadingService.stopLoading();
			// 有問題的存取狀況下，打回登入頁面
			this.router.navigate(['/login']);
		}
	}

	// 有進行異動
	onUpdateIsModifyVal(_val: boolean) {
		console.log(_val);
		this.isModify = _val;
	}

	// 送出公司資料異動
	onSubmit() {
		this.loadingService.startLoading("資料讀取中...");
		let _erpCompID = JSON.parse(localStorage.getItem("currentERPComp")).ERPCompID;
		let _erpUserID = JSON.parse(localStorage.getItem("currentERPUser")).ERPUserID;
		let _userAgent = this.globalService.detectUserAgent();
		let _userDevice = this.globalService.detectUserDevice();
		this.compService.updateCompanyData(_erpCompID, _erpUserID, this.compModel, _userAgent + "|" + _userDevice, this.managerID).subscribe(response => {
			if (response.IsSuccess) {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料更新成功!');
				// 更新localS 和 component model 資料
				localStorage.removeItem('currentERPComp');
				localStorage.setItem('currentERPComp', JSON.stringify(response.CompInfo));
				this.compModel.CompName = response.CompInfo.CompName;
				this.compModel.CompAddr = response.CompInfo.CompAddress;
				this.compModel.CompTaxCode = response.CompInfo.CompTaxCode;
				this.compModel.PhoneNum = response.CompInfo.CompPhoneNum;
				this.compModel.FaxNum = response.CompInfo.CompFaxNum;
				this.backUpCompModel = this.compModel;
				this.loadingService.stopLoading();
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料更新錯誤!');
				this.loadingService.stopLoading();
			}
		});
	}

	// 取消異動
	onCancelSubmit() {
		this.isModify = false;
		this.compModel = this.backUpCompModel;
		this.managerID = this.backUpManagerID;
		this.router.navigate(['/home']);
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);
	}

}
