import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
// import config file
import config from '../config/constant.json';


@Injectable({
  providedIn: 'root'
})
export class MemberService {

	// Define API URL參數
	apiURL = config.APIURL;

	constructor(private http: HttpClient) { }

	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得成員列表
	getMemberList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_erp_member_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得Succmail公司目前在職的成員資料，已供建立成員使用
	getSuccMemberList(_token, _succCompid): Observable<any>{
		var param = {
			token: _token,
			CompID: _succCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/backend/get_active_user_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 建立成員
	createERPMember(_newERPUserid, _succUserid, _erpCompid, _succCompid, _userName, _userEmail, _power, _userDevice): Observable<any>{
		var param = {
			NewERPUserID: _newERPUserid,
			SuccUserID: _succUserid,
			ERPCompID: _erpCompid,
			SuccCompID: _succCompid,
			UserName: _userName,
			UserEmail: _userEmail,
			Power: _power,
			LogUserDevice: _userDevice
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/create_erp_member', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 更新成員
	updateERPMember(_erpUserid, _erpCompid, _userName, _isActive, _power, _userDevice): Observable<any>{
		var param = {
			ERPUserID: _erpUserid,
			ERPCompID: _erpCompid,
			UserName: _userName,
			IsActive: _isActive,
			Power: _power,
			LogUserDevice: _userDevice,
			OperatorID: _erpUserid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/update_erp_member', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))		
	}

}
