import { Injectable, PipeTransform } from '@angular/core';
// interface
import { Supplier } from '../interface/supplier';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';

// 搜尋結果承接的 Interface
interface SearchResult {
	suppliers: Supplier[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(suppliers: Supplier[], column: string, direction: string): Supplier[] {
	// 沒有進行排序
	if (direction === '') {
		return suppliers
	}
	else {
		return [...suppliers].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(supplier: Supplier, term: string, pipe: PipeTransform) {
	return supplier.SupplierCode.toLowerCase().includes(term.toLowerCase()) 
		 || supplier.SupplierName.toLowerCase().includes(term.toLowerCase())
		 || supplier.SupplierPhoneNum.toLowerCase().includes(term.toLowerCase())
		 || supplier.SupplierCityName.toLowerCase().includes(term.toLowerCase())
		 || supplier.SupplierZipName.toLowerCase().includes(term.toLowerCase())
		 || supplier.SupplierAddress.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
	providedIn: 'root'
})
export class SupplierService {

	// Define API URL參數
	apiURL = config.APIURL;

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _suppliers$ = new BehaviorSubject<Supplier[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(private pipe: DecimalPipe, private http: HttpClient) { 
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getSupplierList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.SupplierList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._suppliers$.next(result.suppliers);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getSupplierList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.SupplierList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._suppliers$.next(result.suppliers);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
	}

	// 從外部取參數
	get suppliers$() { return this._suppliers$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(supplierList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let suppliers = sort(supplierList, sortColumn, sortDirection);

		// 2. filter
		suppliers = suppliers.filter(supplier => matches(supplier, searchTerm, this.pipe));
		const total = suppliers.length;

		// 3. paginate
		suppliers = suppliers.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		console.log("suppliers : ", suppliers);
		return of({suppliers, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得供應商列表
	getSupplierList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_supplier_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得供應商最新編號
	getSupplierNewCode(_erpCompid): Observable<any> {
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/generate_supplier_code', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 建立供應商資料
	createSupplier(param): Observable<any> {

		return this.http.post<any>(
			this.apiURL + '/api/erp/create_supplier', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 修改供應商資料
	updateSupplier(param): Observable<any> {

		return this.http.post<any>(
			this.apiURL + '/api/erp/update_supplier', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}




}
