import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { OrderService } from '../../service/order.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import {BillHeader} from '../../interface/billheader';
// 建立表單控制套件
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
// 稅別選擇
import TaxStateObj from '../../config/taxState.json';
import PriceOfTaxObj from '../../config/priceoftax.json';
import CurrencyObj from '../../config/currency.json';
// 備註編輯器
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.css'],
	providers: [OrderService, DecimalPipe]
})
export class OrderComponent implements OnInit {

	myForm: FormGroup;

	orders$: Observable<BillHeader[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsDetail: boolean = false;
	IsEdit: boolean = false;
	provider$: any = [];
	activeProvider$: any = [];
	products$: any = [];
	activeProducts$: any = [];

	categories$: any = [];
	taxstates$: any = [];
	pricetax$: any = [];
	currency$: any = [];
	succdiscs$: any = [];

	// 預設使用者權限為最低
	userPower = 103;

	_year = new Date().getFullYear();
	_month = ('0' + (new Date().getMonth() + 1)).slice(-2);

	// 新增使用 Form
	orderForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		BillDate: new FormControl(''),
		PlanDate: new FormControl(''),
		AccDate: new FormControl({ year: this._year, month: this._month }),
		Memo: new FormControl('<p><br /></p>'),
		BillCode: new FormControl(''),
		BillType: new FormControl(1),
		BillProviderID: new FormControl(''),
		BillProviderName: new FormControl(''),
		BillProviderCode: new FormControl(''),
		BillProviderAddress: new FormControl(''),
		BillProviderPhoneNum: new FormControl(''),
		BillProviderFaxNum: new FormControl(''),
		DeliverCityCode: new FormControl(0),
		DeliverCityName: new FormControl(''),
		DeliverZipCode: new FormControl(''),
		DeliverZipName: new FormControl(''),
		DeliverAddress: new FormControl(''),
		CurrencyCode: new FormControl('1'),
		PriceOfTax: new FormControl(1), // 控制產品單價裡面有沒有含稅, 預設含稅
		Total: new FormControl(0),
		TaxFee: new FormControl(0),
		TaxState: new FormControl('1'), // 1: 免稅, 2: 應稅, 3: 零稅
		OrderItems: new FormArray([this.orderBodyForm(1, '')]),
		DiscID: new FormControl(null),
		SubjTagID: new FormControl(null),
		CusTagID: new FormControl(null),
		ReceiverArray: new FormControl(null),
		ReceiverNameArray: new FormControl(null)
	});
	// 詳細內容 Form
	detailForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		BillState: new FormControl(0), 
		BillDate: new FormControl(''),
		PlanDate: new FormControl(''),
		AccDate: new FormControl(''),
		Memo: new FormControl(''),
		BillCode: new FormControl(''),
		BillType: new FormControl(1),
		BillProviderID: new FormControl(''),
		BillProviderName: new FormControl(''),
		BillProviderCode: new FormControl(''),
		BillProviderAddress: new FormControl(''),
		BillProviderPhoneNum: new FormControl(''),
		BillProviderFaxNum: new FormControl(''),
		CurrencyCode: new FormControl('1'),
		PriceOfTax: new FormControl(1), // 控制產品單價裡面有沒有含稅
		PriceOfTaxStr: new FormControl(''), // 產品單價裡面有沒有含稅字串值
		Total: new FormControl(0),
		TaxFee: new FormControl(0),
		TaxState: new FormControl('1'), // 1: 應稅, 2: 免稅, 3: 零稅
		TaxStateStr: new FormControl(''),
		OrderItems: new FormArray([]),
		SubjID: new FormControl('')
	});
 
 
	editorConfig: AngularEditorConfig = {
	    editable: true,
	      height: 'auto',
	      minHeight: '50',
	      maxHeight: '200',
	      width: 'auto',
	      minWidth: '0',
	      enableToolbar: false,
	      showToolbar: false,
	      placeholder: 'Enter memo here...',
	      defaultParagraphSeparator: 'p',
	      defaultFontName: '',
	      defaultFontSize: '12',
	      fonts: [
	        {class: 'arial', name: 'Arial'},
	        {class: 'times-new-roman', name: 'Times New Roman'},
	        {class: 'calibri', name: 'Calibri'},
	        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
	      ],
	      customClasses: [
	      {
	        name: 'quote',
	        class: 'quote',
	      },
	      {
	        name: 'redText',
	        class: 'redText'
	      },
	      {
	        name: 'titleText',
	        class: 'titleText',
	        tag: 'h1',
	      },
	    ],
	    uploadUrl: 'v1/image',
	    sanitize: false,
	    toolbarPosition: 'top'
	};

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public orderService: OrderService
	) { 
		this.orders$ = orderService.order$;
		this.total$ = orderService.total$;

		this.taxstates$ = TaxStateObj.TaxStates;
		this.pricetax$ = PriceOfTaxObj.PriceOfTax;
		this.currency$ = CurrencyObj.Currency;
	}

	ngOnInit() {

	    this.myForm = this.fb.group({
	      
	    });

		this.loadingService.startLoading("權限確認中...");
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _token = localStorage.getItem('Token');
		// 取得使用者權限
		if (_currentCompInfo != null && _currentUserInfo != null) {
			this.globalService.getUserPowerID(_currentCompInfo.ERPCompID, _currentUserInfo.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
		this.loadingService.stopLoading();
		this.loadingService.startLoading('讀取訂購單據資料...');

		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			// 取得客戶列表資料
			this.orderService.getCustomerList(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this.provider$ = response.CustomerList;
				}
			});
			// 取得啟用中供應商列表資料
			this.orderService.getActiveCustomerList(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this.activeProvider$ = response.CustomerList;
					console.log(this.activeProvider$);
				}
			});
			// 取得啟用中產品列表
			this.orderService.getActiveProductItemsList(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this.activeProducts$ = response.ItemsList;
					console.log(this.activeProducts$);
				}
			});
		}
		this.loadingService.stopLoading();
		this.loadingService.startLoading('讀取討論組資料...');

		// 取得Succmail發送討論組列表資料
		if (_currentUserInfo != null && _token != null && _currentCompInfo != null) {
			this.orderService.getSuccDiscList(_currentCompInfo['SuccCompID'], _currentUserInfo['SuccUserID'], _token).subscribe(response => {
				let _activeDiscs = response.data.filter(res => { if(res.discussion_info) {return res.discussion_info['IsActive'] == 1 && res.discussion_info['DiscType'] == 2;} })
												.map(function(obj) { 
													return obj.discussion_info;
												});
				this.succdiscs$ = _activeDiscs;
				console.log(this.succdiscs$);
				this.loadingService.stopLoading();
			});
		}
		else {
			this.failHttpRequest('open', 1, '未授權狀態!');
			this.router.navigate(['/login']);
		}

	}

	test() {
		console.log("in");
		console.log(this.orderForm);
	}

	// 前往新增訂單頁面
	goToNewOrderPage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		this.IsBrowsing = false;
		this.IsCreate = true;
		this.IsDetail = false;
		// 組成今日日期字串
		let _year = new Date().getFullYear();
		let _month = new Date().getMonth() + 1;
		let _date = new Date().getDate();
		let _todayDateStr = _year + "-" + _month + "-" + _date;
		// 重置品項陣列
		while ((this.orderForm.get('OrderItems') as FormArray).length) {
			(this.orderForm.get('OrderItems') as FormArray).removeAt(0);
		}
		this.addNewProductItem();
		// 初始化編輯器可進行修改
		this.editorConfig.editable = true;
		// 呼叫Server產生新的採購單編號
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.orderService.generateOrderCode(_erpCompid, _todayDateStr).subscribe(response => {
				if (response.IsSuccess) {
					this.orderForm.patchValue({
						ERPCompID: _erpCompid,
						BillDate: { "year": _year, "month": _month, "day": _date},
						PlanDate: { "year": _year, "month": _month, "day": _date},
						AccDate: { year: this._year, month: this._month },
						Memo: '<p><br /></p>',
						BillCode: response.BillCode,
						BillType: 1,
						BillProviderID: '',
						BillProviderName: '',
						BillProviderCode: '',
						BillProviderAddress: '',
						BillProviderPhoneNum: '',
						BillProviderFaxNum: '',
						DeliverCityCode: '',
						DeliverCityName: '',
						DeliverZipCode: '',
						DeliverZipName: '',
						DeliverAddress: '',
						CurrencyCode: '1',
						PriceOfTax: 1, // 控制產品單價裡面有沒有含稅
						Total: 0,
						TaxFee: 0,
						TaxState: '1', // 1: 免稅, 2: 應稅, 3: 零稅
						PurchaseItems: [],
						ReceiverArray: [],
						ReceiverNameArray: [],
						DiscID: null
					});	
					this.loadingService.stopLoading();
				}
				else {
					this.loadingService.stopLoading();
					this.router.navigate(['/order']);
				}
			});
		}
		else {
			this.loadingService.stopLoading();
			this.router.navigate(['/login']);
		}
		// 檢查是否需要建立採購標籤
		this.IsNeedCreateSubjTag(0, '訂單');
	}

	// 回到主頁面
	goToMenuPage() {
		this.router.navigate(['/home']);
	}

	// 回到瀏覽訂單畫面
	backToBrowsePage() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsDetail = false;
	}

	// 產生單據日期字串
	generateBillDate(_billDate) {
		return _billDate.substring(0, 10);
	}

	// 產生客戶名字
	generateCustomerName(_providerID) {
		let _providerName = this.provider$.filter(x => x.CustomerID == _providerID)[0]['CustomerName'];
		return _providerName;
	}

	// 查看詳細資料
	checkDetail(_order) {
		console.log("order : ");
		console.log(_order);
		window.scrollTo(0, 0);
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsDetail = true;
		// 編輯器不可編輯
		this.editorConfig.editable = false;
		// 抽取客戶實體
		let _provider = this.generateProviderEntity(_order.BillProviderID);
		// 將值塞入 detailForm
		this.detailForm.patchValue({
			ERPCompID: _order.ERPCompID,
			BillState: _order.BillState,
			BillDate: _order.BillDate.substring(0, 10),
			PlanDate: _order.PlanDate == null ? "" : _order.PlanDate.substring(0, 10),
			AccDate: _order.AccDate == null ? "" : _order.AccDate.substring(0, 7),
			Memo: _order.Memo.length > 0 ? _order.Memo:"<p><br /></p>",
			BillCode: _order.BillCode,
			BillType: 1,
			BillProviderID: _order.BillProviderID,
			BillProviderName: this.generateProviderName(_order.BillProviderID),
			BillProviderCode: _provider['CustomerCode'],
			BillProviderAddress: _provider['CustomerZipCode'] + _provider['CustomerCityName'] + _provider['CustomerZipName'] + _provider['CustomerAddress'],
			BillProviderPhoneNum: _provider['CustomerPhoneNum'],
			BillProviderFaxNum: _provider['CustomerFaxNum'],
			CurrencyCode: _order['CurrencyCode'],
			PriceOfTax: _order['PriceOfTax'],
			PriceOfTaxStr: _order['PriceOfTax'] == 0 ? "未稅":"含稅",
			Total: _order['Total'],
			TaxFee: _order['TaxFee'],
			TaxState: _order['TaxState'],
			TaxStateStr: this.globalService.convertTaxState(_order['TaxState'].toString()),
			SubjID: _order['SuccSubjID']
		});
		// 重置品項陣列
		while ((this.detailForm.get('OrderItems') as FormArray).length) {
			(this.detailForm.get('OrderItems') as FormArray).removeAt(0);
		}
		// 塞入品項
		for (var i = 0; i< _order.order_bill_data.length; i++) {
			(this.detailForm.get('OrderItems') as FormArray).push(
				this.orderDetailForm(_order, i)
			);
		}
	}

	// 透過 providerID 產生 客戶 實體資料
	generateProviderEntity(_providerID) {
		let _providerEntity = this.provider$.filter(x => x.CustomerID == _providerID)[0];
		return _providerEntity;
	}

	// 送出 Order 到Server
	onSubmitOrder() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('建立訂單資料中...');
		// 全域參數
		let _token = localStorage.getItem("Token");
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserName = _currentUserInfo.UserName;
		let _erpUserid = _currentUserInfo.ERPUserID;

		// 取得判斷所有計算出的品項金額 小數點後第一位 是不是都是5 
		// 如果 true: 計算應稅外加時，稅額不進位只取到整數位
		// 如果 false: 計算應稅外加時，稅額從小數點後面逐個進位
		let _isFiveAtFirstPoint = this.calculateTotalPointIsFive(0);
		// 總數量
		let _totalQuantity = this.calculateTotalAmount(0);
		// 品項數量
		let _itemLength = (this.orderForm.get('OrderItems') as FormArray).length;
		// 錢相關
		let _total = 0;
		for (var i = 0; i < _itemLength; i++) {
			_total += (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemTotal'];
		}
		let _priceInfo = this.globalService.calculateTotalAndTaxFee(
			_isFiveAtFirstPoint,
			_itemLength,
			_total,
			this.orderForm.value['PriceOfTax'],
			this.orderForm.value['TaxState']
		);
		let _taxFee = _priceInfo.TaxFee;
		let _noTaxTotal = _priceInfo.NoTaxTotal;
		let _withTaxTotal = _priceInfo.WithTaxTotal;
		// 表頭參數
		let _billDate = this.orderForm.value['BillDate']['year']+"/"+this.orderForm.value['BillDate']['month']+"/"+this.orderForm.value['BillDate']['day'];
		let _planDate = this.orderForm.value['PlanDate']['year']+"/"+this.orderForm.value['PlanDate']['month']+"/"+this.orderForm.value['PlanDate']['day'];
		let _accDate = this.orderForm.value['AccDate']['year']+"/"+parseInt(this.orderForm.value['AccDate']['month'])+"/"+"01";

		// 準備要使用的列印PDF的參數
		let _paramObjForCreatePDF = {
			ERPCompName: _currentCompInfo.CompName,
			ERPCompAreaCode: '',
			ERPCompAddress: _currentCompInfo.CompAddress,
			ERPCompPhone: _currentCompInfo.CompPhoneNum,
			ERPCompFax: _currentCompInfo.CompFaxNum,
			WesternTodayDate: this.globalService.convertWesternDateStr(new Date(),"/"),
			WesternOrderDate: _billDate,
			PlanDate: _planDate,
			AccDate: this.orderForm.value['AccDate']['year']+"/"+this.orderForm.value['AccDate']['month'],
			CustomerID: this.orderForm.value['BillProviderCode'],
			CustomerCompName: this.orderForm.value['BillProviderName'],
			CustomerAddress: this.orderForm.value['BillProviderAddress'],
			CustomerPhoneNum: this.orderForm.value['BillProviderPhoneNum'],
			CustomerFaxNum: this.orderForm.value['BillProviderFaxNum'],
			LinkMan: '',			
			CurrencyID: this.orderForm.value['CurrencyCode'],
			CurrencyName: this.globalService.convertCurrencyShortID(this.orderForm.value['CurrencyCode']),
			CurrencyRate: 1,			
			SalesManID: '',
			SalesManName: _erpUserName,
			CreatorName: _erpUserName,
			Memo: encodeURIComponent(this.orderForm.value['Memo']),
			DepartmentID: '',
			DepartmentName: '',
			BillNo: this.orderForm.value['BillCode'],
			ItemTaxState: this.orderForm.value['PriceOfTax'] == 0 ? "未稅":"含稅",
			TotalItemCount: _itemLength,
			TotalItemAmount: _totalQuantity,
			NoTaxTotal: _priceInfo.NoTaxTotal,
			TaxFee: _priceInfo.TaxFee,
			WithTaxTotal: _priceInfo.WithTaxTotal,
			OrderSheetItem: this.GenerateOrderItemArray(_itemLength, 0),
			FooterTerms: '',
			CreatorCompID: _currentCompInfo.SuccCompID
		};
		// 建立訂單物件
		let _orderBill = {
			ERPCompID: _erpCompid,
			BillDate: _billDate,
			PlanDate: _planDate,
			AccDate: _accDate,
			Memo: this.orderForm.value['Memo'],
			BillCode: this.orderForm.value['BillCode'],
			BillType: this.orderForm.value['BillType'],
			OperatorID: _erpUserid,
			BillProviderID: this.orderForm.value['BillProviderID'],
			DeliverCityCode: '',
			DeliverCityName: '',
			DeliverZipCode: '',
			DeliverZipName: '',
			DeliverAddress: '',
			CurrencyCode: this.orderForm.value['CurrencyCode'],
			PriceOfTax: this.orderForm.value['PriceOfTax'],
			Total: _priceInfo.NoTaxTotal,
			TaxFee: _priceInfo.TaxFee,
			TaxState: this.orderForm.value['TaxState'],
			BillState: 0,
			BillBody: JSON.stringify(this.orderForm.value['OrderItems']),
			LogUserDevice: this.globalService.detectUserAgent()			
		};
		// 送到SuccMail的物件
		let _subjTitle = this.globalService.generateSubjTitle(_itemLength, (this.orderForm.get('OrderItems') as FormArray));
		var subjFormData = new FormData();
		subjFormData.append("token", _token);
		subjFormData.append("CompID", _currentCompInfo.SuccCompID);
		subjFormData.append("DiscID", this.orderForm.value['DiscID']);
		subjFormData.append("UserID", _currentUserInfo.SuccUserID);
		subjFormData.append("Title", _subjTitle);
		subjFormData.append("sContent", this.generateSubjContent(_priceInfo, 0));
		subjFormData.append("PlanCloseOn", null);
		subjFormData.append("Receiver", JSON.stringify(this.orderForm.value['ReceiverArray']));
		subjFormData.append("EventTagID", this.orderForm.value['SubjTagID']);
		subjFormData.append("CusTagID", this.orderForm.value['CusTagID']);
		subjFormData.append("ByUserDevice", this.globalService.detectUserDevice());
		subjFormData.append("ByUserBrowser", this.globalService.detectUserAgent());
		subjFormData.append("DetailSubjTitle", "訂單 - " + this.orderForm.value['BillProviderName'] + " - " + _subjTitle + " - " + this.globalService.convertWesternDateStr(new Date(),"/"));
		subjFormData.append("SubjRead", JSON.stringify(this.orderForm.value['ReceiverNameArray']));
		subjFormData.append("DiscType", '2');
		subjFormData.append("AdminData", JSON.stringify([]));
		subjFormData.append("SubjType", '3003'); // 2001: 正航採購, 2003: 正航訂單, 3001: Succ採購, 3002: Succ入庫, 3003: Succ訂單, 3004: Succ銷貨

		// 準備存放上傳完成的檔案
		let AttList = [];
		this.orderService.generateOrderPDF(_paramObjForCreatePDF).subscribe(pdfRes => {
			if (pdfRes.IsSuccess) {
				let _attID = pdfRes.AttID;
				let _filePath = pdfRes.FilePath;
				let _filSize = pdfRes.FileSize;
				let _fileSizeView = pdfRes.FileSizeView;
				let _fileExt = pdfRes.FileExt;
				AttList.push({
					'AttID': pdfRes.AttID,
					'FileName': _paramObjForCreatePDF.WesternTodayDate + "-" + _paramObjForCreatePDF.CustomerCompName + "-" + "訂購單憑證.pdf",
					'FilePath': pdfRes.FilePath,
					'FileSize': pdfRes.FileSize,
					'FileSizeView': pdfRes.FileSizeView,
					'FileType': pdfRes.FileExt.toString().toUpperCase(),
					'IsDel': false,
					'IsEmbed': false,
					'IsSuccess': true,
					'uid': "rc-upload-1554787902309-2"
				});
				// 指派Succ建立主題物件的檔案資料
				subjFormData.append("AttList", JSON.stringify(AttList)); 
				// 建立訂單單據
				this.orderService.insertOrderBill(_orderBill).subscribe(orderRes => {
					let _isCreatePurchaseSuccess = orderRes.IsSuccess;
					let _billCode = orderRes.BillCode; // 進server有再判斷一次這個billCode有無重複
					subjFormData.append("BillNo", _billCode);
					// 建立SuccMail主題
					this.orderService.createOrderSuccSubj(subjFormData).subscribe(succRes => {
						console.log(succRes);
						if (succRes.result) {
							// 把SubjID 寫入這一筆採購訂單
							this.orderService.updateOrderRealtedSubjID({
								ERPCompID: _erpCompid,
								BillType: 1,
								BillCode: this.orderForm.value['BillCode'],
								SubjID: succRes.SubjID
							}).subscribe(updateRes => {
								console.log(updateRes);
								this.loadingService.stopLoading();
								window.scrollTo(0, 0);
								this.globalService.changeRemindState('open');
								this.globalService.changeRemindType(0);
								this.globalService.changeRemindMsg('資料寫入成功!');
								this.orderService.refreshDataList();
								this.IsBrowsing = true;
								this.IsCreate = false;
								this.IsDetail = false;
							});
						}
						else {
							this.globalService.changeRemindState('open');
							this.globalService.changeRemindType(1);
							this.globalService.changeRemindMsg('資料寫入錯誤!');
							this.router.navigate(['/']);
						}
					});
				});

			}
		});
	}

	// 客戶值發生變化
	onProviderChange(_target, _type) {
		console.log(_target);
		if (_target == undefined) {
			this.orderForm.patchValue({
				BillProviderID: '',
				BillProviderName: '',
				BillProviderCode: '',
				BillProviderAddress: '',
				BillProviderPhoneNum: '',
				BillProviderFaxNum: ''
			});			
		}
		else {
			this.orderForm.patchValue({
				BillProviderID: _target['CustomerID'],
				BillProviderName: _target['CustomerName'],
				BillProviderCode: _target['CustomerCode'],
				BillProviderAddress: _target['CustomerZipCode'] + _target['CustomerCityName'] + _target['CustomerZipName'] + _target['CustomerAddress'],
				BillProviderPhoneNum: _target['CustomerPhoneNum'],
				BillProviderFaxNum: _target['CustomerFaxNum'],
			});
			// 檢查是否需要建立客戶標籤 並指派
			this.IsNeedCreateSubjTag(1, _target['CustomerName']);
		}
	}

	// 產生一筆新的訂購項目
	private orderBodyForm(_serial, _billCode): FormGroup {
		return new FormGroup({
			ERPCompID: new FormControl(''), // 隱藏參數
			Serial: new FormControl(_serial),
			OrderCode: new FormControl(_billCode),
			ItemCategoryID: new FormControl(null),
			ItemMaterialCode: new FormControl(null),
			ItemName: new FormControl(''),
			ItemSpec: new FormControl(''),
			ItemUnitCode: new FormControl(0),
			ItemUnitName: new FormControl(''),
			ItemCurrency: new FormControl(''),
			ItemQuantity: new FormControl(0),
			ItemPrice: new FormControl(0),
			ItemTotal: new FormControl(0),
			ItemDeliverQuantity: new FormControl(0),
			ItemMemo: new FormControl('')
		});
	}

	// 產生已建立的品項資料
	private orderDetailForm(_order, _index): FormGroup {
		return new FormGroup({
			ERPCompID: new FormControl(_order.ERPCompID),
			Serial: new FormControl(_order.order_bill_data[_index]['Serial']),
			OrderCode: new FormControl(_order.order_bill_data[_index]['OrderCode']),
			ItemCategoryID: new FormControl(_order.order_bill_data[_index]['ItemCategoryID']),
			ItemMaterialCode: new FormControl(_order.order_bill_data[_index]['ItemMaterialCode']),
			ItemName: new FormControl(_order.order_bill_data[_index]['ItemName']),
			ItemSpec: new FormControl(_order.order_bill_data[_index]['ItemSpec']),
			ItemUnitCode: new FormControl(_order.order_bill_data[_index]['ItemUnitCode']),
			ItemUnitName: new FormControl(_order.order_bill_data[_index]['ItemUnitName']),
			ItemCurrency: new FormControl(_order.order_bill_data[_index]['ItemUnitCurrency']),
			ItemQuantity: new FormControl(
				_order.order_bill_data[_index]['ItemQuantity'],
				[
					Validators.min(_order.order_bill_data[_index]['ItemDeliverQuantity'])
				],
			),
			ItemMemo: new FormControl(
				this.activeProducts$.filter(x => x.ItemMaterialCode == _order.order_bill_data[_index]['ItemMaterialCode'])[0]['Memo']
			),
			ItemPrice: new FormControl(_order.order_bill_data[_index]['ItemPrice']),
			ItemTotal: new FormControl(_order.order_bill_data[_index]['ItemTotal']),
			ItemDeliverQuantity: new FormControl(_order.order_bill_data[_index]['ItemDeliverQuantity'])
		});
	}

	// 判斷是否需要建立標籤
	IsNeedCreateSubjTag(_tagType, _tagName) {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _userInfo = JSON.parse(localStorage.getItem('userInfo'));
		let _token = localStorage.getItem('Token');
		if (_currentCompInfo != null && _currentUserInfo != null && _userInfo != null && _token != null) {
			this.orderService.checkSubjTagIsExist(
				_currentCompInfo['SuccCompID'],
				_currentUserInfo['SuccUserID'],
				_userInfo[0]['LastName'] + _userInfo[0]['FirstName'],
				_tagType,
				_tagName,
				_token
			).subscribe(response => {
				if (_tagType == 0) {
					// 事件標籤
					this.orderForm.patchValue({
						SubjTagID: response.result
					});
				}
				else {
					// 對象標籤
					this.orderForm.patchValue({
						CusTagID: response.result
					});
				}
			});
		}
	}

	// 客製化客戶搜尋功能
	customerSearchFn(term: string, customerItem) {
		term = term.toLowerCase();
		return customerItem.CustomerCode.toLowerCase().indexOf(term) > -1 ||
			   customerItem.CustomerName.toLowerCase().indexOf(term) > -1;
	}

	// HTTP Request 失敗狀態
	failHttpRequest(_msgState, _remindType, _remindMsg) {
		// remindType 0: Success, 1: Fail
		this.loadingService.stopLoading();
		window.scrollTo(0, 0);
		this.globalService.changeRemindState(_msgState);
		this.globalService.changeRemindType(_remindType);
		this.globalService.changeRemindMsg(_remindMsg);
	}

	// 討論組選擇發生變化
	onDiscChange(_target) {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _token = localStorage.getItem('Token');
		console.log(_target);
		// 取得討論組收件人
		this.orderService.initNewMsgReceiver(
			_currentCompInfo['SuccCompID'],
			_target.DiscID,
			_currentUserInfo['SuccUserID'],
			_token
		).subscribe(response => {
			console.log("Disc : ", response);
			let _activeReceiver = response.data.filter(x => x.relate_comp_user != null);
			let _fixReceiverArray = [];
			let _receiverNameArray = [];
			_activeReceiver.filter(x => x.DefaultReceiver == 1).forEach(function(_item) {
				_fixReceiverArray.push(_item.UserID);
				_receiverNameArray.push({
					'UserName': _item.relate_comp_user.LastName + _item.relate_comp_user.FirstName
				});
			});
			// 如果當中不包含建立者本人
			if (_fixReceiverArray.filter(x => x == _currentUserInfo['SuccUserID']).length < 1) {
				_fixReceiverArray.push(_currentUserInfo['SuccUserID']);
				_receiverNameArray.push({
					'UserName': _currentUserInfo.UserName
				});
			}
			this.orderForm.patchValue({
				ReceiverArray: _fixReceiverArray,
				ReceiverNameArray: _receiverNameArray
			});
		});
	}

	// 品項選擇發生變化
	onProductItemChange(_target, _index) {
		console.log(_target);
		if (_target == undefined) {
			this.orderForm.get('OrderItems')['controls'][_index].patchValue({
				ERPCompID: '',
				ItemCategoryID: '',
				OrderCode: '',
				ItemName: '',
				ItemSpec: '',
				ItemUnitCode: '',
				ItemUnitName: '',
				ItemCurrency: '',
				ItemMaterialCode: ''
			});			
		}
		else {
			// 使用者選擇的品項
			this.orderForm.get('OrderItems')['controls'][_index].patchValue({
				ERPCompID: this.orderForm.value['ERPCompID'],
				ItemCategoryID: _target['ItemCategoryID'],
				OrderCode: this.orderForm.value['BillCode'],
				ItemMaterialCode: _target['ItemMaterialCode'],
				ItemName: _target['ItemName'],
				ItemSpec: _target['ItemSpec'],
				ItemUnitCode: _target['ItemUnitCode'],
				ItemUnitName: _target['ItemUnitName'],
				ItemCurrency: this.globalService.convertCurrencyShortID(this.orderForm.value['CurrencyCode']),
				ItemPrice: _target['ItemPrice'] == null ? 0:_target['ItemPrice'],
				ItemMemo: _target['Memo'],
			});
		}
		console.log(this.orderForm.value);
	}

	// 客製化品項搜尋功能
	productSearchFn(term: string, productItem) {
		term = term.toLowerCase();
		return productItem.ItemMaterialCode.toLowerCase().indexOf(term) > -1 ||
			   productItem.ItemName.toLowerCase().indexOf(term) > -1 ||
			   productItem.ItemSpec.toLowerCase().indexOf(term) > -1;
	}

	// 產品單價 or 產品數量異動 計算總金額
	UpdateOrderItem(_index) {
		// 參數宣告
		let result = 0; // 最後計算完小數點回傳的品項總金額
		let total = 0; // 未計算小數點前的品項總金額
		let total_length = 0;
		let dot_index = 0;
		let dot_last_index;

		let _price = (this.orderForm.get('OrderItems') as FormArray).at(_index).value['ItemPrice'];
		let _quantity = (this.orderForm.get('OrderItems') as FormArray).at(_index).value['ItemQuantity'];
		total = _quantity * _price;
		total_length = total.toString().length;
		dot_index = total.toString().indexOf(".");
		dot_last_index = total.toFixed(5).lastIndexOf('.')+5; // +5 用意是最小取至第五位進行四捨五入
		if (dot_index == -1) {
			result = parseFloat(total.toString());
		}
		else {
			result = parseFloat(total.toFixed(5).substring(0, dot_last_index));
		}
		// 塞入值
		this.orderForm.get('OrderItems')['controls'][_index].patchValue({
			ItemTotal: result
		});		
	}

	// 編輯表單 產品單價 or 產品數量異動 計算出品項小計
	UpdateEditOrderItem(_index) {
		// 參數宣告
		let result = 0; // 最後計算完小數點回傳的品項總金額
		let total = 0; // 未計算小數點前的品項總金額
		let total_length = 0;
		let dot_index = 0;
		let dot_last_index;

		let _price = (this.detailForm.get('OrderItems') as FormArray).at(_index).value['ItemPrice'];
		let _quantity = (this.detailForm.get('OrderItems') as FormArray).at(_index).value['ItemQuantity'];
		if (_price < 0) {
			this.detailForm.get('OrderItems')['controls'][_index].patchValue({
				ItemPrice: 0
			});
			return;
		}
		if (_quantity < 0) {
			this.detailForm.get('OrderItems')['controls'][_index].patchValue({
				ItemQuantity: 0
			});
			return;
		}
		let _deliveredQuantity = (this.detailForm.get('OrderItems') as FormArray).at(_index).value['ItemDeliverQuantity'];
		total = _quantity * _price;
		total_length = total.toString().length;
		dot_index = total.toString().indexOf(".");
		dot_last_index = total.toFixed(5).lastIndexOf('.')+5; // +5 用意是最小取至第五位進行四捨五入
		if (dot_index == -1) {
			result = parseFloat(total.toString());
		}
		else {
			result = parseFloat(total.toFixed(5).substring(0, dot_last_index));
		}
		// 塞入值
		this.detailForm.get('OrderItems')['controls'][_index].patchValue({
			ItemTotal: result
		});
	}

	// 新增產品品項
	addNewProductItem() {
		(this.orderForm.get('OrderItems') as FormArray).push(this.orderBodyForm(
			this.orderForm.get('OrderItems')['length'] + 1,
			this.orderForm.value['BillCode']
		));
	}

	// 移除產品品項
	removeProductItem(_index) {
		if ((this.orderForm.get('OrderItems') as FormArray).length < 2) {
			return;
		}
		else {
			(this.orderForm.get('OrderItems') as FormArray).removeAt(_index);
			for (var i = 0; i<(this.orderForm.get('OrderItems') as FormArray).length; i++) {
				this.orderForm.get('OrderItems')['controls'][i].patchValue({
					Serial: i+1
				});
			}
		}
	}

	// 取消建立訂單
	cancelCreateOrder() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsDetail = false;
	}

	// 計算品項金額小數點後第一位 是不是都是5
	calculateTotalPointIsFive(_type) {
		// _type = 0: 新增狀態, _type = 1: 編輯狀態
		let result = true;
		if (_type == 0) {
			let _itemLength = (this.orderForm.get('OrderItems') as FormArray).length;
			for (var i = 0; i < _itemLength; i++) {
				let _isFiveIndex = (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemTotal'].toString().indexOf(".5");
				if (_isFiveIndex == -1) {
					result = false;
					return result;
				}
			}
		}
		else {
			let _itemLength = (this.detailForm.get('OrderItems') as FormArray).length;
			for (var i = 0; i < _itemLength; i++) {
				let _isFiveIndex = (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemTotal'].toString().indexOf(".5");
				if (_isFiveIndex == -1) {
					result = false;
					return result;
				}
			}
		}
		return result;
	}

	// 計算品項總數量
	calculateTotalAmount(_type) {
		// _type = 0: 新增狀態, _type = 1: 編輯狀態
		let result = 0;
		if (_type == 0) {
			let _itemLength = (this.orderForm.get('OrderItems') as FormArray).length;
			for (var i = 0; i < _itemLength; i++) {
				result += (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemQuantity'];
			}
		}
		else {
			let _itemLength = (this.detailForm.get('OrderItems') as FormArray).length;
			for (var i = 0; i < _itemLength; i++) {
				result += (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemQuantity'];
			}
		}
		return result;
	}

	// 轉Item Array 的方法，後續完整後要轉到Action裡面 For PDF
	// _type 0: 建立, 1: 編輯
	GenerateOrderItemArray(_itemLength, _type) {
		let result = [];
		if (_type == 0) {
			for (var i = 0; i < _itemLength; i++) {
				result.push({
					'Serial': (this.orderForm.get('OrderItems') as FormArray).at(i).value['Serial'].toString(),
					'ItemID': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemMaterialCode'],
					'ItemName': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemName'],
					'ItemSpec': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemSpec'],
					'ItemAmount': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemQuantity'],
					'ItemUnit': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemUnitName'],
					'ItemPrice': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemPrice'],
					'ItemTotal': (this.orderForm.get('OrderItems') as FormArray).at(i).value['ItemTotal'],
					'PreDeliverDate': ""
				});
			}
		}
		else {
			for (var i = 0; i < _itemLength; i++) {
				result.push({
					'Serial': (this.detailForm.get('OrderItems') as FormArray).at(i).value['Serial'].toString(),
					'ItemID': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemMaterialCode'],
					'ItemName': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemName'],
					'ItemSpec': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemSpec'],
					'ItemAmount': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemQuantity'],
					'ItemUnit': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemUnitName'],
					'ItemPrice': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemPrice'],
					'ItemTotal': (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemTotal'],
					'PreDeliverDate': ""
				});
			}
		}
		return result;
	}

	// 產生SuccMail發布主題要用的Content
	generateSubjContent(_priceInfo, _type) {
		let FormEntity = null;
		if (_type == 0) {
			// 建立採購單
			FormEntity = this.orderForm;
		}
		else {
			// 編輯採購單
			FormEntity = this.detailForm;
		}

		console.log(FormEntity);

		let ContentString = "";

		let customerIDLabel = "客戶編號：";
    	let customerNameLabel = "客戶名稱：";
    	let addressLabel = "客戶地址：";
    	let phoneLabel = "連絡電話：";
    	let faxLabel = "傳真號碼：";
    	let linkManLabel = "聯絡人員：";
    	let taxCodeLabel = "稅別種類：";
    	let currencyIDLabel = "幣　　別：";
    	let currencyRateLabel = "匯　　率：";
    	let itemIncludeTaxLabel = "產品單價：";
    	let planDateLabel = "預交日期：";
    	let accDateLabel = "帳款日期：";

    	let serialLabel = "序　　號：";
    	let itemCodeLabel = "產品編號：";
    	let itemNameLabel = "品名規格：";
    	let itemNumberLabel = "訂購數量：";
    	let itemUnitLabel = "品項單位：";
    	let itemPriceLabel = "單　　價：";
    	let itemMemoLabel = "品項備註：";
    	let eachPriceLabel = "金　　額：";
    	let memoLabel = "單據備註：";

    	let orderPriceLabel = "原幣合計：";
    	let taxPriceLabel = "原幣稅金：";
    	let totalPriceLabel = "原幣總計：";

    	let emptyRow = "<p><br /></p>";
    	let seperateRow = "<p>------------------------------ 分隔線 ------------------------------</p>";
    	// 原幣
    	let _currencyID = this.globalService.convertCurrencyShortID(FormEntity.value['CurrencyCode']);
    	let _currencyName = this.globalService.convertCurrencyName(FormEntity.value['CurrencyCode']);
    	
    	let orderPriceRow = "<p>" + orderPriceLabel + _priceInfo.NoTaxTotal + " " + _currencyID + "</p>";
    	let taxFeeRow = "<p>" + taxPriceLabel + _priceInfo.TaxFee + " " + _currencyID + "</p>";
    	let withTaxTotalRow = "<p>" + totalPriceLabel + _priceInfo.WithTaxTotal + " " + _currencyID + "</p>";

    	// 統一內容區域
    	let custIDRow = "<p>" + customerIDLabel + FormEntity.value['BillProviderCode'] + "</p>";
    	let custNameRow = "<p>" + customerNameLabel + FormEntity.value['BillProviderName'] + "</p>";
    	let addressRow = "<p>" + addressLabel + FormEntity.value['BillProviderAddress'] + "</p>";
    	let phoneRow = "<p>" + phoneLabel + FormEntity.value['BillProviderPhoneNum'] + "</p>";
    	let faxRow = "<p>" + faxLabel + FormEntity.value['BillProviderFaxNum'] + "</p>";
    	// let linkManRow = "<p>" + linkManLabel + this.props.CustomerDetail.get("LinkMan") + "</p>";
    	let taxRow = "<p>" + taxCodeLabel + this.globalService.convertTaxState(FormEntity.value['TaxState'].toString());
    	let currencyIDRow = "<p>" + currencyIDLabel + _currencyID + " " + _currencyName + "</p>";
    	let currencyRateRow = "<p>" + currencyRateLabel + 1 + "</p>";
    	// 產品品項是否含稅
    	let itemIncludeTaxVal = FormEntity.value['PriceOfTax'] == 0 ? "未稅":"含稅";
    	let itemIncludeTaxRow = "<p>" + itemIncludeTaxLabel + itemIncludeTaxVal + "</p>";
    	let planDateStr = "";
    	let accDateStr = "";
    	let memoStr = "";

    	if (_type == 0) {
    		planDateStr = FormEntity.value['PlanDate']['year']+"/"+FormEntity.value['PlanDate']['month']+"/"+FormEntity.value['PlanDate']['day'];
    		accDateStr = FormEntity.value['AccDate']['year']+"/"+FormEntity.value['AccDate']['month'];
    		memoStr = FormEntity.value['Memo'];
    	}
    	else {
    		planDateStr = FormEntity.value['PlanDate'];
    		accDateStr = FormEntity.value['AccDate'];
    	}
    	
    	let planDateLabelRow = "<p>" + planDateLabel + planDateStr + "</p>";
    	let accDateLabelRow = "<p>" + accDateLabel + accDateStr + "</p>";
    	let memoLabelRow = "<p>" + memoLabel + memoStr + "</p>";

    	// 統一訊息部分
    	ContentString += custIDRow + custNameRow + addressRow + phoneRow + faxRow + taxRow + currencyIDRow + currencyRateRow + itemIncludeTaxRow + planDateLabelRow + accDateLabelRow + emptyRow + memoLabelRow + emptyRow;

    	// 個別品項訊息部分
		let _itemLength = (FormEntity.get('OrderItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
    		let SerialRow = "<p>" + serialLabel + (i + 1) + "</p>";
    		let ItemCodeRow = "<p>" + itemCodeLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemMaterialCode'] + "</p>";
    		let ItemNameRow = "<p>" + itemNameLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemName'] + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemSpec'] + "</p>";
    		let ItemQuantityRow = "<p>" + itemNumberLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemQuantity'] + "</p>";
    		let ItemUnitRow = "<p>" + itemUnitLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemUnitName'] + "</p>";
    		let ItemPriceRow = "<p>" + itemPriceLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemPrice'] + " " + _currencyID + "</p>";
    		let ItemMemoRow = "<p>" + itemMemoLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemMemo'] + "</p>";
    		let EachItemTotalRow = "<p>" + eachPriceLabel + (FormEntity.get('OrderItems') as FormArray).at(i).value['ItemTotal'] + " " + _currencyID + "</p>";
    		ContentString += SerialRow + ItemCodeRow + ItemNameRow + ItemQuantityRow + ItemUnitRow + ItemPriceRow + ItemMemoRow + EachItemTotalRow + seperateRow; 
		}

    	// 金額訊息部分
    	ContentString += orderPriceRow + taxFeeRow + withTaxTotalRow;

    	return ContentString;
	}

	// 轉出庫銷貨單
	ConvertToStockSellSheet(_billCode) {
		console.log(_billCode);
		this.router.navigate(['/stockSell/' + _billCode]);
	}

	// 透過 providerID 產生 客戶 名稱
	generateProviderName(_providerID) {
		let _providerName = this.provider$.filter(x => x.CustomerID == _providerID)[0]['CustomerName'];
		return _providerName;
	}

	// 轉換CurrencyCode to Name
	generateCurrencyName(_currenctCode) {
		return this.globalService.convertCurrencyName(_currenctCode);
	}

	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsDisAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 101, 102, 104 可以看到更新按鈕 並且修改內容
	checkIsDisAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

	// 只有權限 101, 104 才能進行作廢
	chekcIsDisAbleToDestroy() {
		switch (this.userPower) {
			case 101:
			case 104:
				return false;
				break;
			case 102:
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

	// 作廢訂單
	returnOrderBill() {
		this.loadingService.startLoading("訂單作廢中...");
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		// _erpCompid, _billCode, _inventoryType, _inventoryTypeName, _operatorid, _loguserDeivce
		this.orderService.returnOrder(
			this.detailForm.value['ERPCompID'],
			this.detailForm.value['BillCode'],
			305,
			'訂單作廢',
			_currentUserInfo.ERPUserID,
			this.globalService.detectUserAgent()
		).subscribe(response => {
			if (response.IsSuccess) {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('成功作廢訂單單據!');
				this.orderService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsDetail = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg(response.message);
			}
			window.scrollTo(0, 0);
			this.loadingService.stopLoading();
		});
	}

	// 訂購日期發生變化
	billDateChange($event) {
		this.loadingService.startLoading('重新產生訂購編號...');
		let _currentDateString = $event.year + "-" + $event.month + "-" + $event.day;
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.orderService.generateOrderCode(_erpCompid, _currentDateString).subscribe(response => {
				if (response.IsSuccess) {
					this.orderForm.patchValue({
						BillDate: { "year": $event.year, "month": $event.month, "day": $event.day},
						BillCode: response.BillCode,
					});
				}
				this.loadingService.stopLoading();
			});
		}
	}

	// 訂單預計交貨日期發生變化
	planDeliverDateChange($event) {
		this.orderForm.patchValue({
			PlanDate: { "year": $event.year, "month": $event.month, "day": $event.day},
		});
	}

	// 啟動編輯狀態
	EditOrderBill() {
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsDetail = true;
		this.IsEdit = true;
	}

	// 修改訂單動作
	SubmitEditOrder(_billCode) {
		console.log(_billCode);
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('更新訂單資料中...');
		// 全域參數
		let _token = localStorage.getItem("Token");
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserName = _currentUserInfo.UserName;
		let _erpUserid = _currentUserInfo.ERPUserID;

		// 取得判斷所有計算出的品項金額 小數點後第一位 是不是都是5 
		// 如果 true: 計算應稅外加時，稅額不進位只取到整數位
		// 如果 false: 計算應稅外加時，稅額從小數點後面逐個進位
		let _isFiveAtFirstPoint = this.calculateTotalPointIsFive(1);
		console.log(_isFiveAtFirstPoint);
		// 總數量
		let _totalQuantity = this.calculateTotalAmount(1);
		console.log(_totalQuantity);
		// 品項數量
		let _itemLength = (this.detailForm.get('OrderItems') as FormArray).length;
		// 錢相關
		let _total = 0;
		for (var i = 0; i < _itemLength; i++) {
			_total += (this.detailForm.get('OrderItems') as FormArray).at(i).value['ItemTotal'];
		}
		let _priceInfo = this.globalService.calculateTotalAndTaxFee(
			_isFiveAtFirstPoint,
			_itemLength,
			_total,
			this.detailForm.value['PriceOfTax'],
			this.detailForm.value['TaxState']
		);
		console.log(_priceInfo);
		let _taxFee = _priceInfo.TaxFee;
		let _noTaxTotal = _priceInfo.NoTaxTotal;
		let _withTaxTotal = _priceInfo.WithTaxTotal;
		// 表頭參數
		let _billDate = this.detailForm.value['BillDate'];

		// 準備要使用的列印PDF的參數
		let _paramObjForCreatePDF = {
			ERPCompName: _currentCompInfo.CompName,
			ERPCompAreaCode: '',
			ERPCompAddress: _currentCompInfo.CompAddress,
			ERPCompPhone: _currentCompInfo.CompPhoneNum,
			ERPCompFax: _currentCompInfo.CompFaxNum,
			WesternTodayDate: this.globalService.convertWesternDateStr(new Date(),"/"),
			WesternOrderDate: _billDate,
			PlanDate: this.detailForm.value['PlanDate'],
			CustomerID: this.detailForm.value['BillProviderCode'],
			CustomerCompName: this.detailForm.value['BillProviderName'],
			CustomerAddress: this.detailForm.value['BillProviderAddress'],
			CustomerPhoneNum: this.detailForm.value['BillProviderPhoneNum'],
			CustomerFaxNum: this.detailForm.value['BillProviderFaxNum'],
			LinkMan: '',			
			CurrencyID: this.detailForm.value['CurrencyCode'],
			CurrencyName: this.globalService.convertCurrencyShortID(this.detailForm.value['CurrencyCode']),
			CurrencyRate: 1,			
			SalesManID: '',
			SalesManName: _erpUserName,
			CreatorName: _erpUserName,
			Memo: '',
			DepartmentID: '',
			DepartmentName: '',
			BillNo: this.detailForm.value['BillCode'],
			ItemTaxState: this.detailForm.value['PriceOfTax'] == 0 ? "未稅":"含稅",
			TotalItemCount: _itemLength,
			TotalItemAmount: _totalQuantity,
			NoTaxTotal: _priceInfo.NoTaxTotal,
			TaxFee: _priceInfo.TaxFee,
			WithTaxTotal: _priceInfo.WithTaxTotal,
			OrderSheetItem: this.GenerateOrderItemArray(_itemLength, 1),
			FooterTerms: '',
			CreatorCompID: _currentCompInfo.SuccCompID
		};

		// 修改訂單物件
		let _orderBill = {
			ERPCompID: _erpCompid,
			BillDate: _billDate,
			BillCode: this.detailForm.value['BillCode'],
			BillType: this.detailForm.value['BillType'],
			OperatorID: _erpUserid,
			Total: _priceInfo.NoTaxTotal,
			TaxFee: _priceInfo.TaxFee,
			BillBody: JSON.stringify(this.detailForm.value['OrderItems']),
			LogUserDevice: this.globalService.detectUserAgent()
		};
		// 送到Succmail的物件
		let _subjTitle = this.globalService.generateSubjTitle(_itemLength, (this.detailForm.get('OrderItems') as FormArray));
		var subjFormData = new FormData();
		subjFormData.append("token", _token);
		subjFormData.append("CompID", _currentCompInfo.SuccCompID);
		subjFormData.append("UserID", _currentUserInfo.SuccUserID);
		subjFormData.append("Title", _subjTitle);
		subjFormData.append("sContent", this.generateSubjContent(_priceInfo, 1));
		subjFormData.append("PlanCloseOn", null);
		subjFormData.append("ByUserDevice", this.globalService.detectUserDevice());
		subjFormData.append("ByUserBrowser", this.globalService.detectUserAgent());
		subjFormData.append("DetailSubjTitle", "訂單 - " + this.detailForm.value['BillProviderName'] + " - " + _subjTitle + " - " + this.globalService.convertWesternDateStr(new Date(),"/"));
		subjFormData.append("DiscType", '2');
		subjFormData.append("AdminData", JSON.stringify([]));
		subjFormData.append("SubjType", '3003'); // 2001: 正航採購, 2003: 正航訂單, 3001: Succ採購, 3002: Succ入庫, 3003: Succ訂單, 3004: Succ銷貨
		subjFormData.append("SubjID", this.detailForm.value['SubjID']);


		// 準備存放上傳完成的檔案
		let AttList = [];
		this.orderService.generateOrderPDF(_paramObjForCreatePDF).subscribe(pdfRes => {
			if (pdfRes.IsSuccess) {
				let _attID = pdfRes.AttID;
				let _filePath = pdfRes.FilePath;
				let _filSize = pdfRes.FileSize;
				let _fileSizeView = pdfRes.FileSizeView;
				let _fileExt = pdfRes.FileExt;
				AttList.push({
					'AttID': pdfRes.AttID,
					'FileName': _paramObjForCreatePDF.WesternTodayDate + "-" + _paramObjForCreatePDF.CustomerCompName + "-" + "訂購單憑證.pdf",
					'FilePath': pdfRes.FilePath,
					'FileSize': pdfRes.FileSize,
					'FileSizeView': pdfRes.FileSizeView,
					'FileType': pdfRes.FileExt.toString().toUpperCase(),
					'IsDel': false,
					'IsEmbed': false,
					'IsSuccess': true,
					'uid': "rc-upload-1554787902309-2"
				});
				// 指派Succ建立主題物件的檔案資料
				subjFormData.append("AttList", JSON.stringify(AttList)); 
				// 更新訂單單據
				this.orderService.updateOrderBill(_orderBill).subscribe(orderRes => {
					let _billCode = orderRes.BillCode;
					subjFormData.append("BillNo", _billCode);
					this.orderService.updateOrderSubj(subjFormData).subscribe(succRes => {
						if (succRes.result) {
							// 更新成功
							this.globalService.changeRemindState('open');
							this.globalService.changeRemindType(0);
							this.globalService.changeRemindMsg('成功修改訂單單據!');
							this.orderService.refreshDataList();
							this.IsBrowsing = true;
							this.IsCreate = false;
							this.IsDetail = false;
							this.IsEdit = false;
						}
						else {
							// 更新失敗
							this.globalService.changeRemindState('open');
							this.globalService.changeRemindType(1);
							this.globalService.changeRemindMsg(succRes.message);
						}
						window.scrollTo(0, 0);
						this.loadingService.stopLoading();
					})
				});
			}
			else {
				this.loadingService.stopLoading();
			}
		});
	}

}
