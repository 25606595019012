import { Injectable, PipeTransform } from '@angular/core';
// interface
import { ValidateRule } from '../interface/validaterule';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';

// 從後端接過來的資料 進行分頁動作的結果
interface SplitPageResult {
	validateRuleList: ValidateRule[],
	total: number;
}

// 承接前端畫面狀態
interface State {
	page: number;
	pageSize: number;
}


@Injectable({
  providedIn: 'root'
})
export class ValidateruleService {

	// Define API URL參數
	apiURL = config.APIURL;

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _heartbeat$ = new Subject<void>(); // 做完動作呼叫他 Angular才會更新畫面的DOM
	private _validateRules$ = new BehaviorSubject<ValidateRule[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10
	};

	// 建構式 實體化服務
	constructor(private http: HttpClient) {
		// 不用參數直接拿取
		this.getValidateRuleList().subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				this._heartbeat$.pipe(
					tap(() => this._loading$.next(true)),
					debounceTime(200),
					switchMap(() => this._splitPages(response.ValidateRuleRes)),
					delay(200),
					tap(() => this._loading$.next(false))
				).subscribe(result => {
					console.log("result : ", result);
					this._validateRules$.next(result.validateRuleList);
					this._total$.next(result.total);
				});
				this._heartbeat$.next();
			}
		});
	}

	// 從 Server更新資料列表
	refreshDataList() {
		
	}

	// 從外部取參數
	get validateRules$() { return this._validateRules$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._heartbeat$.next();
	}

	private _splitPages(_validateRuleList): Observable<SplitPageResult> {
		const { page, pageSize } = this._state;
		let validateRuleList = _validateRuleList.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		const total = _validateRuleList.length;

		console.log("validateRuleList : ", validateRuleList);

		return of({validateRuleList, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得檢驗規則列表
	getValidateRuleList(): Observable<any>{
		return this.http.get<any>(
			this.apiURL + '/api/erp/get_validate_rule',
			this.httpOptions
		).pipe();
	}

	// 建立檢驗規則
	createValidateRule(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_validate_rule', 
			JSON.stringify(param),
			this.httpOptions			
		).pipe();
	}

}
