import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CheckERPCompCount } from '../interface/API/check-erpcomp-count';
import { Observable, throwError, BehaviorSubject, of, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
// import config file
import config from '../config/constant.json';

interface ERPComp {
	ERPCompID: string,
	SuccCompID: string,
	CompName: string,
	LogoPath: string,
	UserName: string
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

	// Define API URL參數
	apiURL = config.APIURL;
	// Parameters 改為存localStorage
	private CompList: any = [];

	// 存放目前登入的公司
	private _currentLoginComp$ = new BehaviorSubject<ERPComp>({
		ERPCompID: "",
		SuccCompID: "",
		CompName: "",
		LogoPath: "",
		UserName: ""		
	});

	constructor(private http: HttpClient) { }

	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}
	// 透過 SuccCompID 和 SuccUserID 拿取Succmail中的基本資料
	getLoginInfo(_compid, _userid): Observable<any>{
		var param = {
			CompID: _compid,
			UserID: _userid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_erp_user_info', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 登入到Succmail 取得Token
	loginToGetToken(_email, _pwd): Observable<any>{
		var param = {
			email: _email,
			password: _pwd 
		};

		return this.http.post<any>(
			this.apiURL + '/api/login', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 確認是否已經有建立過ERP公司，並判斷該登入者有無帳號存在
	checkERPEntityIsExist(_succUserid): Observable<any>{
		var param = {
			UserID: _succUserid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/check_erp_comp_count', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得登入公司的獨立成員資料
	getSingleERPMemberInfo(_erpCompid, _succUserid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			SuccUserID: _succUserid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_erp_single_member', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// Error handling
	handleError(error: any) {
		console.log(error);
	}

	// 設定可進入的公司列表
	setCompList(data: any){
		this.CompList = data;
	}

	// 吐出公司列表
	getCompList(data: any){
		return this.CompList;
	}

	setCurrentLogincomp(data: any) {
		this._currentLoginComp$.next(data);
	}

	// 取得目前登入公司
	get currentLoginComp$() { return this._currentLoginComp$.asObservable(); }


}
