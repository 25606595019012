import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// 呼叫 Services
import { CompanyService } from '../../service/company.service';
import { LoadingService } from '../../service/loading.service';
import { LoginService } from '../../service/login.service';
// import config file
import config from '../../config/constant.json';

@Component({
  selector: 'app-compbinding',
  templateUrl: './compbinding.component.html',
  styleUrls: ['./compbinding.component.css']
})
export class CompbindingComponent implements OnInit {

	bindingCompList: any = [];

	constructor(
		private compService: CompanyService,
		public loginService: LoginService,
		private loadingService: LoadingService,
		private router: Router,
	) { }

	ngOnInit() {
		let _userInfo = JSON.parse(localStorage.getItem('userInfo'));
		let _token = localStorage.getItem('Token');
		if(_userInfo != null) {
			this.loadingService.startLoading("資料傳輸中...");
			let _userID = _userInfo[0]['UserID'];
			let _param = {
				token: _token,
				UserID: _userID
			};
			this.compService.getUserAccessComps(_param).subscribe(response => {
				if (response.result) {
					console.log(response.data);
					this.bindingCompList = response.data;
				}
				// 關閉Loading畫面
				this.loadingService.stopLoading();
			});
		}
	}

	// 產生公司圖片
	onGenerateCompImg(_compID: string, _logoPath: string){
	    // 公司Logo路徑
	    if (_logoPath != null) {
	      _logoPath = _logoPath.toLowerCase();
	      let _attid = _compID.toLowerCase();
	      let _fileExt = _logoPath.toLowerCase().slice(_logoPath.lastIndexOf(".") + 1);
	      let RequestPath = config.Download_URL + "?CompID=" + _compID + "&AttID=" + _attid + "&Ext=" + _fileExt + "&FileName=" + _attid;
	      return RequestPath;
	    }
	    else {
	      return './assets/images/Default_Company3.jpg';
	    }
	}

	// 進行公司綁定
	BindingComp(_compid: string, _shortName: string, _logoPath: string) {
		this.loadingService.startLoading("資料綁定中...");
		// 抽取UserID
		let _userid = JSON.parse(localStorage.getItem("loginInfo"))[0]['UserID'];
		// 判斷是否已經綁定過了
		this.compService.CheckERPCompIsBinding({CompID: _compid}).subscribe((data: any) => {
			if (data.IsSuccess) {
				if (data.BindingComp) {
					alert('該公司已經完成綁定！');
					// 關閉Loading畫面
					this.loadingService.stopLoading();
					return;
				}
				else {
					// 移除 localS 裡面的 compInfo
					// 做動作前 先做一次 localS情除動作
					localStorage.removeItem('userInfo');
					localStorage.removeItem('compInfo');
					// 擷取綁定資料
					this.loginService.getLoginInfo(_compid, _userid).subscribe((data: any) => {
						// 判斷有無成功
						if (data.IsSuccess) {
							// 把相關資料寫入 localStorage
							localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
							localStorage.setItem('compInfo', JSON.stringify(data.CompInfo));
							// 關閉Loading畫面
							this.loadingService.stopLoading();
							if (data.UserInfo[0]['isBoss'] == 0) {
								alert('啟動ERP功能需由公司最高管理者啟用!');
								return;
							}
							else {
								this.router.navigate(['/companyInit/1']);
							}
						}
					});
				}
			}
			else {
				alert('Error！');
				return;
			}
		});
	}

}
