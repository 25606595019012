import { Injectable, PipeTransform } from '@angular/core';
import { Member } from '../interface/member';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

import { MemberService } from './member.service';

// 搜尋結果承接的 Interface
interface SearchResult {
	members: Member[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(members: Member[], column: string, direction: string): Member[] {
	// 沒有進行排序
	if (direction === '') {
		return members
	}
	else {
		return [...members].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(member: Member, term: string, pipe: PipeTransform) {
	return member.UserName.toLowerCase().includes(term.toLowerCase()) 
		 || member.UserEmail.toLowerCase().includes(term.toLowerCase());
}

@Injectable({providedIn: 'root'})
export class ConfigService {

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _members$ = new BehaviorSubject<Member[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(private pipe: DecimalPipe, private memberService: MemberService) {

		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.memberService.getMemberList(_erpCompid).subscribe(response => {
				if (response.result) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.MemberList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._members$.next(result.members);
						this._total$.next(result.total);
					});

					this._search$.next();
				}
			});
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.memberService.getMemberList(_erpCompid).subscribe(response => {
				if (response.result) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.MemberList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._members$.next(result.members);
						this._total$.next(result.total);
					});

					this._search$.next();
				}
			});
		}		
	}

	// 從外部取參數
	get members$() { return this._members$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}


		// let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		// if (_currentCompInfo != null) {
		// 	let _erpCompid = _currentCompInfo.ERPCompID;
		// 	this.memberService.getMemberList(_erpCompid).subscribe(response => {
		// 		console.log(response);
		// 		if (response.result) {
		// 			this._memberList = response.MemberList;	
		// 		}
		// 	});
		// }
		// else {
		// 	this.router.navigate(['/login']);
		// }

	private _search(memberList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let members = sort(memberList, sortColumn, sortDirection);

		// 2. filter
		members = members.filter(member => matches(member, searchTerm, this.pipe));
		const total = members.length;

		// 3. paginate
		members = members.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

		return of({members, total});
	}

}
