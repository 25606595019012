import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { SupplierService } from '../../service/supplier.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import {Supplier} from '../../interface/supplier';
// 建立表單控制套件
import { FormGroup, FormControl } from '@angular/forms';
// 城市選擇
import zipCode from '../../config/zipCode.json';

@Component({
	selector: 'app-supplier',
	templateUrl: './supplier.component.html',
	styleUrls: ['./supplier.component.css'],
	providers: [SupplierService, DecimalPipe]
})
export class SupplierComponent implements OnInit {

	suppliers$: Observable<Supplier[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	city$: any = [];
	area$: any = [];
	// 預設使用者權限為最低
	userPower = 103;

	// 新增使用的 form
	supplierForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		SupplierCode: new FormControl(''),
		SupplierName: new FormControl(''),
		SupplierTaxCode: new FormControl(''),
		SupplierPhoneNum: new FormControl(''),
		SupplierFaxNum: new FormControl(''),
		SupplierEmail: new FormControl(''),
		SupplierCityCode: new FormControl(''),
		SupplierCityName: new FormControl(''),
		SupplierZipCode: new FormControl(''),
		SupplierZipName: new FormControl(''),
		SupplierAddress: new FormControl(''),
		SupplierBankCode: new FormControl(''),
		SupplierBankAccount: new FormControl(''),
		IsActive: new FormControl('1'),
	});
	// 編輯使用的 form
	modifySupplierForm = new FormGroup({
		SupplierID: new FormControl(''), // 供應商的PK
		ERPCompID: new FormControl(''), // 隱藏參數
		SupplierCode: new FormControl(''),
		SupplierName: new FormControl(''),
		SupplierTaxCode: new FormControl(''),
		SupplierPhoneNum: new FormControl(''),
		SupplierFaxNum: new FormControl(''),
		SupplierEmail: new FormControl(''),
		SupplierCityCode: new FormControl(''),
		SupplierCityName: new FormControl(''),
		SupplierZipCode: new FormControl(''),
		SupplierZipName: new FormControl(''),
		SupplierAddress: new FormControl(''),
		SupplierBankCode: new FormControl(''),
		SupplierBankAccount: new FormControl(''),
		IsActive: new FormControl('1'),
	});

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public supplierService: SupplierService
	) {
		this.suppliers$ = supplierService.suppliers$;
		this.total$ = supplierService.total$;

		this.city$ = zipCode.City.sort((a, b) => (a.value > b.value ? 1: -1));
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
	}

	onSort({column, direction}: SortEvent) {
		// resetting other headers
		console.log("headers : ", this.headers);
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.supplierService.sortColumn = column;
		this.supplierService.sortDirection = direction;
	}

	// 前往建立新供應商頁面
	goToNewSupplierPage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		// 更改顯示頁面參數
		this.IsBrowsing = false;
		this.IsCreate = true;
		this.IsEdite = false;
		// 呼叫Server產生新的供應商編號
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.supplierService.getSupplierNewCode(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					// 綁定確定後 幫資料送入Form表格
					this.supplierForm.patchValue({
						ERPCompID: _erpCompid,
						SupplierCode: response.SupplierCode,
						SupplierName: '',
						SupplierTaxCode: '',
						SupplierPhoneNum: '',
						SupplierFaxNum: '',
						SupplierEmail: '',
						SupplierCityCode: '',
						SupplierCityName: '',
						SupplierZipCode: '',
						SupplierZipName: '',
						SupplierAddress: '',
						SupplierBankCode: '',
						SupplierBankAccount: '',
						IsActive: '1'
					});
					console.log(this.supplierForm);
					this.loadingService.stopLoading();
				}
				else {
					this.loadingService.stopLoading();
					this.router.navigate(['/supplier']);
				}
			});
		}
		else {
			this.loadingService.stopLoading();
			this.router.navigate(['/login']);
		}
	}

	// 新增供應商資料到Server
	onSubmitSupplier() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('建立供應商中...');	
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;
		let param = {
			ERPCompID: _erpCompid,
			SupplierCode: this.supplierForm.value['SupplierCode'],
			OperatorID: _erpUserid,
			SupplierName: this.supplierForm.value['SupplierName'],
			SupplierTaxCode: this.supplierForm.value['SupplierTaxCode'],
			SupplierPhoneNum: this.supplierForm.value['SupplierPhoneNum'],
			SupplierEmail: this.supplierForm.value['SupplierEmail'],
			SupplierFaxNum: this.supplierForm.value['SupplierFaxNum'],
			SupplierCityCode: this.supplierForm.value['SupplierCityCode'],
			SupplierCityName: this.supplierForm.value['SupplierCityName'],
			SupplierZipCode: this.supplierForm.value['SupplierZipCode'],
			SupplierZipName: this.supplierForm.value['SupplierZipName'],
			SupplierAddress: this.supplierForm.value['SupplierAddress'],
			SupplierBankAccount: this.supplierForm.value['SupplierBankAccount'],
			LogUserDevice: _logUserDevice,
			IsActive: this.supplierForm.value['IsActive']
		};
		this.supplierService.createSupplier(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.supplierService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 縣市值 發生變化
	onCityChange(_target, _type) {
		let cityName = this.city$.filter(item => {
			return item.value == parseInt(_target);
		})[0]['name'];
		if (_type == 0) {
			// 建立供應商情況
			this.supplierForm.patchValue({
				SupplierCityName: cityName
			});
		}
		else {
			// 修改供應商情況
			this.modifySupplierForm.patchValue({
				SupplierCityName: cityName
			});
		}

		let area = Object.keys(zipCode.Areas)
		.filter(keys => {
			return keys == _target
		})
		.reduce((obj, key) => {
			obj[key] = zipCode.Areas[key];
			return obj;
		}, {});
		this.area$ = area[Object.keys(area)[0]];
	}

	// 區域值 發生變化
	onAreaChange(_target, _type) {
		let zipName = this.area$.filter(item => {
			return item.value == parseInt(_target);
		})[0]['name'];
		if (_type == 0) {
			this.supplierForm.patchValue({
				SupplierZipName: zipName
			});
		}
		else {
			this.modifySupplierForm.patchValue({
				SupplierZipName: zipName
			});
		}
	}

	// 進行供應商資料修改
	updateSupplier(_supplier) {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = true;
		// 灌值給Form表
		this.modifySupplierForm.patchValue({
			SupplierID: _supplier.SupplierID,
			ERPCompID: _supplier.ERPCompID,
			SupplierCode: _supplier.SupplierCode,
			SupplierName: _supplier.SupplierName,
			SupplierTaxCode: _supplier.SupplierTaxCode,
			SupplierPhoneNum: _supplier.SupplierPhoneNum,
			SupplierFaxNum: _supplier.SupplierFaxNum,
			SupplierEmail: _supplier.SupplierEmail,
			SupplierCityCode: _supplier.SupplierCityCode,
			SupplierCityName: _supplier.SupplierCityName,
			SupplierZipCode: _supplier.SupplierZipCode,
			SupplierZipName: _supplier.SupplierZipName,
			SupplierAddress: _supplier.SupplierAddress,
			SupplierBankCode: '',
			SupplierBankAccount: _supplier.SupplierBankAccount,
			IsActive: _supplier.IsActive.toString()
		});
		this.onCityChange(_supplier.SupplierCityCode, 1);

		this.loadingService.stopLoading();
	}

	// 送編輯供應商動作到Server
	onUpdateSupplier() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('資料修正中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpUserid = _currentUserInfo.ERPUserID;

		let param = {
			SupplierID: this.modifySupplierForm.value['SupplierID'],
			OperatorID: _erpUserid,
			SupplierName: this.modifySupplierForm.value['SupplierName'],
			SupplierTaxCode: this.modifySupplierForm.value['SupplierTaxCode'],
			SupplierPhoneNum: this.modifySupplierForm.value['SupplierPhoneNum'],
			SupplierEmail: this.modifySupplierForm.value['SupplierEmail'],
			SupplierFaxNum: this.modifySupplierForm.value['SupplierFaxNum'],
			SupplierCityCode: this.modifySupplierForm.value['SupplierCityCode'],
			SupplierCityName: this.modifySupplierForm.value['SupplierCityName'],
			SupplierZipCode: this.modifySupplierForm.value['SupplierZipCode'],
			SupplierZipName: this.modifySupplierForm.value['SupplierZipName'],
			SupplierAddress: this.modifySupplierForm.value['SupplierAddress'],
			SupplierBankAccount: this.modifySupplierForm.value['SupplierBankAccount'],
			IsActive: this.modifySupplierForm.value['IsActive'],
			ERPCompID: this.modifySupplierForm.value['ERPCompID'],
			LogUserDevice: _logUserDevice,
		};
		this.supplierService.updateSupplier(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料修改成功!');
				this.supplierService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料修改錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 取消進行供應商編輯動作
	cancelUpdateCustomer() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 取消建立新供應商
	cancelCreateSupplier() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);
	}

	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

}
