import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import config file
import config from '../../config/constant.json';
// Service
import { LoginService } from '../../service/login.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	// params
	isLogin: boolean = false;
	ERPComp: any = {
		ERPCompID: "",
		SuccCompID: "",
		CompName: "",
		LogoPath: "",
		UserName: ""
	};
	// 當URL有變化的時候，就要去看一下 localS 的資料
	constructor(
		private router: Router,
		public loginService: LoginService
	) {
		router.events.subscribe((val) => {
			let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
			let _isERPLogin = JSON.parse(localStorage.getItem('IsERPLogin'));
			let _userInfo = JSON.parse(localStorage.getItem('userInfo'));
			this.isLogin = _isERPLogin != null ? _isERPLogin:false;
			if (_userInfo != null && _currentERPComp != null) 
			{
				this.loginService.setCurrentLogincomp({
					ERPComp: _currentERPComp.ERPCompID,
					SuccCompID: _currentERPComp.SuccCompID,
					LogoPath: _currentERPComp.LogoPath,
					CompName: _currentERPComp.CompName,
					UserName: _userInfo[0].LastName.toString() + _userInfo[0].FirstName.toString()
				});
			}
		});
		// this.ERPComp = this.loginService.getLoginComp();
		// console.log(this.ERPComp);
	}
	ngOnInit() { }
	// 產生圖片
	onGenerateCompImg(_compid: string, _logoPath: string){
	    // 公司Logo路徑
	    if (_logoPath != null) {
	      _logoPath = _logoPath.toLowerCase();
	      let _attid = _compid.toLowerCase();
	      let _fileExt = _logoPath.toLowerCase().slice(_logoPath.lastIndexOf(".") + 1);
	      let RequestPath = config.Download_URL + "?CompID=" + _compid + "&AttID=" + _attid + "&Ext=" + _fileExt + "&FileName=" + _attid;
	      return RequestPath;
	    }
	    else {
	      return './assets/images/Default_Company3.jpg';
	    }
	}
	// 登出
	logOut() {
		// 清除 localS 資料
		localStorage.removeItem('userInfo');
		localStorage.removeItem('Token');
		localStorage.removeItem('currentERPComp');
		localStorage.removeItem('currentERPUser');
		localStorage.removeItem('IsERPLogin');
		localStorage.removeItem('loginInfo');
		localStorage.removeItem('compInfo');
		this.router.navigate(['/login']);
	}
	// 登入
	logIn() {
		this.router.navigate(['/login']);
	}
	// 回到SuccMail
	DirectToSucc() {
		window.location.href = "https://succmail.com";
	}
	// 切換公司
	switchCompany() {
		console.log("switch");
		this.router.navigate(['/companyList']);
	}

}
