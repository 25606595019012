import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
// For ngModel
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// For HttpClient
import { HttpClientModule } from '@angular/common/http';
// 下拉式選單
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { MenuComponent } from './component/menu/menu.component';
import { CompanyComponent } from './component/company/company.component';
import { SupplierComponent } from './component/supplier/supplier.component';
import { CustomerComponent } from './component/customer/customer.component';
import { ProductComponent } from './component/product/product.component';
import { WarehouseComponent } from './component/warehouse/warehouse.component';
import { OrderComponent } from './component/order/order.component';
import { PurchaseComponent } from './component/purchase/purchase.component';
import { ConfigComponent } from './component/config/config.component';
import { LoginComponent } from './component/login/login.component';
import { ComplistComponent } from './component/complist/complist.component';
import { CompbindingComponent } from './component/compbinding/compbinding.component';
import { CompmodifyComponent } from './component/compmodify/compmodify.component';
import { StockInComponent } from './component/stock-in/stock-in.component';
import { StockSellComponent } from './component/stock-sell/stock-sell.component';
import { ProductUnitComponent } from './component/product-unit/product-unit.component';
import { ValidateruleComponent } from './component/validaterule/validaterule.component';

import { LoadingComponent } from './component/loading/loading.component';
import { MsgremindComponent } from './component/msgremind/msgremind.component';
// month picker
import { MonthpickerComponent } from './component/monthpicker/monthpicker.component';

// 允許使用 animation
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// 表單排序使用
import {NgbdSortableHeader} from './directive/sortable.directive';

// 備註使用的 Html Editor
import { AngularEditorModule } from '@kolkov/angular-editor';

// 料帳管理表使用的 顯示表格
import { HotTableModule } from '@handsontable/angular';

// Create component need to assign module in the command
// ng g c XXX --module app

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MenuComponent,
    CompanyComponent,
    SupplierComponent, 
    CustomerComponent, 
    ProductComponent,
    WarehouseComponent,
    OrderComponent,
    PurchaseComponent,
    ConfigComponent,
    LoginComponent,
    LoadingComponent,
    ComplistComponent,
    CompbindingComponent,
    CompmodifyComponent,
    MsgremindComponent,
    StockInComponent,
    StockSellComponent,
    ProductUnitComponent,
    MonthpickerComponent,
    ValidateruleComponent,
    NgbdSortableHeader
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    AngularEditorModule,
    HotTableModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
