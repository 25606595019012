import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { MemberService } from '../../service/member.service';
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {ConfigService} from '../../service/config.service';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';
// Interface
import {Member} from '../../interface/member';
// 建立表單控制套件
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css'],
  providers: [ConfigService, DecimalPipe]
})
export class ConfigComponent implements OnInit {

	members$: Observable<Member[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	IsBinding: boolean = false;
	activeMember: any = [];
	// 用來判斷是否已經成為成員 checkbox使用
	erpMember: any = [];
	// 綁定用戶的資料
	bindingUser: any = {};
	// 預設使用者權限為最低
	userPower = 103;

	// 建立用戶 及 編輯用戶的初始化表單
	profileForm = new FormGroup({
		SuccUserID: new FormControl(''), // 隱藏參數 建立使用
		SuccCompID: new FormControl(''), // 隱藏參數 建立使用
		ERPUserID: new FormControl(''), // 隱藏參數 編輯使用
		ERPCompID: new FormControl(''), // 隱藏參數 編輯使用
		FirstName: new FormControl(''),
		LastName: new FormControl(''),
		UserName: new FormControl(''), // 編輯狀態下用這一個代表名稱
		Email: new FormControl(''),
		managerRadios: new FormControl('103'), // 初始化給允許讀取的預設權限
		isActive: new FormControl('1') // 初始化啟用狀態
	});

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private memberService: MemberService,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public configService: ConfigService
	) { 
		this.members$ = configService.members$;
		this.total$ = configService.total$;
		// 取得checkBox使用的檢查成員清單
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.memberService.getMemberList(_erpCompid).subscribe(response => {
				if (response.result) {
					this.erpMember = response.MemberList;
				}
			});
		}
		else {
			this.router.navigate(['/login']);
		}
	}

	onSort({column, direction}: SortEvent) {
		// resetting other headers
		console.log("headers : ", this.headers);
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.configService.sortColumn = column;
		this.configService.sortDirection = direction;
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
	}

	// 前往建立新用戶的畫面
	goToNewUserPage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.IsBrowsing = false;
		this.IsBinding = true;
		this.IsCreate = false;
		this.IsEdite = false;
		let _token = localStorage.getItem('Token');
		let _succCompID = JSON.parse(localStorage.getItem('currentERPComp'))['SuccCompID'];

		this.loadingService.startLoading('讀取綁定用戶資料');
		this.memberService.getSuccMemberList(_token, _succCompID).subscribe(response => {
			if (response.result) {
				this.activeMember = response.activeuserlist;
				console.log(this.activeMember);
			}
			this.loadingService.stopLoading();
		});

	}

	// 判斷用戶是否已經存在 ERPMemberList
	checkIsExistInERP(_loginEmail: string) {
		let _isDisable = false;		
		this.erpMember.filter(item => {
			if (item.UserEmail == _loginEmail) {
				_isDisable = true;
			}
		});
		return _isDisable;
	}

	// 取消建立綁定成員
	cancelCreateMember() {
		this.IsBrowsing = true;
		this.IsBinding = false;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 點擊綁定使用者
	bindUser(_user) {
		console.log(_user);
		this.IsBrowsing = false;
		this.IsBinding = false;
		this.IsCreate = true;
		this.IsEdite = false;
		// 綁定確定後 幫資料送入Form表格
		this.profileForm.patchValue({
			SuccUserID: _user.UserID,
			SuccCompID: _user.CompID,
			FirstName: _user.FirstName,
			LastName: _user.LastName,
			Email: _user.user_info.loginEmail,
			managerRadios: '103',
			isActive: '1'
		});

	}

	// 建立用戶進入ERP名單
	onSubmitUser() {
		this.loadingService.startLoading('建立成員資料');

		// global Service 產生所需的新參數
		let _newERPUserid = this.globalService.generateGUID();
		let _logUserDevice = this.globalService.detectUserAgent();
		// 從 localStorage 抽取參數
		let _erpCompID = JSON.parse(localStorage.getItem('currentERPComp'))['ERPCompID'];

		this.memberService.createERPMember(
			_newERPUserid,
			this.profileForm.value['SuccUserID'],
			_erpCompID,
			this.profileForm.value['SuccCompID'],
			this.profileForm.value['LastName'] + this.profileForm.value['FirstName'],
			this.profileForm.value['Email'],
			parseInt(this.profileForm.value['managerRadios']),
			_logUserDevice
		).subscribe(response => {
			if (response.IsSuccess) {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.loadingService.stopLoading();
				this.configService.refreshDataList();
				this.IsBrowsing = true;
				this.IsBinding = false;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.loadingService.stopLoading();
				this.router.navigate(['/']);
			}
		});
	}

	// 進入更新使用者的畫面
	updateMember(_user) {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.IsBrowsing = false;
		this.IsBinding = false;
		this.IsCreate = false;
		this.IsEdite = true;
		// 綁定確定後 幫資料送入Form表格
		this.profileForm.patchValue({
			ERPUserID: _user.ERPUserID,
			ERPCompID: _user.ERPCompID,
			UserName: _user.UserName,
			Email: _user.UserEmail,
			managerRadios: _user.Power.toString(),
			isActive: _user.IsActive.toString()
		});
	}

	// 更新成員資料
	onUpdateUser() {
		this.loadingService.startLoading('更新成員資料');
		// 通知Server進行成員資料更新
		console.log("server update member");
		console.log(this.profileForm.value);
		// 偵測使用者設備
		let _logUserDevice = this.globalService.detectUserAgent();
		// _erpUserid, _erpCompid, _userName, _isActive, power, _userDevice
		this.memberService.updateERPMember(
			this.profileForm.value['ERPUserID'],
			this.profileForm.value['ERPCompID'],
			this.profileForm.value['UserName'],
			parseInt(this.profileForm.value['isActive']),
			parseInt(this.profileForm.value['managerRadios']),
			_logUserDevice
		).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {

				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料更新成功!');
				this.loadingService.stopLoading();
				this.configService.refreshDataList();
				this.IsBrowsing = true;
				this.IsBinding = false;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料更新錯誤!');
				this.loadingService.stopLoading();
				this.router.navigate(['/']);
			}
		});		
	}

	// 取消更新成員資料
	cancelUpdateMember() {
		this.IsBrowsing = true;
		this.IsBinding = false;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);		
	}

	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
				return false;
				break;
			case 102:
			case 104:
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}
}
