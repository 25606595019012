import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { StockSellService } from '../../service/stocksell.service';
import { LoginService } from '../../service/login.service';

// 建立表單控制套件
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import { StockHeader } from '../../interface/stockheader';
// 稅別選擇
import TaxStateObj from '../../config/taxState.json';
import PriceOfTaxObj from '../../config/priceoftax.json';
import CurrencyObj from '../../config/currency.json';

// 備註編輯器
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-stock-sell',
	templateUrl: './stock-sell.component.html',
	styleUrls: ['./stock-sell.component.css'],
	providers: [StockSellService, DecimalPipe]
})
export class StockSellComponent implements OnInit {

	stockSells$: Observable<StockHeader[]>;
	total$: Observable<number>;
	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsDetail: boolean = false;
	IsCreate: boolean = false;
	provider$: any = []; // 客戶
	products$: any = [];
	taxstates$: any = [];
	pricetax$: any = [];
	currency$: any = [];
	warehouses$: any = [];

	// 預設使用者權限為最低
	userPower = 103;

	// 訂單資料
	OrderSheet: any = {};
	// 客戶資料實體
	ProviderData: any = {};
	// 判斷是否有 銷貨數量 大於 庫存數量的情況
	IsBeyond: boolean = true;

	// 新增使用的 form
	stocksellForm = new FormGroup({
		OrderCode: new FormControl(''),
		StockSheetCode: new FormControl(''), // 銷貨單號
		ERPCompID: new FormControl(''),
		SheetDate: new FormControl(''),
		SheetType: new FormControl(2),
		Memo: new FormControl(''),
		SheetProviderID: new FormControl(''),
		DeliverCityCode: new FormControl(''),
		DeliverCityName: new FormControl(''),
		DeliverZipCode: new FormControl(''),
		DeliverZipName: new FormControl(''),
		DeliverAddress: new FormControl(''),
		CurrencyCode: new FormControl(1),
		PriceOfTax: new FormControl(1),
		TotalQuantity: new FormControl(0),
		TotalPrice: new FormControl(0),
		TaxFee: new FormControl(0),
		TaxState: new FormControl(1),
		SheetState: new FormControl(1),
		StockSellItems: new FormArray([]),
		SubjID: new FormControl(''),
		ReceiverArray: new FormControl(null),
		ReceiverNameArray: new FormControl(null)
	});

	// 查看詳細資料
	detailStockSellForm = new FormGroup({
		StockSheetCode: new FormControl(''),
		ERPCompID: new FormControl(''),
		SheetDate: new FormControl(''),
		SheetType: new FormControl(2),
		Memo: new FormControl(''),
		SheetProviderID: new FormControl(''),
		ProviderCode: new FormControl(''),
		ProviderPhoneNum: new FormControl(''),
		ProviderFaxNum: new FormControl(''),
		ProviderTaxCode: new FormControl(''),
		DeliverCityCode: new FormControl(''),
		DeliverCityName: new FormControl(''),
		DeliverZipCode: new FormControl(''),
		DeliverZipName: new FormControl(''),
		DeliverAddress: new FormControl(''),
		CurrencyCode: new FormControl(1),
		PriceOfTax: new FormControl(1),
		TotalQuantity: new FormControl(0),
		TotalPrice: new FormControl(0),
		TaxFee: new FormControl(0),
		TaxState: new FormControl(1),
		SheetState: new FormControl(1),
		StockSellItems: new FormArray([]),
		ReplyID: new FormControl('')
	});

	editorConfig: AngularEditorConfig = {
	    editable: true,
	      height: 'auto',
	      minHeight: '50',
	      maxHeight: '200',
	      width: 'auto',
	      minWidth: '0',
	      enableToolbar: false,
	      showToolbar: false,
	      placeholder: 'Enter memo here...',
	      defaultParagraphSeparator: 'p',
	      defaultFontName: '',
	      defaultFontSize: '12',
	      fonts: [
	        {class: 'arial', name: 'Arial'},
	        {class: 'times-new-roman', name: 'Times New Roman'},
	        {class: 'calibri', name: 'Calibri'},
	        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
	      ],
	      customClasses: [
	      {
	        name: 'quote',
	        class: 'quote',
	      },
	      {
	        name: 'redText',
	        class: 'redText'
	      },
	      {
	        name: 'titleText',
	        class: 'titleText',
	        tag: 'h1',
	      },
	    ],
	    uploadUrl: 'v1/image',
	    sanitize: false,
	    toolbarPosition: 'top'
	};

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
	constructor(
		private route: ActivatedRoute, 
		private router: Router,
		private loginService: LoginService,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public stocksellService: StockSellService
	) {
		this.stockSells$ = stocksellService.stocksells$;
		this.total$ = stocksellService.total$;

		this.taxstates$ = TaxStateObj.TaxStates;
		this.pricetax$ = PriceOfTaxObj.PriceOfTax;
		this.currency$ = CurrencyObj.Currency;
	}

	ngOnInit() {
		let _year = new Date().getFullYear();
		let _month = new Date().getMonth() + 1;
		let _date = new Date().getDate();
		let _todayDateStr = _year + "-" + _month + "-" + _date;
		// 參數
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _token = localStorage.getItem('Token');
		// 如果 StockSell 有掛公司和人員ID 就代表建立入庫單後 要進行導頁
		if (this.route.snapshot.paramMap.get('erpCompID') || this.route.snapshot.paramMap.get('succUserID')) {
			localStorage.removeItem('DirectURL');
			localStorage.setItem('DirectURL', 'https://erp.succmail.com/stockSell/'+this.route.snapshot.paramMap.get('orderCode'));
		}

		this.loadingService.startLoading("權限確認中...");
		// 取得使用者權限
		if (_currentCompInfo != null && _currentUserInfo != null) {
			if (this.route.snapshot.paramMap.get('erpCompID') || this.route.snapshot.paramMap.get('succUserID')) {
				this.globalService.getUserPowerIDBySuccUserID(this.route.snapshot.paramMap.get('erpCompID'), this.route.snapshot.paramMap.get('succUserID')).subscribe(response => {
					if (response.result) {
						this.userPower = response.MemberInfo['Power'];
						localStorage.removeItem('currentERPUser');
						localStorage.setItem('currentERPUser', JSON.stringify(response.MemberInfo));
						console.log(response);
					}
					this.loadingService.stopLoading();
				});
			}
			else {
				this.globalService.getUserPowerID(_currentCompInfo.ERPCompID, _currentUserInfo.ERPUserID).subscribe(response => {
					if (response.result) {
						this.userPower = response.MemberInfo['Power'];
					}
					this.loadingService.stopLoading();
				});
			}
		}
		else {
			alert('ERP系統尚未登入！');
			localStorage.removeItem('DirectERPCompID');
			localStorage.removeItem('DirectSuccUserID');
			localStorage.setItem('DirectERPCompID', this.route.snapshot.paramMap.get('erpCompID'));
			localStorage.setItem('DirectSuccUserID', this.route.snapshot.paramMap.get('succUserID'));
			// 回到登入畫面
			this.router.navigate(['/login']);
			this.loadingService.stopLoading();
		}

		this.loadingService.startLoading("公司資料確認中...");
		if (_currentCompInfo != null && (this.route.snapshot.paramMap.get('erpCompID') || this.route.snapshot.paramMap.get('succUserID'))) {
			if (_currentCompInfo.ERPCompID.toLowerCase() != this.route.snapshot.paramMap.get('erpCompID').toLowerCase()) {
				// 如果進來的單據 跟 localStorage的CompID不一樣
				let _isExistERPCompany = this.loginService.checkERPEntityIsExist(this.route.snapshot.paramMap.get('succUserID'));
				_isExistERPCompany.subscribe(response => {
					if (response.IsSuccess) {
						let _compData = response.CompList.filter(x => x.ERPCompID.toLowerCase() == this.route.snapshot.paramMap.get('erpCompID').toLowerCase())[0];
						localStorage.removeItem('currentERPComp');
						localStorage.setItem('currentERPComp', JSON.stringify(_compData));
						// 寫入到 loginService
						this.loginService.setCurrentLogincomp({
							ERPComp: _compData.ERPCompID,
							SuccCompID: _compData.SuccCompID,
							LogoPath: _compData.LogoPath,
							CompName: _compData.CompName
						});
						this.extractOrderData(_compData, _currentUserInfo, _token);
						this.loadingService.stopLoading();
					}
				});
			}
			else {
				this.extractOrderData(_currentCompInfo, _currentUserInfo, _token);
			}
		}
		else {
			this.extractOrderData(_currentCompInfo, _currentUserInfo, _token);
		}

		console.log("ERPCompID : ", this.route.snapshot.paramMap.get('erpCompID'));
		console.log("UserID : ", this.route.snapshot.paramMap.get('succUserID'));
	}

	// 執行訂單資料擷取動作
	extractOrderData(_currentCompInfo, _currentUserInfo, _token) {
		let _year = new Date().getFullYear();
		let _month = new Date().getMonth() + 1;
		let _date = new Date().getDate();
		let _todayDateStr = _year + "-" + _month + "-" + _date;
		// 訂單編號ID
		var _orderCode = this.route.snapshot.paramMap.get('orderCode');
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;

			// 一般瀏覽畫面
			if (_orderCode == null) {
				// 取得客戶資料
				this.stocksellService.getCustomerList(_erpCompid).subscribe(response => {
					if (response.IsSuccess) {
						this.provider$ = response.CustomerList;
					}
				});
			}

			// 訂單點擊轉單進入
			if (_orderCode != null) {
				// 進入建立銷貨單狀態
				this.loadingService.startLoading('比對訂單資料中...');
				this.IsBrowsing = false;
				this.IsCreate = true;
				this.IsDetail = false;
				this.stocksellService.getOrderList(
					_erpCompid,
					_orderCode
				).subscribe(response => {
					if (response.result) {
						console.log(response.BillData);
						this.OrderSheet = response.BillData;
						// 寫入Form的值
						this.stocksellForm.patchValue({
							ERPCompID: this.OrderSheet.ERPCompID,
							SheetDate: { "year": _year, "month": _month, "day": _date},
							SheetProviderID: this.OrderSheet.BillProviderID,
							CurrencyCode: this.OrderSheet.CurrencyCode,
							PriceOfTax: this.OrderSheet.PriceOfTax,
							TaxState: this.OrderSheet.TaxState,
							SubjID: this.OrderSheet.SuccSubjID,
							Memo: '<p><br /></p>',
						});
						// 進入下一階段loading
						this.loadingService.stopLoading();
						this.loadingService.startLoading('取得客戶資料中...');
						this.stocksellService.getCustomerList(
							_erpCompid
						).subscribe(response => {
							if (response.IsSuccess) {
								this.provider$ = response.CustomerList;
								let _provider = this.provider$.filter(x => { return x.CustomerID == this.OrderSheet.BillProviderID; })[0];
								this.ProviderData.CustomerID = _provider.CustomerID;
								this.ProviderData.CustomerCode = _provider.CustomerCode;
								this.ProviderData.CustomerName = _provider.CustomerName;
								this.ProviderData.CustomerTaxCode = _provider.CustomerTaxCode;
								this.ProviderData.CustomerPhoneNum = _provider.CustomerPhoneNum;
								this.ProviderData.CustomerFaxNum = _provider.CustomerFaxNum;
								this.ProviderData.CustomerCityCode = _provider.CustomerCityCode;
								this.ProviderData.CustomerCityName = _provider.CustomerCityName;
								this.ProviderData.CustomerZipCode = _provider.CustomerZipCode;
								this.ProviderData.CustomerZipName = _provider.CustomerZipName;
								this.ProviderData.CustomerAddress = _provider.CustomerAddress;
								this.loadingService.stopLoading();
							}
							else {
								this.failHttpRequest('open', 1, '訂單不存在!');
								this.router.navigate(['/stockSell']);
							}
						});
						this.loadingService.startLoading('取得庫存資料中...');
						// 取得倉庫列表
						this.stocksellService.getWarehouseList(
							_erpCompid,
						).subscribe(warehouse => {
							if (warehouse.IsSuccess) {
								this.warehouses$ = warehouse.WarehouseList;
								// 塞入品項值
								for (var i = 0; i<response.BillData.order_bill_data.length; i++) 
								{
									let _index = i;
									this.stocksellService.getItemInventoryQuantity(
										_erpCompid,
										this.warehouses$[0]['WarehouseID'],
										response.BillData.order_bill_data[i]['ItemMaterialCode']
									).subscribe(quantityRes => {
										let _stockQuantity = 0;
										if (quantityRes.IsSuccess) {
											_stockQuantity = quantityRes.ItemInventoryQuantity;
										}
										(this.stocksellForm.get('StockSellItems') as FormArray).push(
											this.stockSellDetailForm(response.BillData, _index, _stockQuantity)
										);
										this.loadingService.stopLoading();
									});
								}
							}
						});
						// 根據主題ID拿取DiscID，再用DiscID拿取收件人
						this.stocksellService.getDiscIDBySubjID(
							_currentCompInfo.SuccCompID,
							_currentUserInfo.SuccUserID,
							this.OrderSheet.SuccSubjID,
							_token
						).subscribe(response => {
							if (response.result) {
								// 指派DiscID
								this.OrderSheet.DiscID = response.DiscID;
								// 拿收件人
								this.stocksellService.initNewMsgReceiver(
									_currentCompInfo.SuccCompID,
									response.DiscID,
									_currentUserInfo.SuccUserID,
									_token
								).subscribe(receiverRes => {
									console.log(receiverRes);
									let _activeReceiver = receiverRes.data.filter(x => x.relate_comp_user != null);
									let _fixReceiverArray = [];
									let _receiverNameArray = [];
									_activeReceiver.filter(x => x.DefaultReceiver == 1).forEach(function(_item) {
										_fixReceiverArray.push(_item.UserID);
										_receiverNameArray.push({
											'UserName': _item.relate_comp_user.LastName + _item.relate_comp_user.FirstName
										});
									});
									// 如果當中不包含建立者本人
									if (_fixReceiverArray.filter(x => x == _currentUserInfo['SuccUserID']).length < 1) {
										_fixReceiverArray.push(_currentUserInfo['SuccUserID']);
										_receiverNameArray.push({
											'UserName': _currentUserInfo.UserName
										});
									}
									this.stocksellForm.patchValue({
										ReceiverArray: _fixReceiverArray,
										ReceiverNameArray: _receiverNameArray
									});
								});
							}
						});
						// 抽取主題的搜尋內容
						this.stocksellService.getSearchContent({
							CompID: _currentCompInfo.SuccCompID,
							SubjID: this.OrderSheet.SuccSubjID,
							token: _token
						}).subscribe(response => {
							console.log(response);
							if (response.result) {
								this.OrderSheet.SearchContent = response.searchContent.sContent;
								this.OrderSheet.Attachment = response.searchContent.Attachment;
							}
						});
					}
					else {
						this.failHttpRequest('open', 1, '訂單不存在!');
						this.router.navigate(['/stockSell']);
					}
				});

				this.stocksellForm.patchValue({
					OrderCode: _orderCode
				});
			}
			else {
				this.loadingService.startLoading('取得庫存資料中...');
				// 取得倉庫列表
				this.stocksellService.getWarehouseList(
					_erpCompid,
				).subscribe(warehouse => {
					if (warehouse.IsSuccess) {
						this.warehouses$ = warehouse.WarehouseList;
						this.loadingService.stopLoading();
					}
				});
			}

			// 取得產品列表
			this.stocksellService.getProductItemsList(
				_erpCompid
			).subscribe(response => {
				if (response.IsSuccess) {
					this.products$ = response.ItemsList;
				}
			});
			// 產生銷貨單號
			this.stocksellService.generateStockSellCode(
				_erpCompid,
				_todayDateStr,
				2
			).subscribe(response => {
				if (response.IsSuccess) {
					this.stocksellForm.patchValue({
						StockSheetCode: response.SheetCode // 銷貨單號
					});
				}
			});
		}
	}


	onSort({column, direction}: SortEvent) {
		// resetting other headers
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.stocksellService.sortColumn = column;
		this.stocksellService.sortDirection = direction;
	}

	// 回到選單頁面
	goToMenuPage() {
		this.router.navigate(['/home']);
	}

	// 產生單據日期字串
	generateSheetDate(_sheetDate) {
		return _sheetDate.substring(0, 10);
	}

	// 透過 providerID 產生 客戶 名稱
	generateProviderName(_providerID) {
		let _providerName = this.provider$.filter(x => x.CustomerID == _providerID)[0]['CustomerName'];
		return _providerName;
	}

	// 查看銷貨單詳細資料
	checkDetail(_item) {
		console.log(_item);
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsDetail = true;
		// 編輯器不可編輯
		this.editorConfig.editable = false;
		// 重置品項陣列
		if ((this.detailStockSellForm.get('StockSellItems') as FormArray) != null) {
			while ((this.detailStockSellForm.get('StockSellItems') as FormArray).length) {
				(this.detailStockSellForm.get('StockSellItems') as FormArray).removeAt(0);
			}
		}
		let _provider = this.provider$.filter(x => {return x.CustomerID == _item.SheetProviderID;})[0];
		this.detailStockSellForm.patchValue({
			StockSheetCode: _item.StockSheetCode,
			ERPCompID: _item.ERPCompID,
			SheetDate: _item.SheetDate.substring(0, 10),
			SheetType: _item.SheetType,
			Memo: _item.Memo.length > 0 ? _item.Memo:"<p><br /></p>",
			SheetProviderID: _item.SheetProviderID,
			ProviderCode: _provider.CustomerCode,
			ProviderPhoneNum: _provider.CustomerPhoneNum,
			ProviderFaxNum: _provider.CustomerFaxNum,
			ProviderTaxCode: _provider.CustomerTaxCode,
			DeliverCityCode: _item.DeliverCityCode,
			DeliverCityName: _item.DeliverCityName,
			DeliverZipCode: _item.DeliverZipCode,
			DeliverZipName: _item.DeliverZipName,
			DeliverAddress: _item.DeliverAddress,
			CurrencyCode: _item.CurrencyCode,
			PriceOfTax: _item.PriceOfTax,
			TotalQuantity: _item.TotalQuantity,
			TotalPrice: _item.TotalPrice,
			TaxFee: _item.TaxFee,
			TaxState: _item.TaxState,
			SheetState: _item.SheetState,
			ReplyID: _item.SuccReplyID
		});
		for (var i = 0; i< _item['stock_sell_data'].length; i++) {
			this.addNewStockSellItem(_item['stock_sell_data'][i]);
		}
	}

	// 增加銷貨品項
	addNewStockSellItem(_item) {
		let _warehouseName = this.warehouses$.filter(x => { return x.WarehouseID == _item.WarehouseID})[0]['WarehouseName'];
		(this.detailStockSellForm.get('StockSellItems') as FormArray).push(this.stockSellBodyForm(
			_item.ID,
			_item.Serial,
			_item.StockSheetCode,
			_item.WarehouseID,
			_warehouseName,
			_item.OrderCode,
			_item.ItemMaterialCode,
			_item.ItemName,
			_item.ItemSpec,
			_item.ItemUnitCode,
			_item.ItemUnitName,
			_item.ItemCurrency,
			_item.ItemQuantity,
			_item.SellQuantity,
			_item.SellPrice,
			_item.SellTotal
		));
	}

	// 銷貨品項
	private stockSellBodyForm(_id, _serial, _sheetCode, _warehouseID, _warehouseName, _orderCode, _materialCode, _itemName, _itemSpec, _itemUnitCode, _itemUnitName, _itemCurrency, _itemQuantity, _sellQuantity, _sellPrice, _sellTotal): FormGroup {
		return new FormGroup({
			ID: new FormControl(_id), // 隱藏參數
			Serial: new FormControl(_serial),
			StockSheetCode: new FormControl(_sheetCode),
			WarehouseID: new FormControl(_warehouseID),
			WarehouseName: new FormControl(_warehouseName),
			OrderCode: new FormControl(_orderCode),
			ItemMaterialCode: new FormControl(_materialCode),
			ItemName: new FormControl(_itemName),
			ItemSpec: new FormControl(_itemSpec),
			ItemUnitCode: new FormControl(_itemUnitCode),
			ItemUnitName: new FormControl(_itemUnitName),
			ItemCurrency: new FormControl(_itemCurrency),
			ItemQuantity: new FormControl(_itemQuantity),
			SellQuantity: new FormControl(_sellQuantity),
			SellPrice: new FormControl(_sellPrice),
			SellTotal: new FormControl(_sellTotal)
		});
	}

	// 將建立銷貨單的需求送到 Server
	onSubmitStockSell() {
		console.log("submit Stock Sell");
		this.loadingService.startLoading('產生銷貨單據PDF中...');
		// 全域參數
		let _token = localStorage.getItem("Token");
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserName = _currentUserInfo.UserName;
		let _erpUserid = _currentUserInfo.ERPUserID;

		// 取得判斷所有計算出的品項金額 小數點後第一位 是不是都是5 
		// 如果 true: 計算應稅外加時，稅額不進位只取到整數位
		// 如果 false: 計算應稅外加時，稅額從小數點後面逐個進位
		let _isFiveAtFirstPoint = this.calculateTotalPointIsFive();
		// 總數量
		let _totalQuantity = this.calculateTotalAmount();
		// 品項數量
		let _itemLength = (this.stocksellForm.get('StockSellItems') as FormArray).length;
		// 錢相關
		let _total = 0;
		for (var i = 0; i < _itemLength; i++) {
			_total += (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellTotal'];
		}

		let _priceInfo = this.globalService.calculateTotalAndTaxFee(
			_isFiveAtFirstPoint,
			_itemLength,
			_total,
			this.stocksellForm.value['PriceOfTax'],
			this.stocksellForm.value['TaxState']
		);
		let _taxFee = _priceInfo.TaxFee;
		let _noTaxTotal = _priceInfo.NoTaxTotal;
		let _withTaxTotal = _priceInfo.WithTaxTotal;

		// 表頭參數
		let _billDate = this.stocksellForm.value['SheetDate']['year']+"/"+this.stocksellForm.value['SheetDate']['month']+"/"+this.stocksellForm.value['SheetDate']['day'];

		// 準備要使用的列印PDF的參數
		let _paramObjForCreatePDF = {
			ERPCompName: _currentCompInfo.CompName,
			ERPCompAreaCode: '',
			ERPCompAddress: _currentCompInfo.CompAddress,
			ERPCompPhone: _currentCompInfo.CompPhoneNum,
			ERPCompFax: _currentCompInfo.CompFaxNum,
			WesternTodayDate: this.globalService.convertWesternDateStr(new Date(),"/"),
			WesternStockSellDate: _billDate,
			CustomerCode: this.ProviderData.CustomerCode,
			CustomerName: this.ProviderData.CustomerName,
			CustomerAddress: this.ProviderData.CustomerZipCode + " " + this.ProviderData.CustomerCityName + this.ProviderData.CustomerZipName + this.ProviderData.CustomerAddress,
			CustomerPhoneNum: this.ProviderData.CustomerPhoneNum,
			CustomerFaxNum: this.ProviderData.CustomerFaxNum,
			CustomerTaxCode: this.ProviderData.CustomerTaxCode,
			CurrencyID: this.stocksellForm.value['CurrencyCode'],
			CurrencyName: this.globalService.convertCurrencyShortID(this.stocksellForm.value['CurrencyCode']),
			CurrencyRate: 1,
			SalesManID: '',
			SalesManName: _erpUserName,
			CreatorName: _erpUserName,
			Memo: encodeURIComponent(this.stocksellForm.value['Memo']),
			BillNo: this.stocksellForm.value['StockSheetCode'], // 銷貨單號
			OrderCode: this.stocksellForm.value['OrderCode'], // 來源訂單單號
			ItemTaxState: this.stocksellForm.value['PriceOfTax'] == 0 ? "未稅":"含稅",
			TotalItemCount: _itemLength,
			TotalItemAmount: _totalQuantity,
			NoTaxTotal: _priceInfo.NoTaxTotal,
			TaxFee: _priceInfo.TaxFee,
			WithTaxTotal: _priceInfo.WithTaxTotal,
			StockSellSheetItem: this.generateStockSellItemArray(_itemLength),
			FooterTerms: '',
			CreatorCompID: _currentCompInfo.SuccCompID
		};

		// 建立銷貨單物件
		let _stockSellSheet = {
			ERPCompID: _erpCompid,
			SheetDate: _billDate,
			StockSheetCode: this.stocksellForm.value['StockSheetCode'],
			SheetType: this.stocksellForm.value['SheetType'],
			Memo: this.stocksellForm.value['Memo'],
			OperatorID: _erpUserid,
			SheetProviderID: this.stocksellForm.value['SheetProviderID'],
			DeliverCityCode: this.ProviderData.CustomerCityCode,
			DeliverCityName: this.ProviderData.CustomerCityName,
			DeliverZipCode: this.ProviderData.CustomerZipCode,
			DeliverZipName: this.ProviderData.CustomerZipName,
			DeliverAddress: this.ProviderData.CustomerAddress,
			CurrencyCode: this.stocksellForm.value['CurrencyCode'],
			PriceOfTax: this.stocksellForm.value['PriceOfTax'],
			TotalQuantity: _totalQuantity,
			TotalPrice: _noTaxTotal,
			TaxFee: _taxFee,
			TaxState: this.stocksellForm.value['TaxState'],
			SheetBody: JSON.stringify(this.stocksellForm.value['StockSellItems'].filter(x => x.SellQuantity > 0)),
			LogUserDevice: this.globalService.detectUserAgent(),
			InventoryType: '303',
			InventoryTypeName: '銷貨出貨'
		};
		// 回覆不需要Title 但寫入Log需要
		let _subjTitle = this.globalService.generateSubjTitle(_itemLength, (this.stocksellForm.get('StockSellItems') as FormArray));

		var replyFormData = new FormData();
		replyFormData.append("token", _token);
		replyFormData.append("CompID", _currentCompInfo.SuccCompID);
		replyFormData.append("DiscID", this.OrderSheet.DiscID);
		replyFormData.append("SubjID", this.stocksellForm.value['SubjID']);
		replyFormData.append("UserID", _currentUserInfo.SuccUserID);
		replyFormData.append("sContent", this.generateReplyContent(_priceInfo));
		replyFormData.append("ReplyTo", "00000000-0000-0000-0000-000000000000");
		replyFormData.append("Receiver", JSON.stringify(this.stocksellForm.value['ReceiverArray']));
		replyFormData.append("ByUserDevice", this.globalService.detectUserDevice());
		replyFormData.append("ByUserBrowser", this.globalService.detectUserAgent());		
		replyFormData.append("DetailSubjTitle", "銷貨 - " + this.ProviderData.SupplierName + " - " + _subjTitle + " - " + this.globalService.convertWesternDateStr(new Date(),"/"));
		replyFormData.append("SubjRead", JSON.stringify(this.stocksellForm.value['ReceiverNameArray']));
		replyFormData.append("ReplyType", '3004'); // 2001: 正航採購, 2003: 正航訂單, 3001: Succ採購, 3002: Succ入庫, 3003: Succ訂單, 3004: Succ銷貨

		// 準備存放上傳完成的檔案
		let AttList = [];
		this.stocksellService.generateStockSellPDF(_paramObjForCreatePDF).subscribe(pdfRes => {
			if (pdfRes.IsSuccess) {
				let _attID = pdfRes.AttID;
				let _filePath = pdfRes.FilePath;
				let _filSize = pdfRes.FileSize;
				let _fileSizeView = pdfRes.FileSizeView;
				let _fileExt = pdfRes.FileExt;
				AttList.push({
					'AttID': pdfRes.AttID,
					'FileName': _paramObjForCreatePDF.WesternTodayDate + "-" + _paramObjForCreatePDF.CustomerName + "-" + "銷貨單憑證.pdf",
					'FilePath': pdfRes.FilePath,
					'FileSize': pdfRes.FileSize,
					'FileSizeView': pdfRes.FileSizeView,
					'FileType': pdfRes.FileExt.toString().toUpperCase(),
					'IsDel': false,
					'IsEmbed': false,
					'IsSuccess': true,
					'uid': "rc-upload-1554787902309-2"
				});
				
				// 指派Succ建立回覆物件的檔案資料
				replyFormData.append("AttList", JSON.stringify(AttList)); 
				// 產生Succ搜尋內容
				let _searchContent = this.OrderSheet.SearchContent + "@||@" + this.generateReplyContent(_priceInfo);
				let _searchAttachment = this.OrderSheet.Attachment + "@||@" + AttList[0]['FileName'];
				replyFormData.append("Search_Content", _searchContent);
				replyFormData.append("Search_Att", _searchAttachment);

				// 進入下一階段loading
				this.loadingService.stopLoading();
				this.loadingService.startLoading('建立銷貨單據中...');

				// 建立銷貨單據
				this.stocksellService.insertStockSellSheet(_stockSellSheet).subscribe(stockRes => {
					if (stockRes.IsSuccess) {
						// 進入下一階段loading
						this.loadingService.stopLoading();
						this.loadingService.startLoading('建立SuccMail回覆中...');
						// 指派Reply屬於哪一個BillNo
						let _sheetCode = stockRes.SheetCode; // 進server有再判斷一次這個billCode有無重複
						replyFormData.append("SheetCode", _sheetCode);
						// 建立Succmail Reply
						this.stocksellService.createStockSellSuccReply(
							replyFormData
						).subscribe(succRes => {
							// 進入下一階段loading
							this.loadingService.stopLoading();
							this.loadingService.startLoading('資料最後確認...');
							if (succRes.result) {
								// 寫入銷貨單對應的 ReplyID
								this.stocksellService.updateStockSellRelatedReplyID({
									ERPCompID: _erpCompid,
									SheetType: 2,
									SheetCode: this.stocksellForm.value['StockSheetCode'],
									ReplyID: succRes.ReplyID
								}).subscribe(updateReplyRes => {
									this.loadingService.stopLoading();
									window.scrollTo(0, 0);
									this.globalService.changeRemindState('open');
									this.globalService.changeRemindType(0);
									this.globalService.changeRemindMsg('成功建立銷貨單據!');
									this.stocksellService.refreshDataList();
									this.IsBrowsing = true;
									this.IsCreate = false;
									this.IsDetail = false;
									let _directURL = localStorage.getItem('DirectURL');
									if (_directURL != null) {
										if (_directURL.length > 0 && _directURL.indexOf("stockSell") > 0) {
											localStorage.removeItem('DirectURL');
											window.location.href = "https://succmail.com/Spage/" + _currentCompInfo.SuccCompID + "/" + _currentUserInfo.SuccUserID + "/" + this.stocksellForm.value['SubjID'];
										}
										else {
											this.router.navigate(['/stockSell']);
										}
									}
								});
							}
							else {
								this.failHttpRequest('open', 1, '建立SuccMail回覆失敗!');
							}
						});
					}
					else {
						this.failHttpRequest('open', 1, '建立銷貨單據資料失敗!');
					}
				});
			}
			else {
				this.failHttpRequest('open', 1, '建立銷貨單據PDF失敗!');
			}
		});
	}

	// 品項出貨數量發生變化
	updateStockSellItem(_index) {
		console.log(_index);
		// 輸入值
		let _sellQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['SellQuantity'];
		// 訂單訂購數量
		let _orderQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['OrderQuantity'];
		// 已銷貨數量
		let _deliveredQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['DeliveredQuantity'];
		// 該庫庫存數量
		let _stockQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['StockQuantity'];
		
		// 判斷有無銷貨輸量 超過 庫存數量
		if (_sellQuantity > _stockQuantity) {
			this.IsBeyond = true;
		}
		else {
			this.IsBeyond = false;
		}

		if (_sellQuantity > (_orderQuantity - _deliveredQuantity) || _deliveredQuantity == null || _sellQuantity > _stockQuantity) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('數值輸入異常!');
			// 塞入值 留意
			this.stocksellForm.get('StockSellItems')['controls'][_index].patchValue({
				SellTotal: 0
			});
		}
		else {
			// 參數宣告
			let result = 0; // 最後計算完小數點回傳的品項總金額
			let total = 0; // 未計算小數點前的品項總金額
			let total_length = 0;
			let dot_index = 0;
			let dot_last_index;

			let _price = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['SellPrice'];
			let _sellQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['SellQuantity'];
			total = _sellQuantity * _price;
			total_length = total.toString().length;
			dot_index = total.toString().indexOf(".");
			dot_last_index = total.toFixed(5).lastIndexOf('.')+5; // +5 用意是最小取至第五位進行四捨五入
			if (dot_index == -1) {
				result = parseFloat(total.toString());
			}
			else {
				result = parseFloat(total.toFixed(5).substring(0, dot_last_index));
			}
			// 塞入值
			this.stocksellForm.get('StockSellItems')['controls'][_index].patchValue({
				SellTotal: result
			});
		}
	}

	// 取消建立銷貨單	
	cancelCreateStockSell() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsDetail = false;
		this.router.navigate(['/order']);
	}

	// 計算品項金額小數點後第一位 是不是都是5
	calculateTotalPointIsFive() {
		let result = true;
		let _itemLength = (this.stocksellForm.get('StockSellItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
			let _isFiveIndex = (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellTotal'].toString().indexOf(".5");
			if (_isFiveIndex == -1) {
				result = false;
				return result;
			}
		}
		return result;
	}

	// 計算品項總數量
	calculateTotalAmount() {
		let result = 0;
		let _itemLength = (this.stocksellForm.get('StockSellItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
			result += (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellQuantity'];
		}
		return result;
	}

	// 轉Item Array 的方法，後續完整後要轉到Action裡面 For PDF
	generateStockSellItemArray(_itemLength) {
		let result = [];
		for (var i = 0; i < _itemLength; i++) {
			let _warehouseName = 
			result.push({
				'Serial': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['Serial'].toString(),
				'ItemID': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemMaterialCode'],
				'ItemName': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemName'] + '-' + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemSpec'],
				'SellQuantity': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellQuantity'], // 銷貨數量
				'ItemUnit': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemUnitName'],
				'SellPrice': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellPrice'],
				'SellTotal': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellTotal'],
				'ItemWarehouseName': this.generateWarehouseName((this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['WarehouseID']),
				'OrderCode': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['OrderCode'],
				'NotSellQuantity': (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['OrderQuantity'] - (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['DeliveredQuantity'] - (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellQuantity'] // 未出貨數量
			});
		}
		return result;
	}

	// 產生倉庫名稱
	generateWarehouseName(_warehouseID): string {
		return this.warehouses$.filter(x => {return x.WarehouseID == _warehouseID;})[0]['WarehouseName'];
	}

	// HTTP Request 失敗狀態
	failHttpRequest(_msgState, _remindType, _remindMsg) {
		// remindType 0: Success, 1: Fail
		this.loadingService.stopLoading();
		window.scrollTo(0, 0);
		this.globalService.changeRemindState(_msgState);
		this.globalService.changeRemindType(_remindType);
		this.globalService.changeRemindMsg(_remindMsg);
	}

	// 產生銷貨品項
	private stockSellDetailForm(_stockSell, _index, _stockQuantity): FormGroup {
		let _warehouseID = "";
		if (this.warehouses$.length > 0) {
			_warehouseID = this.warehouses$[0]['WarehouseID'];
		}
		return new FormGroup({
			OrderItemID: new FormControl(_stockSell.order_bill_data[_index]["ID"]), // 對應的purchase Item 主key
			ERPCompID: new FormControl(_stockSell.ERPCompID),
			Serial: new FormControl(_stockSell.order_bill_data[_index]["Serial"]),
			StockSheetCode: new FormControl(this.stocksellForm.value['StockSheetCode']),
			WarehouseID: new FormControl(_warehouseID),
			OrderCode: new FormControl(_stockSell.order_bill_data[_index]["OrderCode"]),
			ItemCategoryID: new FormControl(_stockSell.order_bill_data[_index]["ItemCategoryID"]),
			ItemMaterialCode: new FormControl(_stockSell.order_bill_data[_index]["ItemMaterialCode"]),
			ItemName: new FormControl(_stockSell.order_bill_data[_index]["ItemName"]),
			ItemSpec: new FormControl(_stockSell.order_bill_data[_index]["ItemSpec"]),
			ItemUnitCode: new FormControl(_stockSell.order_bill_data[_index]["ItemUnitCode"]),
			ItemUnitName: new FormControl(_stockSell.order_bill_data[_index]["ItemUnitName"]),
			ItemCurrency: new FormControl(_stockSell.order_bill_data[_index]["ItemCurrency"]),
			StockQuantity: new FormControl(_stockQuantity), // 計算使用，不寫入Table
			OrderQuantity: new FormControl(_stockSell.order_bill_data[_index]["ItemQuantity"]), // 品項訂購數量 order.ItemQuantity
			DeliveredQuantity: new FormControl(_stockSell.order_bill_data[_index]["ItemDeliverQuantity"]), // 品項銷貨數量 order.ItemDeliverQuatity
			SellQuantity: new FormControl(
				0, 
				[Validators.max(_stockSell.order_bill_data[_index]["ItemQuantity"] - _stockSell.order_bill_data[_index]["ItemDeliverQuantity"])]
			), // 品項欲銷貨數量
			SellPrice: new FormControl(_stockSell.order_bill_data[_index]["ItemPrice"]),
			SellTotal: new FormControl(0) // SellQuantity*SellPrice
		});
	}

	// 品項倉庫數值發生變化
	onWarehouseChange(_target, _index) {
		if (_target == undefined) {
			this.stocksellForm.get('StockSellItems')['controls'][_index].patchValue({
				StockQuantity: 0
			});			
		}
		else {
			this.loadingService.startLoading('取得庫存資料中...');
			this.stocksellService.getItemInventoryQuantity(
				_target.ERPCompID,
				_target.WarehouseID,
				(this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['ItemMaterialCode']
			).subscribe(response => {
				if (response.IsSuccess) 
				{
					// 寫入該品項庫存數量
					this.stocksellForm.get('StockSellItems')['controls'][_index].patchValue({
						StockQuantity: response.ItemInventoryQuantity
					});
					// 判斷有無銷貨輸量 超過 庫存數量
					let _sellQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(_index).value['SellQuantity'];
					if (_sellQuantity > response.ItemInventoryQuantity) {
						this.IsBeyond = true;
					}
					else {
						this.IsBeyond = false;
					}

				}
				this.loadingService.stopLoading();
			});
		}
	}

	// 產生回覆內容
	generateReplyContent(_priceInfo) {
		let ContentString = "";

		let _orderCodeLabel = "訂單單號：";
		let _stockSellCodeLabel = "銷貨單號：";
		let customerIDLabel = "客戶編號：";
    	let customerNameLabel = "客戶名稱：";
    	let addressLabel = "客戶地址：";
    	let phoneLabel = "連絡電話：";
    	let faxLabel = "傳真號碼：";
    	let linkManLabel = "聯絡人員：";
    	let taxCodeLabel = "稅別種類：";
    	let currencyIDLabel = "幣　　別：";
    	let currencyRateLabel = "匯　　率：";
    	let itemIncludeTaxLabel = "產品單價：";

    	let serialLabel = "序　　號：";
    	let itemCodeLabel = "產品編號：";
    	let itemNameLabel = "品名規格：";
    	let itemUnitLabel = "品項單位：";
    	let itemOrderNumLabel = "訂購數量：";
    	let itemSellNumLabel = "銷貨數量：";
    	let itemStockNumLabel = "銷後庫存：";
    	let sellPriceLabel = "單　　價：";
    	let warehouseLabel = "倉　　庫：";
    	let eachPriceLabel = "銷售金額：";
    	let memoLabel = "備　　註：";

    	let orderPriceLabel = "原幣合計：";
    	let taxPriceLabel = "原幣稅金：";
    	let totalPriceLabel = "原幣總計：";

    	let emptyRow = "<p><br /></p>";
    	let seperateRow = "<p>------------------------------ 分隔線 ------------------------------</p>";
    	// 原幣
    	let _currencyID = this.globalService.convertCurrencyShortID(this.stocksellForm.value['CurrencyCode']);
    	let _currencyName = this.globalService.convertCurrencyName(this.stocksellForm.value['CurrencyCode']);
    	
    	let sellPriceRow = "<p>" + orderPriceLabel + _priceInfo.NoTaxTotal + " " + _currencyID + "</p>";
    	let taxFeeRow = "<p>" + taxPriceLabel + _priceInfo.TaxFee + " " + _currencyID + "</p>";
    	let withTaxTotalRow = "<p>" + totalPriceLabel + _priceInfo.WithTaxTotal + " " + _currencyID + "</p>";

    	// 統一內容區域
    	let orderCodeRow = "<p>" + _orderCodeLabel + this.OrderSheet.BillCode + "</p>";
    	let stockSellCodeRow = "<p>" + _stockSellCodeLabel + this.stocksellForm.value['StockSheetCode'] + "</p>";
    	let custIDRow = "<p>" + customerIDLabel + this.ProviderData.CustomerCode + "</p>";
    	let custNameRow = "<p>" + customerNameLabel + this.ProviderData.CustomerName + "</p>";
    	let addressRow = "<p>" + addressLabel + this.ProviderData.CustomerZipCode + " " + this.ProviderData.CustomerCityName + this.ProviderData.CustomerZipName + this.ProviderData.CustomerAddress + "</p>";
    	let phoneRow = "<p>" + phoneLabel + this.ProviderData.CustomerPhoneNum + "</p>";
    	let faxRow = "<p>" + faxLabel + this.ProviderData.CustomerFaxNum + "</p>";
    	// let linkManRow = "<p>" + linkManLabel + this.props.CustomerDetail.get("LinkMan") + "</p>";
    	let taxRow = "<p>" + taxCodeLabel + this.globalService.convertTaxState(this.stocksellForm.value['TaxState'].toString());
    	let currencyIDRow = "<p>" + currencyIDLabel + _currencyID + " " + _currencyName + "</p>";
    	let currencyRateRow = "<p>" + currencyRateLabel + 1 + "</p>";
    	// 產品品項是否含稅
    	let itemIncludeTaxVal = this.stocksellForm.value['PriceOfTax'] == 0 ? "未稅":"含稅";
    	let itemIncludeTaxRow = "<p>" + itemIncludeTaxLabel + itemIncludeTaxVal + "</p>";
    	let memoStr = this.stocksellForm.value['Memo'];
    	let memoLabelRow = "<p>" + memoLabel + memoStr + "</p>";

    	// 統一訊息部分
    	ContentString += orderCodeRow + stockSellCodeRow + custIDRow + custNameRow + addressRow + phoneRow + faxRow + taxRow + currencyIDRow + currencyRateRow + itemIncludeTaxRow + emptyRow + memoLabelRow + emptyRow;

    	// 個別品項訊息部分
		let _itemLength = (this.stocksellForm.get('StockSellItems') as FormArray).length;
		for (var i = 0; i < _itemLength; i++) {
			let _currentStockQuantity = (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['StockQuantity'] - (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellQuantity'];

    		let SerialRow = "<p>" + serialLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['Serial'] + "</p>";
    		let ItemCodeRow = "<p>" + itemCodeLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemMaterialCode'] + "</p>";
    		let ItemNameRow = "<p>" + itemNameLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemName'] + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemSpec'] + "</p>";
    		let ItemUnitRow = "<p>" + itemUnitLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['ItemUnitName'] + "</p>";
    		let OrderQuantityRow = "<p>" + itemOrderNumLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['OrderQuantity'] + "</p>";
    		let SellQuantityRow = "<p>" + itemSellNumLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellQuantity'] + "</p>";
    		let StockQuantityRow = "<p>" + itemStockNumLabel + _currentStockQuantity + "</p>";
    		let ItemPriceRow = "<p>" + sellPriceLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellPrice'] + " " + _currencyID + "</p>";
    		let WarehouseRow = "<p>" + warehouseLabel + this.generateWarehouseName((this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['WarehouseID']) + "</p>";
    		let EachItemTotalRow = "<p>" + eachPriceLabel + (this.stocksellForm.get('StockSellItems') as FormArray).at(i).value['SellTotal'] + " " + _currencyID + "</p>";

    		ContentString += SerialRow + ItemCodeRow + ItemNameRow + ItemUnitRow + OrderQuantityRow + SellQuantityRow + StockQuantityRow + ItemPriceRow + WarehouseRow + EachItemTotalRow + seperateRow; 
		}

    	// 金額訊息部分
    	ContentString += sellPriceRow + taxFeeRow + withTaxTotalRow;

    	return ContentString;
	}

	// 回到瀏覽畫面
	backToBrowsePage() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsDetail = false;
		window.scrollTo(0, 0);
	}

	// 確認該成員有無權限進行作廢動作
	// 只有權限 101, 104 才能進行作廢
	chekcIsDisAbleToDestroy() {
		switch (this.userPower) {
			case 101:
			case 104:
				return false;
				break;
			case 102:
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

	// 進行銷貨單退回
	returnStockSell() {
		this.loadingService.startLoading("銷貨單作廢中...");
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		// _erpCompid, _stocksSheetCode, _inventoryType, _inventoryTypeName, _operatorid, _loguserDeivce
		this.stocksellService.returnStockSell(
			this.detailStockSellForm.value['ERPCompID'],
			this.detailStockSellForm.value['StockSheetCode'],
			304,
			'銷庫作廢',
			_currentUserInfo.ERPUserID,
			this.globalService.detectUserAgent()
		).subscribe(response => {
			if (response.IsSuccess) {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('成功作廢銷庫單據!');
				this.stocksellService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsDetail = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg(response.message);
			}
			window.scrollTo(0, 0);
			this.loadingService.stopLoading();
		});		
	}

	// 表單日期發生變化
	sheetDateChange($event) {
		this.loadingService.startLoading('重新產生銷貨單號...');
		let _currentDateString = $event.year + "-" + $event.month + "-" + $event.day;
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;

			this.stocksellService.generateStockSellCode(
				_erpCompid,
				_currentDateString,
				2
			).subscribe(response => {
				if (response.IsSuccess) {
					this.stocksellForm.patchValue({
						StockSheetCode: response.SheetCode, // 銷貨單號
						SheetDate: { "year": $event.year, "month": $event.month, "day": $event.day}
					});
				}
				this.loadingService.stopLoading();
			});
		}
	}


}
