import { Injectable, PipeTransform } from '@angular/core';
// interface
import { Warehouse } from '../interface/warehouse';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';


// 搜尋結果承接的 Interface
interface SearchResult {
	warehouses: Warehouse[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(warehouses: Warehouse[], column: string, direction: string): Warehouse[] {
	// 沒有進行排序
	if (direction === '') {
		return warehouses
	}
	else {
		return [...warehouses].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(warehouse: Warehouse, term: string, pipe: PipeTransform) {
	return warehouse.WarehouseID.toLowerCase().includes(term.toLowerCase()) 
		 || warehouse.WarehouseName.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
	providedIn: 'root'
})
export class WarehouseService {

	// Define API URL參數
	apiURL = config.APIURL;

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _warehouses$ = new BehaviorSubject<Warehouse[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 8,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(private pipe: DecimalPipe, private http: HttpClient) {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getWarehouseList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.WarehouseList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._warehouses$.next(result.warehouses);
						this._total$.next(result.total);
					});

					this._search$.next();
				}
			});
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getWarehouseList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.WarehouseList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._warehouses$.next(result.warehouses);
						this._total$.next(result.total);
					});

					this._search$.next();
				}
			});
		}
	}

	// 從外部取參數
	get warehouse$() { return this._warehouses$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(warehouseList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let warehouses = sort(warehouseList, sortColumn, sortDirection);

		// 2. filter
		warehouses = warehouses.filter(warehouse => matches(warehouse, searchTerm, this.pipe));
		const total = warehouses.length;

		// 3. paginate
		warehouses = warehouses.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		console.log("warehouses : ", warehouses);
		return of({warehouses, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得倉庫列表
	getWarehouseList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_warehouse_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 建立倉庫資料
	createWarehouse(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_warehouse', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 更新倉庫資料
	updateWarehouse(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/update_warehouse', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 產生倉庫編號
	generateWarehouseCode(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/generate_warehouse_code', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))		
	}


}
