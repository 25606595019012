import { Injectable, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

// interface
import { ItemsUnit } from '../interface/ItemsUnit';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';

// 搜尋結果承接的 Interface
interface SearchResult {
	itemsUnits: ItemsUnit[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(itemUnits: ItemsUnit[], column: string, direction: string): ItemsUnit[] {
	// 沒有進行排序
	if (direction === '') {
		return itemUnits
	}
	else {
		return [...itemUnits].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(itemsUnit: ItemsUnit, term: string, pipe: PipeTransform) {
	return pipe.transform(itemsUnit.ItemUnitCode).includes(term)
		 || itemsUnit.ItemUnitName.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
	providedIn: 'root'
})
export class UnitService {

	// Define API URL參數
	apiURL = config.APIURL;

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _itemsUnits$ = new BehaviorSubject<ItemsUnit[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(
		private pipe: DecimalPipe, 
		private http: HttpClient,
		private router: Router
	) 
	{ 
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			// 取得公司底下的單位列表
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getItemsUnitList(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.ItemsUnitList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._itemsUnits$.next(result.itemsUnits);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
		else {
			this.router.navigate(['/login']);
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			// 取得公司底下的單位列表
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getItemsUnitList(_erpCompid).subscribe(response => {
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.ItemsUnitList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._itemsUnits$.next(result.itemsUnits);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}		
	}

	// 從外部取參數
	get itemsUnits$() { return this._itemsUnits$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(itemsUnitList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let itemsUnits = sort(itemsUnitList, sortColumn, sortDirection);

		// 2. filter
		itemsUnits = itemsUnits.filter(itemsUnit => matches(itemsUnit, searchTerm, this.pipe));
		const total = itemsUnits.length;

		// 3. paginate
		itemsUnits = itemsUnits.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		console.log("itemsUnits : ", itemsUnits);
		return of({itemsUnits, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得單位列表
	getItemsUnitList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_items_unit_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得目前單位編號
	GetGeneratedUnitCode(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/generate_unit_code', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 建立單位資料
	createItemsUnit(param): Observable<any> {

		return this.http.post<any>(
			this.apiURL + '/api/erp/create_items_unit', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 修改單位資料
	updateItemsUnit(param): Observable<any> {

		return this.http.post<any>(
			this.apiURL + '/api/erp/update_items_unit', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

}
