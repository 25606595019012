import { Injectable, PipeTransform } from '@angular/core';
// interface 
import { BillHeader } from '../interface/billheader';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';

// 搜尋結果承接的 Interface
interface SearchResult {
	purchases: BillHeader[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(purchases: BillHeader[], column: string, direction: string): BillHeader[] {
	// 沒有進行排序
	if (direction === '') {
		return purchases
	}
	else {
		return [...purchases].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(purchase: BillHeader, term: string, pipe: PipeTransform) {
	return purchase.BillCode.toLowerCase().includes(term.toLowerCase()) 
		 || purchase.BillDate.toLowerCase().includes(term.toLowerCase())
		 // || purchase.BillProviderName.toLowerCase().includes(term.toLowerCase()) 因為Server那邊不存這個
		 || purchase.DeliverCityName.toLowerCase().includes(term.toLowerCase())
		 || purchase.DeliverZipName.toLowerCase().includes(term.toLowerCase())
		 || purchase.DeliverAddress.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
	providedIn: 'root'
})
export class PurchaseService {

	// Define API URL參數
	apiURL = config.APIURL;
	fileURL = config["ERP_GeneratePDF_URL"];

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _purchases$ = new BehaviorSubject<BillHeader[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(private pipe: DecimalPipe, private http: HttpClient) {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getPurchaseList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.result) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.BillList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._purchases$.next(result.purchases);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getPurchaseList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.result) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.BillList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._purchases$.next(result.purchases);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
	}

	// 從外部取參數
	get purchases$() { return this._purchases$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(purchaseList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let purchases = sort(purchaseList, sortColumn, sortDirection);

		// 2. filter
		purchases = purchases.filter(purchase => matches(purchase, searchTerm, this.pipe));
		const total = purchases.length;

		// 3. paginate
		purchases = purchases.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		console.log("purchases : ", purchases);
		return of({purchases, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得採購單據標頭列表
	getPurchaseList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			SearchStr: '', // 暫時不啟用 Server Side Search 機制
			Type: 1 // 0: 訂單, 1:  採購單
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_purchase_bill_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 產生採購單據編號
	generatePurchaseCode(_erpCompid, _billdate): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			BillDate: _billdate,
			BillType: 2, // 1: 訂單, 2: 採購單
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/generate_purchase_code', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得供應商列表
	getSupplierList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_supplier_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得啟用中供應商列表
	getActiveSupplierList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_active_supplier_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}	

	// 取得產品列表
	getProductItemsList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_items_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得啟用中產品列表
	getActiveProductItemsList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_active_items_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得Succmail討論組列表
	getSuccDiscList(_succCompid, _succUserid, _token): Observable<any>{
		var param = {
			CompID: _succCompid,
			UserID: _succUserid,
			token: _token
		};
		return this.http.post<any>(
			this.apiURL + '/api/comp/get_user_access_discs', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 判斷是否需要建立 訂單標籤 or 對象標籤(客戶, 採購商)
	// tagType 0: 事件標籤, 1: 對象標籤
	checkSubjTagIsExist(_succCompid, _succUserid, _succUserName, _tagType, _tagName, _token): Observable<any>{
		var param = {
			token: _token,
			CompID: _succCompid,
			UserID: _succUserid,
			UserName: _succUserName,
			SubjTagName: _tagName,
			TagType: _tagType
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_expected_tagid', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得討論組的收件人陣列
	initNewMsgReceiver(_succCompid, _succDiscid, _succUserid, _token): Observable<any>{
		var param = {
			token: _token,
			CompID: _succCompid,
			DiscID: _succDiscid
		};

		return this.http.post<any>(
			this.apiURL + '/api/user/get_disc_member', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 更新採購單據主題動作列表
	// 1. 更新採購單據
	updatePurchaseBill(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/update_purchase_bill', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}
	// 2. 更新採購單據關聯主題
	updatePurchaseSubj(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/subj/update_bill_subject', 
			param
		).pipe()
	}

	// 作廢退回採購單動作列表
	// 1. 作廢採購單
	returnPurchase(_erpCompid, _billCode, _inventoryType, _inventoryTypeName, _operatorid, _loguserDeivce): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			BillCode: _billCode,
			InventoryType: _inventoryType,
			InventoryTypeName: _inventoryTypeName,
			OperatorID: _operatorid,
			LogUserDevice: _loguserDeivce
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/return_purchase_bill', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}


	// 建立採購單動作列表
	// 1. 產生採購單PDF
	generatePurchasePDF(param): Observable<any>{
		var formData = new FormData();
		formData.append("PurchaseSheet", JSON.stringify(param));

		return this.http.post<any>(
			this.fileURL + '/SuccErpApi/GeneratePurchasePDF', 
			formData
		).pipe()
	}
	// 2. 建立採購單
	insertPurchaseBill(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_purchase_bill', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}
	// 3. 建立Succmail 主題
	createPurchaseSuccSubj(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/subj/create_subject', 
			param
		).pipe()
	}
	// 4. 把SubjID 寫入這一筆採購訂單
	updatePurchaseRealtedSubjID(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/update_purchase_subjid', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}
}
