import { Component, OnInit } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { LoginService } from '../../service/login.service';
// 呼叫外部Service
import { LoadingService } from '../../service/loading.service';
// 平行化執行 Observable 事件
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	// ngModel params
	account: string;
	password: string;
	checked: boolean = true;

	constructor(
		private route: ActivatedRoute, 
		private router: Router,
		public loginService: LoginService,
		private loadingService: LoadingService
	) {}


	ngOnInit() {
		// 取得 URL參數
		var CompID = this.route.snapshot.paramMap.get('CompID');
		var UserID = this.route.snapshot.paramMap.get('UserID');
		if(CompID && UserID) {
			// 根據 連線 CompID 和 UserID 取得登入帳號密碼
			this.onGetInitData(CompID, UserID);
		}
	}	

	// 進行登入 判斷有無建立過ERP公司 及 拿取Succmail Token
	// 這裡有一種情況是，對方是違法URL過來，沒有經過正常管道在 Init時拿到三包寫入 localS的資料
	onLogin() {
		this.loadingService.startLoading("登入中...");

		// 取得 URL參數
		var CompID = this.route.snapshot.paramMap.get('CompID');
		var UserID = this.route.snapshot.paramMap.get('UserID');

		if(!CompID && !UserID) {
			this.loginService.loginToGetToken(this.account, this.password).subscribe(result => {
				if (result.IsSuccess) {
					UserID = result['result']['UserData'][0]['UserID'];
					CompID = result['result']['UserData'][0]['DefaultCompID'];
					this.onLoginWithOutParam(CompID, UserID);
				}
				else {
					alert(result.result);
				}
				this.loadingService.stopLoading();
			});
		}
		else {
			this.onLoginWithInParam(CompID, UserID);
			this.loadingService.stopLoading();
		}
	}

	// 連結自帶參數的登入方式
	onLoginWithInParam(_compid: string, _userid: string) {
		// 有無已經建立的 ERP 公司
		let _isExistERPCompany = this.loginService.checkERPEntityIsExist(_userid);
		// 拿取可存取Succmail 的Token
		let _loginToGetToken = this.loginService.loginToGetToken(this.account, this.password);

		// 平行化執行
		forkJoin([_isExistERPCompany, _loginToGetToken]).subscribe(results => {
			// results[0] 有無建立的ERP結果 
			if(results[0]['IsSuccess']) {
				this.onCheckERPCompState(results[0]['CompCount']);
			}
			// results[1] 拿取Succmail Token的結果
			// 將token寫入localS
			localStorage.removeItem('Token');
			localStorage.setItem('Token', results[1]['result']['Token']);
			// 關閉Loading畫面
			this.loadingService.stopLoading();
		});
	}

	// 連結無參數的登入方式
	onLoginWithOutParam(_compid: string, _userid: string) {
		// 有無已經建立的 ERP 公司
		let _isExistERPCompany = this.loginService.checkERPEntityIsExist(_userid);
		// 拿取可存取Succmail 的Token
		let _loginToGetToken = this.loginService.loginToGetToken(this.account, this.password);

		// 平行化執行
		forkJoin([_isExistERPCompany, _loginToGetToken]).subscribe(results => {
			// results[0] 有無建立的ERP結果 
			if(results[0]['IsSuccess']) {
				this.onGetInitData(_compid, _userid);
			}
			// results[1] 拿取Succmail Token的結果
			// 將token寫入localS
			localStorage.removeItem('Token');
			localStorage.setItem('Token', results[1]['result']['Token']);
			// 關閉Loading畫面
			this.loadingService.stopLoading();
		});
	}


	// 拿取三包使用者進來的基本資料
	onGetInitData(_compid: string, _userid: string) {
		console.log("get init data");
		// 做動作前 先做一次 localS情除動作
		localStorage.removeItem('loginInfo');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('compInfo');

		this.loadingService.startLoading("資料傳輸中...");
		this.loginService.getLoginInfo(_compid, _userid).subscribe((data: any) => {
			// 判斷有無成功
			if (data.IsSuccess) {
				// 把相關資料寫入 localStorage
				localStorage.setItem('loginInfo', JSON.stringify(data.LoginInfo));
				localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
				localStorage.setItem('compInfo', JSON.stringify(data.CompInfo));
				// 拉出Email & Pwd
				this.account = data.LoginInfo[0]['loginEmail'];
				this.password = data.LoginInfo[0]['loginPW'];
				
				let _directURL = localStorage.getItem('DirectURL') == null ? '':localStorage.getItem('DirectURL');
				let _directERPCompID = localStorage.getItem('DirectERPCompID') == null ? '':localStorage.getItem('DirectERPCompID');
				let _directSuccUserID = localStorage.getItem('DirectSuccUserID') == null ? '':localStorage.getItem('DirectSuccUserID');
				
				if (_directURL.length > 0 && _directERPCompID.length > 0 && _directSuccUserID.length > 0) {
					// 從SuccMail導過來的
					this.loadingService.startLoading("資料傳輸中...");
					let _getUserInfo = this.loginService.getSingleERPMemberInfo(_directERPCompID, _directSuccUserID);
					let _getCompInfo = this.loginService.checkERPEntityIsExist(_directSuccUserID);

					// 平行化執行
					forkJoin([_getUserInfo, _getCompInfo]).subscribe(results => {
						// 取人的資料
						if (results[0]['result']) {
							localStorage.setItem('currentERPUser', JSON.stringify(results[0]['MemberInfo']));
							localStorage.setItem('IsERPLogin', "true");
						}
						else {
							this.router.navigate(['/companyList']);
						}
						// 取公司的資料
						if (results[1]['IsSuccess']) {
							let _ERPCompanyData = results[1]['CompList'].filter(x => {
								return x.ERPCompID == _directERPCompID
							})[0];
							localStorage.setItem('currentERPComp', JSON.stringify(_ERPCompanyData));
						}
						else {
							this.router.navigate(['/companyList']);
						}
						// DirectURL的部分留到表單發出去後再刪，因為要判斷表單發出去後是要回到瀏覽畫面，還是導回去Succ
						localStorage.removeItem('DirectERPCompID');
						localStorage.removeItem('DirectSuccUserID');
						this.loadingService.stopLoading();
						window.location.href = _directURL;
					});
				}
				else {
					// 假如是非法類型，要補充ERP公司類型的資料
					this.router.navigate(['/companyList']);
				}
			}
			else {
				alert("登入失敗");
			}
			// 關閉Loading畫面
			this.loadingService.stopLoading();
		});
	}

	// ERP是否需要進行公司建立，或者顯示公司列表
	onCheckERPCompState(_compCount) {
		// ERP是否需要建立的確認流程
		if(_compCount == 0) {
			let _isBoss = JSON.parse(localStorage.getItem('userInfo'))[0]['isBoss'];
			if(_isBoss == 0) {
				// 關閉Loading畫面
				this.loadingService.stopLoading();
				alert('啟動ERP功能需由公司最高管理者啟用!');
				return;
			}	
			else {
				// CompCount 為0的話 且 該人為公司最高管理者 導到建立頁面
				// Type = 1, 導向新增，給公司名稱預設值
				this.router.navigate(['/companyInit/1']);
			}
		}
		else {
			// CompCount 為1的話 拉出可以前往的公司列表 並 導頁	
			this.router.navigate(['/companyList']);
		}
	}

}
