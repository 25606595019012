import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { ValidateruleService } from '../../service/validaterule.service';
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';

// 拉出表格資料使用
import {Observable} from 'rxjs';
// interface.
import { ValidateRule } from '../../interface/validaterule';
// 建立表單工具
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-validaterule',
  templateUrl: './validaterule.component.html',
  styleUrls: ['./validaterule.component.css'],
  providers: [ValidateruleService]
})
export class ValidateruleComponent implements OnInit {

	validateRules$: Observable<ValidateRule[]>;
	total$: Observable<number>;

	// 判斷目前成員頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	userPower = 103;

	// 新增檢驗規則的 form
	ValidateForm = new FormGroup({
		ValidateName: new FormControl(''),
		IsActive: new FormControl('1')
	});

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		private validateService: ValidateruleService
	) {
		this.validateRules$ = validateService.validateRules$;
		this.total$ = validateService.total$;
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
					console.log(this.userPower);
				}
				this.loadingService.stopLoading();
			});
		}
	}

	// 前往新增檢驗規則頁面
	goToNewValidateRulePage() {
		console.log("goToNewValidateRulePage");
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}	
		this.loadingService.startLoading('讀取資料');
		this.IsCreate = true;
		this.IsBrowsing = false;
		this.IsEdite = false;
		this.loadingService.stopLoading();


	}

	// 送出新增檢驗規則
	onSubmitValidateRule() {
		console.log("in new rule");
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('建立檢驗規則中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;
		console.log("form value : ", this.ValidateForm.value);
		let param = {
			ERPCompID: _erpCompid,
			OperatorID: _erpUserid,
			ValidateName: this.ValidateForm.value['ValidateName'],
			IsActive: this.ValidateForm.value['IsActive'],
			LogUserDevice: _logUserDevice,
		};
		this.validateService.createValidateRule(param).subscribe(response => {
			console.log(response);
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 取消 Form 動作
	onFormCancel() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
		window.scrollTo(0, 0);
	}


	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

}
