import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

	private _loadingState: boolean = false;
	private _loadingText: string = "";
	// Applied to check if the status has updated.
	loadingSubject: Subject<any> = new Subject();
	

	// 取得loading參數
	get loadingState(): any {
		return this._loadingState;
	}

	// 設定loading參數
	set loadingState(value) {
		this._loadingState = value['isLoading'];
		this._loadingText = value['loadingMsg'];
		this.loadingSubject.next(value);
	}

	// 執行函示
	startLoading(message) {
		// 呼叫set
		this.loadingState = {isLoading: true, loadingMsg: message};
	}
	stopLoading() {
		// 呼叫set
		this.loadingState = {isLoading: false, loadingMsg: ''};
	}

	// Simple Service about sending text content
	// private subject = new Subject<any>();

	// sendMessage(message: string) {
	// 	this.subject.next({ text: message });
	// }

	// clearMessages() {
	// 	this.subject.next();
	// }

	// getMessage(): Observable<any> {
	// 	return this.subject.asObservable();
	// }

}
