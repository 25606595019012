import { Injectable, PipeTransform } from '@angular/core';
// interface
import { ProductItem } from '../interface/productitem';
import { SortDirection } from '../directive/sortable.directive';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, debounceTime, delay, switchMap, retry, catchError } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

// Http
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import config file
import config from '../config/constant.json';

// 搜尋結果承接的 Interface
interface SearchResult {
	productitems: ProductItem[];
	total: number;
}

// 承接畫面使用狀態
interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

// sort 使用的比較函示
function compare(v1, v2) {
	return v1 < v2 ? -1 : v1 > v2 ? 1: 0;
}

// 排序功能
function sort(productitems: ProductItem[], column: string, direction: string): ProductItem[] {
	// 沒有進行排序
	if (direction === '') {
		return productitems
	}
	else {
		return [...productitems].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

// 搜尋比對
function matches(productitem: ProductItem, term: string, pipe: PipeTransform) {
	// 過濾數字類型資料的方法
	// pipe.transform(supplier.XXX).includes(term)
	return productitem.ItemMaterialCode.toLowerCase().includes(term.toLowerCase())
		 || pipe.transform(productitem.ItemCategoryID).includes(term)
		 || productitem.ItemName.toLowerCase().includes(term.toLowerCase())
		 || productitem.ItemSpec.toLowerCase().includes(term.toLowerCase())
		 || productitem.ItemUnitName.toLowerCase().includes(term.toLowerCase())
		 || productitem.ItemResourceID.toLowerCase().includes(term.toLowerCase());
}

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	// Define API URL參數
	apiURL = config.APIURL;
	fileURL = config["ERP_GeneratePDF_URL"];

	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _productitems$ = new BehaviorSubject<ProductItem[]>([]);
	private _total$ = new BehaviorSubject<number>(0);

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: ''
	};

	constructor(private pipe: DecimalPipe, private http: HttpClient) {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getProductItemsList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.ItemsList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._productitems$.next(result.productitems);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
	}

	// 從 Server更新資料列表
	refreshDataList() {
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentCompInfo != null) {
			let _erpCompid = _currentCompInfo.ERPCompID;
			this.getProductItemsList(_erpCompid).subscribe(response => {
				console.log(response);
				if (response.IsSuccess) {
					this._search$.pipe(
						tap(() => this._loading$.next(true)),
						debounceTime(200),
						switchMap(() => this._search(response.ItemsList)),
						delay(200),
						tap(() => this._loading$.next(false))
					).subscribe(result => {
						console.log("result : ", result);
						this._productitems$.next(result.productitems);
						this._total$.next(result.total);
					});
					this._search$.next();
				}
			});
		}
	}

	// 從外部取參數
	get productitems$() { return this._productitems$.asObservable(); }
	get total$() { return this._total$.asObservable(); }
	get loading$() { return this._loading$.asObservable(); }
	get page() {return this._state.page; }
	get pageSize() {return this._state.pageSize; }
	get searchTerm() {return this._state.searchTerm; }

	set page(page: number) { this._set({page}); }
	set pageSize(pageSize: number) { this._set({pageSize}); }
	set searchTerm(searchTerm: string) { this._set({searchTerm}); } // 這裡的參數名稱要跟上面宣告的 State內容一樣
	set sortColumn(sortColumn: string) { this._set({sortColumn}); }
	set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(itemsList): Observable<SearchResult> {
		const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;

		// 1. sort
		let productitems = sort(itemsList, sortColumn, sortDirection);

		// 2. filter
		productitems = productitems.filter(supplier => matches(supplier, searchTerm, this.pipe));
		const total = productitems.length;

		// 3. paginate
		productitems = productitems.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		return of({productitems, total});
	}

	// Server 處理相關函式
	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		})
	}

	// 取得倉庫列表
	getWarehouseList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_warehouse_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得產品列表
	getProductItemsList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_items_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得產品類別列表
	getProductCateList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		}

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_item_cate_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得產品編號
	generateItemMaterialCode(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		}

		return this.http.post<any>(
			this.apiURL + '/api/erp/generate_item_code', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 建立產品
	createProductItem(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_items', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 更新產品
	updateProductItem(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/update_items', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 取得品項庫存明細
	getInventoryList(_erpCompid, _itemMaterialCode): Observable<any>{
		var param = {
			ERPCompID: _erpCompid,
			ItemMaterialCode: _itemMaterialCode
		}

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_inventory_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 上傳表單
	uploadSheetData(_formdata): Observable<any>{
		return this.http.post<any>(
			this.fileURL + '/SuccErpApi/UploadItemsCSV', 
			_formdata
		).pipe()
	}

	// 建立表單多項產品
	createProductItemsFromSheet(param): Observable<any>{
		return this.http.post<any>(
			this.apiURL + '/api/erp/create_items_from_sheet', 
			JSON.stringify(param),
			this.httpOptions
		).pipe()
	}

	// 取得所有單位列表
	getItemsUnitList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_items_unit_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}

	// 取得啟用中單位列表
	getActiveUnitList(_erpCompid): Observable<any>{
		var param = {
			ERPCompID: _erpCompid
		};

		return this.http.post<any>(
			this.apiURL + '/api/erp/get_active_unit_list', 
			JSON.stringify(param),
			this.httpOptions
		).pipe(retry(1))
	}


}
