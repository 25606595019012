import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
// import Service
import { LoadingService } from '../../service/loading.service';
import { GlobalService } from '../../service/global.service';
import { UnitService } from '../../service/unit.service';

// 表格過濾排序使用套件
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {NgbdSortableHeader, SortEvent} from '../../directive/sortable.directive';

// Interface
import {ItemsUnit} from '../../interface/ItemsUnit';
// 建立表單控制套件
import { FormGroup, FormControl } from '@angular/forms';


@Component({
	selector: 'app-product-unit',
	templateUrl: './product-unit.component.html',
	styleUrls: ['./product-unit.component.css'],
	providers: [UnitService, DecimalPipe]
})
export class ProductUnitComponent implements OnInit {

	itemsUnits$: Observable<ItemsUnit[]>;
	total$: Observable<number>;
	// 判斷目前頁面的狀態
	IsBrowsing: boolean = true;
	IsCreate: boolean = false;
	IsEdite: boolean = false;
	// 預設使用者權限為最低
	userPower = 103;

	// 新增Unit Form
	itemsUnitForm = new FormGroup({
		ERPCompID: new FormControl(''), // 隱藏參數
		ItemUnitCode: new FormControl(''), 
		ItemUnitName: new FormControl(''), 
		IsActive: new FormControl(1), 
	});

	// 修改Unit Form
	detailUnitForm = new FormGroup({
		ID: new FormControl(''), 
		ERPCompID: new FormControl(''), // 隱藏參數
		ItemUnitCode: new FormControl(''), 
		ItemUnitName: new FormControl(''), 
		IsActive: new FormControl(1), 
	});

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	constructor(
		private router: Router,
		private loadingService: LoadingService,
		private globalService: GlobalService,
		public unitService: UnitService
	) 
	{ 
		this.itemsUnits$ = unitService.itemsUnits$;
		console.log(this.itemsUnits$);
		this.total$ = unitService.total$;
	}

	ngOnInit() {
		this.loadingService.startLoading("權限確認中...");
		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentERPUser = JSON.parse(localStorage.getItem('currentERPUser'));
		// 取得使用者權限
		if (_currentERPComp != null && _currentERPUser != null) {
			this.globalService.getUserPowerID(_currentERPComp.ERPCompID, _currentERPUser.ERPUserID).subscribe(response => {
				if (response.result) {
					this.userPower = response.MemberInfo['Power'];
				}
				this.loadingService.stopLoading();
			});
		}
	}

	onSort({column, direction}: SortEvent) {
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});

		this.unitService.sortColumn = column;
		this.unitService.sortDirection = direction;
	}

	// 前往建立產品單位畫面
	goToNewItemsUnitPage() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');

		let _currentERPComp = JSON.parse(localStorage.getItem('currentERPComp'));
		if (_currentERPComp != null) {
			let _erpCompid = _currentERPComp.ERPCompID;
			this.unitService.GetGeneratedUnitCode(_erpCompid).subscribe(response => {
				this.itemsUnitForm.patchValue({
					ERPCompID: _erpCompid,
					ItemUnitCode: response.ItemsUnitCode,
					ItemUnitName: '',
					IsActive: '1'
				});
				this.IsBrowsing = false;
				this.IsCreate = true;
				this.IsEdite = false;
				this.loadingService.stopLoading();
			});
		}
	}

	// 回到選單畫面
	goToMenuPage() {
		this.router.navigate(['/home']);
	}

	// 取消建立產品單位
	cancelCreateItemUnit() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
	}

	// 取消修改產品單位
	cancelUpdateItemUnit() {
		this.IsBrowsing = true;
		this.IsCreate = false;
		this.IsEdite = false;
	}

	// 啟動修改產品單位
	updateItemsUnit(_item) {
		console.log(_item);
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('讀取資料');
		this.IsBrowsing = false;
		this.IsCreate = false;
		this.IsEdite = true;
		this.detailUnitForm.patchValue({
			ID: _item.ID, 
			ERPCompID: _item.ERPCompID,
			ItemUnitCode: _item.ItemUnitCode, 
			ItemUnitName: _item.ItemUnitName, 
			IsActive: _item.IsActive.toString(), 
		});

		this.loadingService.stopLoading();
	}

	// 送出修改產品單位要求
	onUpdateItemsUnit() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('資料修正中...');
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpUserid = _currentUserInfo.ERPUserID;

		let param = {
			ID: this.detailUnitForm.value['ID'],
			ERPCompID: this.detailUnitForm.value['ERPCompID'],
			OperatorID: _erpUserid,
			ItemUnitName: this.detailUnitForm.value['ItemUnitName'],
			IsActive: this.detailUnitForm.value['IsActive'],
			LogUserDevice: _logUserDevice
		};

		this.unitService.updateItemsUnit(param).subscribe(response => {
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料修改成功!');
				this.unitService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;				
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料修改錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 送出新增產品單位要求
	onSubmitItemsUnit() {
		let _isAccessAble = this.checkFuncAccessAble();
		if (!_isAccessAble) { return;}
		this.loadingService.startLoading('建立產品單位中...');	
		let _logUserDevice = this.globalService.detectUserAgent();
		let _currentCompInfo = JSON.parse(localStorage.getItem('currentERPComp'));
		let _currentUserInfo = JSON.parse(localStorage.getItem('currentERPUser'));
		let _erpCompid = _currentCompInfo.ERPCompID;
		let _erpUserid = _currentUserInfo.ERPUserID;

		let param = {
			ERPCompID: this.itemsUnitForm.value['ERPCompID'],
			ItemUnitCode: this.itemsUnitForm.value['ItemUnitCode'],
			ItemUnitName: this.itemsUnitForm.value['ItemUnitName'],
			IsActive: this.itemsUnitForm.value['IsActive'],
			OperatorID: _erpUserid,
			LogUserDevice: _logUserDevice
		};

		this.unitService.createItemsUnit(param).subscribe(response => {
			if (response.IsSuccess) {
				window.scrollTo(0, 0);
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(0);
				this.globalService.changeRemindMsg('資料建立成功!');
				this.unitService.refreshDataList();
				this.IsBrowsing = true;
				this.IsCreate = false;
				this.IsEdite = false;
			}
			else {
				this.globalService.changeRemindState('open');
				this.globalService.changeRemindType(1);
				this.globalService.changeRemindMsg('資料建立錯誤!');
				this.router.navigate(['/']);
			}
			this.loadingService.stopLoading();
		});
	}

	// 確認權限是否符合進入函式
	checkFuncAccessAble() {
		let _isDisableAccess = this.checkIsAbleToModify();
		if (_isDisableAccess) {
			this.globalService.changeRemindState('open');
			this.globalService.changeRemindType(1);
			this.globalService.changeRemindMsg('權限授予失敗!');
			return false;
		}
		else {
			return true;
		}
	}

	// 只有權限 = 102, 104 可以看到更新按鈕 並且修改內容
	checkIsAbleToModify() {
		switch (this.userPower) {
			case 101:
			case 102:
			case 104:
				return false;
				break;
			case 103:
				return true;
			default:
				return true;
				break;
		}
	}

}
